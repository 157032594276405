@import 'libs';
@import 'font';
@import 'vars';
@import '../sprite/sprite';

body, html {
  overflow-x: hidden;
}


body {
  font-size: $basicFontSize;
  min-width: 320px;
  position: relative;
  line-height: 24px;
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  color: $basicFontColor;
  font-weight: $basicFontMediumWeight;
  background: $basicBG;
  input, textarea {
    border: #e2e2e2 1px solid;
    outline: none;
    &:focus:required:invalid {
      border-color: red;
    }
    &:required:valid {
      border-color: green;
    }
  }
  a{
    color: $contentLinkColor;
  }
  hr{
    border-color: transparent !important;
    background: transparent !important;
  }
  .alignleft{
    display: block;
    float: left;
    margin: 5px 10px 5px 0;
  }
  .alignright{
    display: block;
    float: right;
    margin: 5px 0 5px 10px;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: $basicFontMediumWeight;
  }
  .lazyload{
    z-index: 10000;
  }
}
.alignRight{text-align: right;}
.center{
  text-align: center;
  display: block;
  width: 100%;
}
.alignLeft{text-align: left;}
.padding0{padding: 0;}
.paddingLeft0{padding-left: 0;}
.borderRight1{border-right: 1px solid $borderColor !important;}
.borderLeft1{border-left: 1px solid $borderColor !important;}
.overflowHidden{
  overflow: hidden;
}

.line {
  background: $borderColor;
  height: 1px;
  display: block;
  clear: both;
  margin-bottom: 23px;
}

.floatLeft {
  float: left;
  margin-right: 25px;
}
.floatRight {
  float: right;
  margin-left: 25px;
}
.clear{clear: both;}


.search_block {
  display: inline-block;
  position: relative;
  border-right: 1px solid $headerMenuLinkBorderColor;
  border-left: 1px solid $headerMenuLinkBorderColor;
  width: 50px;
  height: 34px;
  cursor: pointer;
  margin: 13px 0 7px;
  i{
    display: block;
    margin: 5px auto;
  }
}
.search-form{
  display: none;
  position: absolute;
  padding: 3px;
  background: $searchFormBg;
  z-index: 1000;
  border-radius: 5px;
  right: -218px;
  top: 11px;
  &.active{
    right: 55px;
    display: block;
  }
  input[type='text']{
    //border: 1px solid $headerMenuLinkBorderColor;
    padding: 4px 30px 4px 7px;
    border-radius: 5px;
    font-size: 14px;
  }
  input[type='submit']{
    background: url(../img/search-ico.png) center 6px no-repeat #fff;
    width: 28px;
    height: 32px;
    position: absolute;
    right: 5px;
    top: 4px;
    border: 0;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  .search-submit{
    width: 28px;
    height: 32px;
    position: absolute;
    right: 5px;
    top: 5px;
    border: 0;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    i{
      position: absolute;
      display: block;
      right: 6px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 22px;
    }
  }
}
.choose_language_block{
  display: inline-block;
  position: relative;
  margin: 13px 0 -2px -3px;
  vertical-align: top;
  border-right: 1px solid $headerMenuLinkBorderColor;
  height: 34px;
  width: 46px;
  .language_active{
    background: url(../img/flags-ico.png) -480px center no-repeat;
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 6px 13px;
  }
  .language_links_block{
    background: $languageBlockBg;
    padding: 0 5px;
    border-radius: 5px;
    position: absolute;
    width: 36px;
    margin-left: 4px;
    z-index: 100;
    display: none;
    &.active{
      display: block;
    }
    a{
      background: url(../img/flags-ico.png) 0 center no-repeat;
      height: 22px;
      margin: 5px auto;
      width: 20px;
      display: block;
      color: transparent;
      cursor: pointer;
      overflow: hidden;
      &.language_sw{background-position: -120px center !important;}
      &.language_ge{background-position: -160px center !important;}
      &.language_fn{background-position: -40px center !important;}
      &.language_no{background-position: -80px center !important;}
      &.language_pl{background-position: 0px center !important;}
      &.language_da{background-position: -200px center !important;}
      &.language_de{background-position: -240px center !important;}
      &.language_nl{background-position: -280px center !important;}
      &.language_ca{background-position: -320px center !important;}
      &.language_nz{background-position: -360px center !important;}
      &.language_fr{background-position: -400px center !important;}
      &.language_ja{background-position: -440px center !important;}
      &.language_ro{background-position: -480px center !important;}
    }
  }
}

/* header */
.mobile-menu-icon{
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  margin: 15px -15px 0 10px;
  padding: 7px;
  line-height: 0;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  z-index: 1000;
  span{
    background: $headerMenuLinkBorderColor;
    margin-top: 3px;
    width: 100%;
    height: 3px;
    display: inline-block;
  }
}

.mobile-menu-cnt{
  display: none;
  .mobile-header-menu{
    position: absolute;
    width: 85%;
    background: $headerBg;
    z-index: 9;
    top: 63px;
    display: block;
    ul{
      padding: 0;
      margin: 0;
      border: 1px solid $borderColor;
      li{
        display: block;
        border-top: 1px solid $headerMenuLinkBorderColor;
        position: relative;
        a{
          color: #818181;
          font-weight: $basicFontSemiBoldWeight;
          padding: 10px;
          display: block;
          &:hover {
            text-decoration: none;
            color: $menuLinkHoverColor;
          }
        }
        .sub-menu{
          background: #fff;
          padding: 10px;
          text-align: center;
          li{
            border: 0;
            a{
              display: block;
              position: relative;
              color: #818181;
              font-weight: $basicFontMediumWeight;
              font-size: $dropMenuLinkFontSize;
              padding: 5px 0;
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  &.active{
    display: block;
  }
}

.sub-menu {
  display: none;
}
.active-link{
  &:after {
    content: "►";
    display: block;
    cursor: pointer;
    width: 40px;
    height: 30px;
    color: #000;
    position: absolute;
    right: 0;
    top: 10px;
  }
  ul.sub-menu{
    display: block;
  }
}
.header{
  background: linear-gradient(0deg,$fromColor,$toColor);
  .header-logo{
    background: url(../img/logo.png) no-repeat 50% transparent;
    display: block;
    position: absolute;
    width: 160px;
    height: 62px;
    left: 0;
    z-index: 15;
    top: 0;
    margin-left: 10px;
  }
}
.header-line{
  display: block;
  position: relative;
  height: 7px;
  width: 100%;
  background: $headerLine;
}

/* front-page */
.promo-block-left {
  height: auto;
  overflow: hidden;
  background: #fff;
  border: 1px solid $borderColor;
  border-radius: 5px;
  .promo-block-title{
    border-top: 0;
  }
}
.promo-block-title{
  color: $titleBlockColor;
  text-align: center;
  font-size: 22px;
  border-bottom: 1px solid $borderColor;
  border-top: 1px solid $borderColor;
  padding: 10px 0 10px;
  font-weight: $basicFontBoldWeight;
}
.header-promo-block{
  display: block;
  position: relative;
  margin: 15px -10px;
  overflow: hidden;
  .casinos-header{
    position: relative;
    z-index: 1;
    border: 1px solid $borderColor;
    box-shadow: 0 0 7px $shadowColor;
    border-radius: 5px;
    .promo-block-title{
      border-top: 0;
    }
    .load-more-in-tab{
      border-top: 0;
    }
  }
  .promo-block-left{
    .casino-promo-cnt{
      display: block;
      position: relative;
      margin: 0 auto;
      .casino-promo-item{
        border-bottom: 1px solid $borderColor;
        padding: 2px 15px;
        height: auto;
        overflow: hidden;
        &:nth-child(even){
          background: $tabTableCasinoItemHover;
        }
        &:last-child{
          border: 0;
        }
        .casino-logo{
          border-right: 0;
          img{
            border-radius: 5px;
            max-width: 100%;
            margin: -2px auto;
            display: block;
          }
        }
        .casino-rating{
          border-right: 1px solid $borderColor;
          border-left: 1px solid $borderColor;
          margin: 2px -1px;
        }
        .casino-btn-ref{
          padding: 7px 18px;
          border-radius: 3px;
          margin: 15px auto;
          max-width: 100%;
          display: block;
          width: 94px;
        }
      }
    }
  }
}
.content-front{
  padding: 15px;
}
.content-block-bg{
  background: $contentBlockBg;
}
.btn{
  background: $btnBg;
  background: linear-gradient(to top, $btnBgBottom, $btnBgTop);
  color: $btnTextColor;
  line-height: 24px;
  font-weight: $basicFontRegularWeight;
  &:hover{
    color: $btnTextColor;
    //background: $btnBgHover;
    opacity: 0.8;
  }
}
.content{
  display: block;
  position: relative;
  h1{
    font-weight: $basicFontBoldWeight;
    line-height: 40px;
    font-size: 38px;
  }
}

.hidden-text{
  padding-bottom: 30px;
  &.active{
    height: auto !important;
  }
}
.hidden-block{
  display: block;
  position: absolute;
  height: 50px;
  z-index: 3;
  width: 100%;
  bottom: 2px;
  background: #f5f5f5;
  background: linear-gradient(180deg,hsla(0,0%,96%,.50744),#f5f5f5);
  &.no-active{display: none;}
}
.hidden-btn{
  color: $readMoreColor;
  width: 130px;
  margin: 15px auto;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  font-size: 18px;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 9px;
  span{
    color: $readMoreSpanColor;
    display: inline-block;
    border: 2px solid $readMoreSpanColor;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    margin-left: 10px;
  }
  &:hover{
    color: $readMoreColorHover;
  }
}
.title-cnt{
  padding: 15px 0;
  .title{
    color: $titleColor;
    font-size: 32px;
    line-height: 32px;
  }
}

/* rating */
progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 7px;
  margin-bottom: 0;
}

progress[value]::-webkit-progress-bar {
  background-color: #c4d7ee;
  border-radius: 8px;
  //box-shadow: 0 2px 5px rgba(0, 0, 0, .25) inset
}

progress[value]::-webkit-progress-value {
  //background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, .1) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)), -webkit-linear-gradient(left, #09c, #f44);
  border-radius: 8px;
  background: linear-gradient(to right,#6fabf7,#257ff0);
  //background-color: #818181;
  //background-size: 35px 20px, 100% 100%, 100% 100%;
  -webkit-animation: animate-stripes 5s linear infinite;
  animation: animate-stripes 5s linear infinite
}

@-webkit-keyframes animate-stripes {
  100% {
    background-position: -100px 0
  }
}

@keyframes animate-stripes {
  100% {
    background-position: -100px 0
  }
}

progress[value]::-webkit-progress-value::before {
  content: '80%';
  position: absolute;
  right: 0;
  top: -125%
}

progress[value]::-webkit-progress-value::after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  //border-radius: 100%;
  right: 7px;
  top: 7px;
  background-color: #fff
}

progress[value]::-moz-progress-bar {
  //background-image: -moz-linear-gradient(135deg, transparent 33%, rgba(0, 0, 0, .1) 33%, rgba(0, 0, 0, .1) 66%, transparent 66%), -moz-linear-gradient(top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)), -moz-linear-gradient(left, #09c, #f44);
  //background-color: #818181;
  background: linear-gradient(to right,#6fabf7,#257ff0);
  border-radius: 8px;
  //background-size: 35px 20px, 100% 100%, 100% 100%
}

.progress-value {
  color: $ratingColor;
  font-weight: $basicFontSemiBoldWeight;
  margin: 6px auto -10px;
}

.progress-value span {
  color: $ratingSpanColor
}

/* tabs */
.sorting-th{
  width: 100%;
}
.table-tabs{
  .load-more-in-tab{
    border-top: 0;
  }
  .tabs-block{
    padding: 0 15px;
    .tab-item{
      padding: 10px 0;
      border-radius: 5px 5px 0 0;
      //box-shadow: 0 -2px 13px 0 $shadowColor;
      cursor: pointer;
      border: 1px solid $borderColor;
      height: 46px;
      overflow: hidden;
      background: $tabsBg;
      margin: 2px 0;
      &.active{
        background: $tabHoverBg;
        //.title-item {
        //  color: $titleTabColorHover;
        //}
      }
      &:last-child{
        line-height: 17px;
        padding: 6px 0;
      }
      .title-item{
        color: $titleColor;
        display: block;
        text-align: center;
        font-size: 13px;
        font-weight: $basicFontSemiBoldWeight;
        i{
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .oneTableTab{
    padding: 0 15px;
    .table-sorter{
      background: $tableBG;
      border: 1px solid $titleTabBorderColor;
      border-top: 0;
      border-radius: 0 0 5px 5px;
      width: 100%;
      .sorting-panel{
        background: $sortPanelBg;
        height: auto;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid $borderColor;
        .sort-items-title{
          display: block;
          padding: 5px 0;
          text-align: center;
          font-weight: $basicFontRegularWeight;
          color: $sortItemTitle;
          text-transform: uppercase;
          font-size: $sortItemFontSize;
          margin: 2px 0;
        }
      }
      .casino-table-body{
        tr:nth-child(2n) {
          background: $tabTableCasinoItemHover;
        }
        .casino-title-hidden{display: none;}
        .casino-logo{
          border-right: 0;
          img{
            margin: 0 auto;
            display: block;
            max-width: 100%;
            border-radius: 5px;
          }
        }
        .casino-rating{
          margin: -20px -1px 10px;
          padding: 10px 15px 20px;
        }
        .casino-btn-ref{
          padding: 5px 27px;
          border-radius: 3px;
          display: inline-block;
          margin: 12px 15px;
        }
        tr{
          border-bottom: 1px solid $borderColor;
          td{
            padding: 10px 5px 0;
            border: 0;
            text-align: left;
          }
          td:nth-child(1){width: 50%}
          td:nth-child(2){width: 50%}
        }
        .table-casino-bonus{
          padding: 0 10px;
          font-size: 14px;
          text-align: center;
        }
        .btns-cnt{
          /*border-left: 1px solid $borderColor;*/
          .casinos-link-btn{
            text-decoration: underline;
            margin: -10px auto 5px;
            display: block;
            font-weight: $lightWeight;
            color: $btnLink;
            &:hover{
              text-decoration: none;
              color: $basicFontColor;
            }
          }
        }

      }
    }
    .load-more-in-tab {
      background: $loadMoreInTabBg;
      color: $loadMoreInTabColor;
      text-decoration: underline;
      padding: 7px 10px 10px;
      border-radius: 0 0 5px 5px;
      text-align: center;
      cursor: pointer;
      transition: top 1s ease-out 0.5s;
      width: 100%;
      border: 1px solid $borderColor;
      border-top: 0;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

/* promotion block */
.promotion-block{
  margin: 20px -5px 0;
  .one-news-item{
    height: 87px;
    overflow: hidden;
    padding: 13px 10px;
    line-height: 14px;
    border-bottom: 1px solid $borderColor;
    &:hover{
      background: $sbCasinoItemHoverBG;
      .promotion-title{
        color: $sbCasinoItemHoverColor;
      }
      .promotion-text{
        color: $sbCasinoItemHoverColor;
      }
    }
    &:last-child{
      border-bottom: 0;
    }
    img{
      border-radius: 5px;
      height: 60px;
    }
    .promotion-title {
      font-size: 14px;
      font-weight:$mediumBoldWeight;
      padding-bottom: 3px;
      display: block;
      max-height: 30px;
      overflow: hidden;
      color: $basicFontColor;
    }
    .promotion-text{
      font-size: 14px;
      padding: 5px 0 0;
    }
  }
  .show-more-promo {
    color: $loadMoreInTabColor;
    position: relative;
    padding: 9px 10px;
    margin-top: 6px;
    text-align: center;
    display: block;
  }
}

/* slots */
.front-slots-bg{
  background: $frontSlotsBg;
  //border-bottom: 1px solid $headerMenuLinkBorderColor;
  //border-top: 1px solid $headerMenuLinkBorderColor;
}
.front-slots-cnt{
  height: auto;
  overflow: hidden;
  padding: 15px 0;
  line-height: 29px;
  text-align: center;
  .more-slots-btn{
    border: 1px solid $moreCasinoBTNBorderColor;
    color: $btnColor;
    margin: 0 auto 15px;
    cursor: pointer;
    display: block;
    width: 198px;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: $basicFontMediumWeight;
    &:hover{
      background: #fff;
      color: #c84646;
    }
  }
  .filter-slots{
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li{
      display: inline-block;
      border: 1px solid $borderColor;
      padding: 2px 10px;
      color: $filterItemColor;
      border-radius: 5px;
      margin: 5px;
      cursor: pointer;
      &:hover{
        color: $filterItemHoverColor;
        background: $filterItemHoverBg;
      }
      &.active{
        background: #fff;
        color: $basicFontColor;
      }
    }
  }
  .title-front-slots-cnt{
    font-size: 26px;
    color: $titleFrontSlotsCnt;
    padding: 15px 0;
  }
  .slots-block{
    ul{
      list-style: none;
      margin: 15px 0;
      padding: 0;
      text-align: center;
      li{
        background: $oneSlotBg;
        padding: 3px;
        border-radius: 5px;
        margin: 0 0 12px;
        max-width: 228px;
        cursor: pointer;
        min-width: 228px;
        display: inline-block;
        .slot-img-block{
          display: block;
          position: relative;
          height: auto;
          overflow: hidden;
          &:hover .slot-hidden-block{
            display: block;
          }
          img{
            max-width: 100%;
            border: none;
            width: 100%;
          }
          .slot-hidden-block{
            background: rgba(0,0,0,.7);
            width: 100%;
            height: 100%;
            display: none;
            position: absolute;
            top: 0;
            .slot-hidden-block-btn{
              background: url(../img/play-btn-ico.png) no-repeat;
              display: block;
              width: 68px;
              height: 62px;
              position: absolute;
              z-index: 1000;
              margin: auto;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              &:hover{
                background-position: 0 -79px;
              }
            }
          }
        }
        .slot-title_cnt{
          position: relative;
          height: 48px;
          overflow: hidden;
          vertical-align: middle;
          display: table;
          text-align: center;
          width: 100%;
          .slot-title{
            color: $oneSlotTitle;
            padding: 5px;
            text-align: center;
            font-weight: $basicFontSemiBoldWeight;
            display: table-cell;
            vertical-align: middle;
            line-height: 18px;
          }
        }
      }
    }
  }
}

/* front new sidebar */
.news-block{
  .one-news-item{
    height: 94px;
    overflow: hidden;
    padding: 10px;
    //background: $oneNewsItemBG;
    line-height: 14px;
    border-bottom: 1px solid $borderColor;
    img{
      border-radius: 5px;
      width: 80px;
      margin: 10px 10px 18px 0;
    }
    &:hover{
      background: $oneNewsItemHoverBG;
    }
    &:nth-child(even){
      background: $oneNewsItemHoverBG;
    }
    &:hover{
      .promotion-title{
        text-decoration: none;
        color: $sbCasinoItemHoverColor;
      }
      //.promotion-text{
      //  color: #fff;
      //}
    }
    &:last-child{
      border-bottom: 0;
    }
    .promotion-title {
      font-size: $basicFontSize;
      font-weight:$basicFontRegularWeight;
      padding-bottom: 3px;
      display: block;
      line-height: 16px;
      color: $basicFontColor;
      margin-top: 0;
      &:hover{
        text-decoration: none;
      }
    }
    .promotion-text{
      font-size: 12px;
      padding: 5px 0;
      line-height: 14px;
    }
  }
}

/* Nowe automaty do gry */
.new-games-block{
  display: block;
  position: relative;
  ul{
    text-align: center;
    padding: 0;
    list-style: none;
    li{
      background: $oneSlotBg;
      padding: 7px 15px;
      border-radius: 5px;
      margin: 0 0 12px;
      max-width: 100%;
      min-width: 100%;
      display: inline-block;
      cursor: pointer;
      .slot-img-block{
        display: block;
        position: relative;
        height: auto;
        overflow: hidden;
        border-radius:5px;
        &:hover .slot-hidden-block{
          display: block;
        }
        img{
          border: none;
          width: 100%;
        }
        .slot-hidden-block{
          background: rgba(0,0,0,.7);
          width: 100%;
          height: 100%;
          display: none;
          position: absolute;
          top: 0;
          .slot-hidden-block-btn{
            background: url(../img/play-btn-ico.png) no-repeat;
            display: block;
            width: 68px;
            height: 62px;
            position: absolute;
            z-index: 1000;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            &:hover{
              background-position: 0 -79px;
            }
          }
        }
      }
      .slot-title{
        display: none;
      }
    }
  }
}
/* footer */
.footer-line{
  background: $footerBg;
  width: 100%;
  height: 10px;
  display: block;
  position: relative;
}
footer{
  //background: $footerBeforeBg;
  padding: 15px;
  .footer-before-block{
    .footer-img-cnt{
      display: block;
      color: #5b6267;
      padding-top: 10px;
      line-height: 20px;
      font-size: 13px;
      background: url('../img/footer-text.png') no-repeat left top transparent;
      background-size: contain;
      width: 100%;
      height: 210px;
      .footer-title{
        color: $footerTitleColor;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .footer-menu{
      ul{
        list-style: none;
        padding: 13px 0;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        li{
          display: block;
          a{
            display: block;
            color: $footerLinksColor;
            text-decoration: underline;
            padding: 5px 0;
            font-size: 15px;
            line-height: 18px;
            &:hover{
              color: $footerLinksHoverColor;
            }
          }
        }
      }
    }
  }
  .footer-news{
    border-top: 1px solid $borderColor;
    padding: 15px 0 0;
    .footer-title{
      color: $footerTitleColor;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 10px;
    }
    ul{
      text-align: center;
      padding: 0;
      list-style: none;
      margin: 10px 0 0;
      li{
        display: inline-block;
        vertical-align: top;
        margin-bottom: 15px;
        opacity: .7;
        text-align: center;
        width: 190px;
        &:hover{
          opacity: 1;
        }
        &:hover .footer-new-title{
          color: $footerLinksHoverColor;
        }
        img{
          width: 190px;
          border-radius: 5px;
          display: block;
          margin: 0 auto;
        }
        .footer-new-title{
          padding: 5px 3px;
          color: $footerLinksColor;
          text-decoration: underline;
          text-align: left;
          clear: both;
          font-size: 14px;
        }
      }
    }
  }
}

.under-footer-block{
  background: $footerBg;
  padding: 7px 0;
  color: $footerLinksColor;
  .soc-block{
    text-align: center;
    a{
      display: inline-block;
      width: 26px;
      height: 23px;
      background: url(../img/social-net-icons.png) 10px center no-repeat;
      margin-top: 5px;
      opacity: .7;
      &:nth-child(2) {
        background-position: -24px center;
      }
      &:nth-child(3) {
        background-position: -60px center;
      }
      //&:nth-child(4) {
      //  background-position: -93px center;
      //}
      &:nth-child(4) {
        background-position: -129px center;
        width: 35px;
      }
      &:hover{
        opacity: 1;
        font-size: 14px;
      }
    }
  }
  .copy-rights{
    color: $copyRights;
    padding: 5px 0 3px;
    font-size: 14px;
    text-align: center;
  }
  .payment-sys{
    text-align: center;
    float: none;
    height: 33px;
    display: block;
    a{
      display: inline-block;
      vertical-align: middle;
      &.footer-gambl-ter-link{
        width: 54px;
        height: 20px;
        background: url(../img/gamblingtherapy-logo.png) 0 center no-repeat;
      }
      &:nth-child(2){
        width: 40px;
        height: 33px;
        margin: 0 7px;
        background: url(../img/18-dmca-gpwa-icons.png) 0 center no-repeat;
      }
      &:nth-child(4){
        width: 46px;
        height: 33px;
        background: url(../img/18-dmca-gpwa-icons.png) -139px center no-repeat
      }
      &.dmca {
        width: 64px;
        height: 33px;
        background: url(../img/18-dmca-gpwa-icons.png) -56px center no-repeat;
        margin: 0 7px;
      }
    }
  }
}

/* casinos-template */
.category-casino-spellen{
  .line{
    margin-bottom: 23px !important;
  }
}
.casinos-template{
  position: relative;
  .part-title{
    font-size: 32px;
    line-height: 32px;
    margin: 15px 0;
  }
  .line{
    margin-bottom: 0;
  }
  .more-casino-btn{
    color: $btnColor;
    margin: 0 auto 15px;
    cursor: pointer;
    display: block;
    width: 198px;
    padding: 9px 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: $basicFontMediumWeight;
    border: 1px solid $moreCasinoBTNBorderColor;
    &:hover{
      color: $btnColor;
    }
  }
  .list-casinos{
    padding: 0;
    list-style: none;
    margin: 15px 0;
    text-align: center;
    li{
      border: 1px solid $borderColor;
      box-shadow: 1px 1px 12px $shadowColor;
      padding: 7px 7px 10px;
      border-radius: 5px;
      max-width: 225px;
      position: relative;
      min-width: 225px;
      margin: 0 auto 11px;
      display: inline-block;
      //background: $backgroundSlot;
      .casino-rating{
        padding: 0 25px;
      }
      .slot-img-over-block{
        border-bottom: 1px solid $borderColor;
        margin: -15px -7px 0;
        padding: 0 15px 8px;
        img{
          margin: 15px auto 0;
          display: block;
        }
      }
      .casino-btn-ref{
        width: 88%;
        position: relative;
        margin: 13px auto 2px;
        display: block;
      }
      .slot-title{
        font-weight: $basicFontMediumWeight;
        padding: 10px 0;
        font-size: 22px;
        line-height: 20px;
        display: block;
        height: 31px;
        overflow: hidden;
        color: $casinoListItemTitle;
        text-align: center;
        margin: 15px -8px 0;
        border-top: 1px solid $borderColor;
        &:hover{
          text-decoration: none;
          color: $casinoListItemHover;
        }
      }
      .casino-win{
        border-bottom: 1px solid $borderColor;
        padding: 18px 15px 5px;
        font-size: 13px;
        text-align: center;
        margin: 0 -8px;
      }
      .oversikt{
        display: inline-block;
        color: $linkColor;
        margin: 10px 0 0;
      }
      .casino-mode{
        text-align: center;
        position: relative;
        display: block;
        margin: 7px auto 0;
        vertical-align: middle;
        a {
          height: 33px;
          width: 32px;
          cursor: pointer;
          vertical-align: middle;
          display: inline-block;
          i{
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
}

/* top-rating-casinos */
.top-rating-casinos{
  .slot-title{
    border: 0 !important;
    margin: 5px -8px 0 !important;
  }
  .casino-win{
    border: 0 !important;
  }
  li{
    padding: 7px 7px 50px !important;
    .casino-btn-ref{
      width: 88%;
      margin: 13px auto 2px;
      display: block;
      position: absolute !important;
      left: 0;
      right: 0;
      bottom: 11px;
    }
  }
}

/* best-rating-casino-block */
.best-rating-casino-block{
  .promo-block-title{
    margin-bottom: -1px;
  }
  .one-sb-casino-item{
    border-top: 1px solid $borderColor;
    padding: 0;
    height: auto;
    overflow: hidden;
    &:nth-child(odd){
      background: $tabTableCasinoItemHover;
    }
    &:hover{
      background: $tabTableCasinoItemHover;
    }
    .casino-logo{
      border-right: 1px solid $borderColor;
      img{
        display: block;
        position: relative;
        margin: 5px auto;
        border-radius: 5px;
      }
    }
    .casino-sb-rating-block{
      margin-left: -15px;
      padding: 0 10px;
      .casino-rating{
        .progress-value{
          margin: 3px auto -10px;
        }
      }
      .casino-btn-ref{
        padding: 5px 0;
        width: 100%;
        margin: 0 0 5px;
        display: block;
        line-height: 18px;
      }
    }
  }
}

/* slots-template */
.slots-template{
  .part-title{
    font-size: 32px;
    line-height: 32px;
    margin: 15px 0 0;
    text-align: center;
  }
  .best-rating-casino-block{
    margin: 0 -15px;
  }
}

/* list-slots */
.list-slots{
  text-align: center;
  list-style: none;
  padding: 0;
  li{
    border: 1px solid $borderColor;
    padding: 0 0 100px;
    border-radius: 5px;
    max-width: 100%;
    box-shadow: 1px 1px 12px $shadowColor;
    position: relative;
    min-width: 100%;
    margin: 0 0 20px;
    overflow: hidden;
    cursor: pointer;
    display: inline-block;
    //background: $backgroundSlot;
    img{
      width: 100%;
      display: block;
      position: relative;
    }
    .slot-title{
      font-weight: $lightWeight;
      padding: 10px 0;
      font-size: 22px;
      line-height: 20px;
      display: block;
      height: 31px;
      overflow: hidden;
      color: $titleColor;
      text-align: center;
      margin: 5px 0 0;
      text-transform: capitalize;
    }
    .slot-software{
      padding: 5px 0;
      font-size: 15px;
      text-align: center;
      color: $breadcrumbsSpanColor;
    }
    .slot-btn-ref{
      margin: auto;
      width: 85%;
      position: absolute;
      bottom: 57px;
      left: 0;
      right: 0;
    }
    .slot-link{
      margin: auto;
      width: 85%;
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      color: $moreCasinoBTNColor;
      box-sizing: border-box;
      font-weight: $basicFontMediumWeight;
      background: $moreCasinoBTNBG;
      &:hover{
        color: $moreCasinoBTNColorHover;
        background: $moreCasinoBTNBGHover;
      }
    }
  }
}

/* page-numbs */
.page-numbs{
  text-align: center;
  a{
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 32px;
    box-shadow: 1px 1px 12px $shadowColor;
    font-weight: $mediumWeight;
    text-align: center;
    color: $basicFontColor;
    border-radius: 50%;
    border: 1px solid $borderColor !important;
    border-color: $borderColor !important;
    &:hover{
      background: $pageNumberBgHover;
      color: $pageNumberColorHover;
      border: 1px solid $pageNumberBgHover;
      border-color: $pageNumberBgHover !important;
      box-shadow: 1px 1px 12px $pageNumberBgHover;
      text-decoration: none;
    }
  }
  span{
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 32px;
    box-shadow: 1px 1px 12px $pageNumberBgHover;
    font-weight: $mediumWeight !important;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $pageNumberBgHover !important;
    border-color: $pageNumberBgHover !important;
    background: $pageNumberBgHover;
    color: $pageNumberColorHover;
  }
}

/* list-news */
.list-news{
  list-style: none;
  padding: 0;
  text-align: center;
  li{
    border: 1px solid $borderColor;
    padding: 0 0 30px;
    border-radius: 5px;
    max-width: 212px;
    box-shadow: 1px 1px 12px $shadowColor;
    position: relative;
    min-width: 212px;
    margin: 0 0 20px;
    overflow: hidden;
    display: inline-block;
    img{
      display: block;
      margin: 15px auto;
      border-radius: 5px;
    }
    .news-title{
      padding: 10px;
      font-size: 15px;
      line-height: 15px;
    }
    .news-date{
      color: $newsDateColor;
      text-align: left;
      padding: 0 10px 10px;
      position: absolute;
      bottom: 0;
    }
  }
  li.payment-system{
    padding: 0;
    .news-title{
      margin-top: 15px !important;
      border-top: 1px solid $borderColor !important;
    }
  }
}

.category-content{
  h1{
    text-align: center;
    line-height: 40px;
    font-size: 38px;
  }
}
.news-template{
  .best-rating-casino-block, .news-block, .new-games-block{
    margin: 0 -15px 15px;
  }
}

/* new-template */
.new-template{
  .content{
    img{
      border-radius: 8px;
      max-width: 100%;
      height: auto;
      width: 100%;
      display: block;
      margin: 10px auto;
    }
  }
  .best-rating-casino-block{
    margin: 0 -15px;
  }
}

/* breadcrumbs */
.breadcrumbs-bg{
  background: $breadcrumbsBG;
}
.breadcrumbs-block{
  padding: 10px 0;
  color: $breadcrumbsLinkColor;
  font-weight: $basicFontRegularWeight;
  a{
    color: $breadcrumbsLinkColor;
    text-decoration: underline;
    &:hover{
      color: $breadcrumbsLinkHoverColor;
    }
  }
  span{
    color: $breadcrumbsSpanColor;
    text-decoration: none;
  }
}

/* 404 error */
.error-template{
  .error-code{
    font-size: 180px;
    line-height: 250px;
    color: $errorCodeColor;
    text-align: center;
  }
  .error-text{
    text-align: center;
    padding: 15px;
    font-size: 22px;
    color: $errorTextColor;
  }
  .casino-btn-ref{
    width: 150px;
    display: block;
    margin: 10px auto 100px;
  }
}

/* search-template */
.search-template{
  .search-list{
    padding: 0;
    list-style: none;
    margin: 15px 0;
    li{
      display: block;
      margin-bottom: 25px;
      border-bottom: 1px solid $borderColor;
      .title{
        font-size: 22px;
        color: $titleColor;
        font-weight: $mediumWeight;
        margin-bottom: 10px;
      }
      .text{
        padding: 0 0 15px;
      }
      .link{
        color: $linkColor;
      }
    }
  }
}
.nothing-find{
  text-align: center;
}

/* news-block */
.news{
  margin-bottom: 30px;
  .one-new{
    height: 130px;
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid $borderColor;
    border-radius: 5px;
    position: relative;
    background: $oneNewBG;
    @extend %flex-row-wrap;
    &.opened{
      height: auto;
      .hidden-block-new{
        display: none;
      }
    }
    .hidden-block-new{
      display: block;
      position: absolute;
      height: 50px;
      z-index: 100;
      width: 100%;
      bottom: 0;
      background: rgba(255,255,255,0.75);
      background: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%);
      &.no-active{display: none;}
    }

    &.wide{
      height: auto;
      .show-text {
        background: url(../img/up-rarr.png) right center no-repeat;
      }
    }
    .hidden-block{
      height: 60px;
    }
    .new-description{
      padding: 10px 10px 25px;
      img{
        float: left;
        margin-right: 10px;
        z-index: 100;
        position: relative;
        max-width: 100%;
        height: auto;
        border: none;
        border-radius: 5px;
      }
      .new-img{
        position: relative;
        z-index: 1000;
      }
      .new-title{
        color: $titleColor;
        font-weight: $basicFontSemiBoldWeight;
        padding: 5px 0;
        font-size: $basicFontSize;
      }
      .new-content{
        font-size: 14px;
        line-height: 22px;
      }
    }
    .show-text, .more-text{
      position: absolute;
      bottom: 3px;
      right: 10px;
      color: #2ac25e;
      font-size: 14px;
      font-weight: $basicFontSemiBoldWeight;
      padding: 0 20px 0 0;
      z-index: 100;
      cursor: pointer;
      i{
        width: 18px;
        height: 10px;
        display: block;
        position: absolute;
        top: 7px;
        right: 0;
      }
    }
  }
}

/* my-comments-block */
.my-comments-block{
  padding: 25px 15px;
  margin: 30px 0 0;
  .one-comment-cnt{
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 10px 0 0;
    &.opened{
      height: auto;
      .hidden-block{
        display: none;
      }
    }
    .comment-author{
      font-weight: $mediumWeight;
      .comment-date{
        color: $linkColor;
        margin-left: 15px;
      }
    }
    .comment-text{
      padding: 5px 0;
      font-size: 14px;
    }
  }
  .more-comments{
    a{
      color: $linkColor;
    }
  }
}

/* one casino */
.casino-review-template{
  h1{
    color: $titleColor;
    margin-bottom: 0;
    text-align: center;
    font-weight: $basicFontSemiBoldWeight;
  }
  .casino-img-cnt{
    border: 1px solid $borderColor;
    box-shadow: 1px 1px 12px $shadowColor;
    border-radius: 5px;
    margin: 15px auto 0;
    width: 100%;
    display: block;
    position: relative;
    .casino-single-img{
      position: relative;
      display: block;
      margin: 15px auto;
    }
  }
  .content{
    .casino-big-btn{
      display: block;
      text-align: center;
      color: $btnTextColor;
      border-radius: 3px;
      font-size: 24px;
      line-height: 24px;
      padding: 5px 10px 10px;
      width: 100%;
      margin-top: 0;
      background: linear-gradient(0deg,#f6a237,#f8b746);
      &:hover{
        background: linear-gradient(0deg,#f8b746,#f6a237);
      }
    }
    h1{
      color: $titleColor;
      padding-right: 100px;
      margin-bottom: 10px;
    }
    .casino-big-btn{
      display: block;
      text-align: center;
      color: $btnTextColor;
      border-radius: 5px;
      font-size: 20px;
      line-height: 26px;
      padding: 5px;
      width: 100%;
      margin-top: 0;
      font-weight: $basicFontSemiBoldWeight;
    }
  }
  .casino-desc-cnt{
    padding: 5px 0 15px;
    .casino-plus-minus-cnt{
      display: block;
      position: relative;
      padding-bottom: 13px;
      height: auto;
      .casino-prop-table{
        display: table;
        width: 100%;
        margin: 10px auto;
        .casino-plus-block, .casino-minus-block{
          display: table-cell;
          vertical-align: top;
          width: 50%;
          ul,ol{
            padding: 0;
            margin: 0;
            list-style: none!important;
            li{
              display: block;
              position: relative;
              font-size: 14px;
              padding: 0 5px 0 15px;
              line-height: 16px;
              margin-bottom: 5px;
              &:before{
                content: '+';
                left: 0;
                display: block;
                position: absolute;
                font-size: 23px;
                color: #dd0000;
                border: 0;
                top: -2px;
              }
            }
          }
        }
        .casino-minus-block{
          ul,ol{
            li{
              &:before{
                content: '-';
                color: #555555;
              }
            }
          }
        }
      }
    }
  }
  .casino-progress{
    width: 160px;
    display: block;
    position: relative;
    margin: 0 auto 5px;
    text-align: center;
  }
  .line {
    background: $borderColor;
    height: 1px;
  }

  .casino-left-properties-block{
    border-radius: 5px;
    height: auto;
    width: 100%;
    border: 1px solid $borderColor;
    box-shadow: 1px 1px 12px $shadowColor;
    margin-top: 8px;
    background: $blocksBG;
    .properties-block{
      display: block;
      position: relative;
      .properties-block-item, .properties-pass{
        border-bottom: 1px solid $borderColor;
        padding: 7px 30px 7px 14px;
        //background: url(../img/icon-down.png) right 5px top 20px no-repeat;
        position: relative;
        i{
          display: block;
          position: absolute;
          top: 17px;
          right: 6px;
          &.icon-icon-down{
            cursor: pointer;
          }
        }
        span{
          i{
            display: block;
            position: absolute;
            top: 9px;
            right: 6px;
          }
        }
        a{
          i{
            display: block;
            position: absolute;
            top: 5px;
            right: 6px;
          }
        }
        &.active{
          //background: url(../img/icon-top.png) right 5px top 20px no-repeat;
        }
        &.pass{
          background: none;
          cursor: default;
          border-bottom: 0;
          padding: 10px 30px 9px 14px;
        }
        .properties-block-item-hidden{
          display: none;
          margin-top: 5px;
          &.active{
            display: block;
          }
        }
        .casino-mode{
          display: inline-block;
          text-align: left;
          span, a{
            height: 32px;
            width: 32px;
            vertical-align: middle;
            display: inline-block;
            position: relative;
            i{
              display: block;
              margin: auto;
              position: absolute;
              top: 0;
              bottom: 0;
            }
          }
        }
        .char-flag{
          width: 34px;
          height: 30px;
          vertical-align: middle;
          margin: 3px 0 3px -5px;
          display: inline-block;
          position: relative;
          border-right: 1px solid $borderColor;
          span{
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: default;
            i{
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
        .char-currency{
          width: 34px;
          height: 30px;
          vertical-align: middle;
          margin: 3px 0 3px -5px;
          display: inline-block;
          position: relative;
          border-right: 1px solid $borderColor;
          span{
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: default;
            i{
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
        .payment-flag{
          width: 63px;
          height: 45px;
          vertical-align: middle;
          display: inline-block;
          position: relative;
          border: 1px solid $borderColor;
          margin: 2px -1px 2px 0;
          span{
            width: 62px;
            height: 45px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: default;
            i{
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
          a{
            i{
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              cursor: pointer;
            }
          }
        }
        span{
          &.title{
            font-size: 14px;
            border-right: 1px solid $borderColor;
            padding: 4px 10px 4px 0;
            color: $linkColor;
          }
        }
        &.active{
          //background: url(../img/icon-top.png) right 5px center no-repeat;
        }
      }
      .properties-block-item:last-child{
        border-bottom: 0;
        padding: 10px 30px 9px 14px;
      }
      .properties-block-item.payment-cnt{
        padding: 0 30px 1px 14px;
      }
    }
    .product-properties{
      ul{
        list-style: none;
        margin: 0 auto -1px;
        padding: 0;
        border-radius: 5px;
        box-shadow: 1px 1px 12px $shadowColor;
        li{
          border-bottom: 1px solid $borderColor;
          padding: 10px 0 10px 40px;
          margin: 0;
          color: $linkColor;
          position: relative;
          font-size: 14px;
          font-weight: $basicFontSemiBoldWeight;
          &:last-child{
            border-bottom: 0;
          }
          &:hover, &:active{
            color: $titleTabColor;
            background: $tabTableCasinoItemHover;
          }
          .drop-block{
            display: none;
            border-top: 1px solid $borderColor;
            border-bottom: 1px solid $borderColor;
            border-left: 1px solid $borderColor;
            background: $blocksBG;
            position: absolute;
            width: 100%;
            left: -1px;
            z-index: 10;
            padding: 13px;
            margin-top: 9px;
            height: auto;
            max-height: 226px;
            overflow-y: scroll;
            right: -1px;
            span{
              display: block;
              color: $linkColor;
              font-size: 12px;
              padding: 1px 0 1px 12px;
              position: relative;
              //cursor: pointer;
              i{
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
              }
              &:before{
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $linkColor;
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 10px;
              }
            }
            a{
              display: block;
              color: $linkColor;
              font-size: 12px;
              padding: 1px 0 1px 12px;
              position: relative;
              cursor: pointer;
              text-decoration: underline;
              i{
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
              }
              &:before{
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $linkColor;
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 10px;
              }
              &:hover{
                text-decoration: none;
              }
            }
            &.active{
              display: block;
            }
            .casino-mode{
              display: inline-block;
              span{
                height: 35px;
                width: 32px;
                vertical-align: middle;
                display: inline-block;
                &:before{display:none;}
              }
              //.Instant-Play{background: url(../img/desktop-icon.png) center no-repeat;}
              //.Mobile{background: url(../img/mobile-icon.png) center no-repeat;}
              //.Download{background: url(../img/download-icon.png) center no-repeat;}
            }
            .char-flag{
              width: 34px;
              height: 30px;
              vertical-align: middle;
              margin: 3px 0 3px -5px;
              display: inline-block;
              position: relative;
              border-right: 1px solid $headerMenuLinkBorderColor;
              span{
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: default;
                &:before{display: none;}
              }
              a{
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                &:before{display: none;}
              }
            }
            .char-currency{
              width: 34px;
              height: 30px;
              vertical-align: middle;
              margin: 3px 0 3px 0;
              display: inline-block;
              position: relative;
              border-right: 1px solid $borderColor;
              span{
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: default;
                &:before{display: none;}
              }
              a{
                position: absolute;
                padding: 0;
                width: 24px;
                height: 20px;
                display: block;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                &:before{
                  display: none;
                }
                i{
                  display: block;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  left: 0;
                  right: 0;
                }
              }
            }
            .payment-flag{
              width: 63px;
              height: 45px;
              vertical-align: middle;
              display: inline-block;
              position: relative;
              border: 1px solid $borderColor;
              margin: 2px -2px 2px 0;
              span{
                width: 62px;
                height: 45px;
                display: block;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: default;
                &:before{display: none;}
              }
              a{
                position: relative;
                padding: 0;
                &:before{
                  display: none;
                }
                i{
                  position: relative;
                  margin: auto;
                  display: block;
                }
              }
            }
          }
          .icon-char{
            position: absolute;
            left: 8px;
            top: 11px;
            margin: auto;
          }
          .icon {
            //background: url(../img/icon-down.png) center no-repeat;
            display: block;
            position: absolute;
            width: 32px;
            height: 34px;
            top: 5px;
            right: 0;
            cursor: pointer;
            &.active{
              //background: url(../img/icon-top.png) center no-repeat;
            }
            i{
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
  .casino-screens{
    margin: 15px 0;
    border: 1px solid $borderColor;
    box-shadow: 1px 1px 12px $shadowColor;
    border-radius: 5px;
    width: 100%;
    padding: 15px;
    background: $screenBG;
    img{
      border-radius: 5px;
      max-width: 100%;
      margin: 0 auto 15px;
      height: auto;
      width: auto;
      display: block;
      border: 1px solid $borderColor;
    }
    .iframe{
      border-radius: 8px;
      width: auto;
      overflow: hidden;
      border: 1px solid $borderColor;
      iframe,object{
        height: auto;
        border-radius: 5px;
        max-width: 100%;
      }
    }
  }
  .hidden-text{
    overflow: hidden;
  }
}

/* menu-link-go-to */
.fixed-cnt {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.menu-link-go-to{
  ul{
    padding: 0;
    margin: 15px 0 15px 1px;
    @extend %flex-row-wrap;
    li{
      cursor: pointer;
      border: 1px solid $borderColor;
      margin: 0 -1px -1px;
      background: $tabsBg;
      display: block;
      position: relative;
      width: 100%;
      i{
        display: none;
        position: absolute;
        margin: -5px auto 0;
        left: 0;
        right: 0;
      }
      &:hover{
        background: $tabHoverBg !important;
        i{
          display: block;
        }
      }
      &.active{
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        a{
          color: $titleTabColor;
          text-decoration: none;
          background: $tabHoverBg !important;
        }
        i{
          display: block;
        }
      }
      a{
        display: block;
        padding: 10px;
        color: $titleTabColor;
        text-decoration: none;
        font-size: 13px;
        font-weight: $basicFontRegularWeight;
        &:hover{
          color: #1ac2c2;
          text-decoration: none;
        }
      }
    }
  }
}

/* popup */
.popup-cont{
  width:96%;
  height:auto;
  z-index:100001;
  position:fixed;
  left:0;
  right:0;
  margin:auto;
  top:0;
  bottom:0;
  display:none;
  overflow:hidden
}
.popup-cont iframe,.popup-cont object{
  width:350px;
  height:265px;
  border:none;
  background: #000;
  display: block;
  margin: 15px auto 35px;
}
.shadow{
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.8);
  width:100%;
  height:100%;
  position:fixed;
  z-index:100000;
  display:none
}
.button{
  outline:none;
  display:block;
  height:80px;
  width:90%;
  cursor:pointer;
  text-decoration:none !important;
  margin:20px auto
}
.button .outer{
  position:relative;
  width:100%;
  height:100%;
  padding:0;
  background:rgba(0,0,0,1);
  box-shadow:inset rgba(0,0,0,0.85) 0 1px 5px;
  transform:perspective(500px) rotateX(35deg);
}
.button .outer .height{
  position:relative;
  height:100%;
  margin-top:-25px;
  padding-bottom:25px;
  background: $btnBg;
  box-shadow: rgba(0,0,0,0.85) 0 1px 1px,inset rgba(0,0,0,0.35) 0 -2px 8px;
  transition:all .1s ease-in-out;
}
.button:hover .outer .height{
  margin-top:-10px;
  padding-bottom:10px;
  background: $btnBgHover;
  box-shadow:rgba(0,0,0,0.25) 0 1px 1px,inset rgba(0,0,0,0.35) 0 -2px 6px;
  text-decoration: none !important;
}
.button:active .outer .height{
  margin-top:0;
  padding-bottom:0
}
.button .outer .height .inner{
  line-height:68px;
  font-size:30px;
  letter-spacing:.05em;
  position:relative;
  height:100%;
  text-align:center;
  text-shadow: #000 0 0 1px;
  background: $btnBg;
  background:linear-gradient(top,$btnBg 0%,$btnBgHover 100%);
  box-shadow:inset rgba(255,255,255,0.85) 0 0 1px;
  transition:all .1s ease-in-out;
  color:#fff
}
.button:hover .outer .height .inner{
  text-shadow: $shadowColor 0 0 1px;
  background: $btnBgHover;
  background:linear-gradient(top,$btnBgHover 0%,$btnBg 100%);
  text-decoration: none;
}
.button:active .outer .height .inner{
  text-shadow:$btnBgHover 0 1px 0;
  box-shadow:inset rgba(0,0,0,0.9) 0 0 8px;
  background:$btnBgHover;
  background:linear-gradient(top,$btnBgHover 0%,$btnBg 100%);
  text-decoration: none;
}

/* demo */
.slot-background {
  /*background: $slotBG;*/
  border-bottom: 1px solid $headerMenuLinkBorderColor;
  .category-content{
    h1{
      margin: 25px auto 10px;
      font-weight: $basicFontRegularWeight;
      line-height: 24px;
      font-size: 24px;
    }
  }
}

.demo-container{
  display: block;
  margin: 5px 0 0;
  width: 100%;
  .demo-left{
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 25px;
    .one-sb-casino-item {
      padding: 8px 0;
    }
    .promo-block-left{
      border: 0;
      box-shadow: none;
    }
  }
  .demo{
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 25px;
    iframe,object{
      display: block;
      width: 100%;
      border-radius: 5px;
      background: #000;
      border: 0;
    }
    .gameItem {
      padding: 0;
      background: url(../img/slot-bg.jpg) 50% no-repeat;
      background-size: cover;
      height: 100%;
      display: block;
      width: 100%;
      position: relative;
      z-index: 3;
      border-radius: 5px;
      &:hover{
        background: url(../img/slot-bg-hover.jpg) 50% no-repeat;
        background-size: cover;
      }
      .play-big-slot-btn {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .bonus-btn-block {
      position: relative;
      margin-top: 20px;
      border-radius: 10px;
      overflow: hidden;
      height: 60px;
      padding: 10px 15px;
      box-sizing: border-box;
      background: url(../img/bonus-btn-bg.jpg) no-repeat top;
      background-size: 100% 100%;
      display: block;
      .demo-bonus-btn {
        display: block;
        width: 100%;
        height: 100%;
        .btn__left-block {
          display: inline-block;
          width: 32%;
          .casino-name {
            position: relative;
            height: 20px;
            width: auto;
            color: #919497;
            font-size: 16px;
            padding: 0 0 0 23px;
            margin-top: -5px;
          }
          .bonus-size {
            position: relative;
            margin-top: 22px;
            color: #fff;
            font-size: 48px;
            font-weight: $basicFontSemiBoldWeight;
          }
        }
        .btn__right-block {
          float: right;
          width: 68%;
          height: 80px;
          line-height: 43px;
          font-size: 26px;
          font-weight: 400;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  .demo-right{
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 25px;
    &.promo-block-left{
      border: 0;
      box-shadow: none !important;
    }
    .char-block{
      display: block;
      position: relative;
      .char-item{
        text-align: left;
        padding: 5px 15px;
        border-top: 1px solid $borderColor;
        span{
          text-align: left;
          width: 65%;
          display: inline-block;
          font-size: 14px;
          line-height: 16px;
          color: $titleBlockColor;
          font-weight: $basicFontBoldWeight;
          vertical-align: middle;
        }
        span:nth-child(1){
          color: $sidebarLinkColor;
          font-weight: $basicFontMediumWeight;
        }
        span:nth-child(2){
          width: 32%;
        }
        &:nth-child(even){
          //background: $bgODDColor;
          background: $tabTableCasinoItemHover;
        }
      }
    }
  }
}
/* END demo */

/* comments */
.comments-bg-container{
  background: $commentsBgContainer;
  .comments-template-block{
    .comment-reply-title, .logged-in-as, .comment-form-url, .comment-notes{
      display: none;
    }
    .comment-form-author, .comment-form-comment, .comment-form-email {
      background: $commentsBgContainerBlock;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 1px 1px 12px $borderColor;
      //border: 1px solid $borderColor;
      margin: 15px 0;
    }
    .comment-form-comment{
      margin: 0;
    }
    label {
      color: $titleColor;
      display: block;
      font-size: 14px;
      font-weight: $basicFontSemiBoldWeight;
    }
    input[type=email], input[type=text], textarea {
      border-radius: 5px;
      background: $commentsBgField;
      border: 1px solid $commentsBorder;
      font-size: 14px;
      padding: 5px;
      width: 100%;
      &:focus{
        border-color: #b7afaf !important;
      }
    }
    input[type=submit] {
      background: $btnBg;
      border: 0;
      color: #fff;
      padding: 10px;
      width: 220px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .comments-cnt{
    .one-comment-cnt{
      height: auto;
      overflow: hidden;
      position: relative;
      padding: 10px 0 0;
      &.opened{
        height: auto;
        .hidden-block{
          display: none;
        }
        .comment-text{
          height: auto;
        }
      }
      .comment-author {
        font-weight: $basicFontSemiBoldWeight;
        .comment-date {
          color: $readMoreColor;
          position: absolute;
          right: 10px;
        }
      }
      .comment-text {
        padding: 5px 0;
        font-size: 14px;
        height: 100px;
      }
      .btn-show-comment{
        display: block;
        position: absolute;
        right: 15px;
        cursor: pointer;
        bottom: -5px;
        z-index: 10;
        color: #2ac25e;
        font-size: 14px;
        font-weight: $basicFontSemiBoldWeight;
      }
      .show-text, .more-text{
        position: absolute;
        bottom: -1px;
        right: 10px;
        color: $readMoreColor;
        font-size: 14px;
        padding: 0 20px 0 0;
        z-index: 100;
        cursor: pointer;
        i{
          width: 18px;
          height: 10px;
          display: block;
          position: absolute;
          top: 7px;
          right: 0;
        }
        &:hover, &:active{
          color: $readMoreHoverColor;
        }
      }
      &:hover{
        color: $basicFontColor;
      }
    }
  }
}
/* END comments */

/* developers */
.sorting{
  padding: 18px 15px;
  border: 1px solid $borderColor;
  box-shadow: 1px 1px 12px $shadowColor;
  display: block;
  margin: 0 -25px;
  color: #818181;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  position: relative;
  .icon{
    .icon-icon-down{
      display: block;
      position: absolute;
      width: 20px;
      height: 18px;
      top: 0;
      right: 10px;
      cursor: pointer;
      bottom: 0;
      margin: auto;
    }
  }
  &.active{
    background: $tabsBg;
    color: $titleTabColor;
  }
}
.sorting_panel, .sorting_panel_dev, .sorting_panel_payment{
  display: none;
  background: $sortingPanelBG;
  padding: 15px;
  border: 1px solid $borderColor;
  text-align: center;
  margin: 0px -26px;
  position: relative;
  &.active{
    display: block;
  }
  a{
    display: inline-block;
    color: $sortingLinkColor !important;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    margin: 0 15px 0 0;
    font-size: 16px;
    i{
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.sort-item-asc {
      padding: 9px 9px 7px 0;
    }
    &.sort-item-desc {
      padding: 9px 9px 7px 0;
    }
    &:hover{
      text-decoration: underline !important;
    }
  }
}

.list-developers{
  li{
    .slot-img-over-block{
      margin: -7px !important;
      border-bottom: 0 !important;
      img{
        border-radius: 5px;
      }
    }
    .slot-title{
      position: relative;
      text-align: left !important;
      padding: 10px 45px 10px 13px !important;
      font-size: 16px !important;
      text-transform: uppercase;
      font-weight: 400 !important;
      height: 38px !important;
      margin: 15px -8px -5px !important;
      line-height: 23px !important;
      span{
        display: block;
        position: absolute;
        right: 7px;
        top: 6px;
        padding: 2px 9px;
        border-radius: 5px;
        background: $sbCasinoItemHoverBG;
        font-weight: $lightWeight;
        color: #fff;
      }
    }
  }
}
/* END developers */

/* all-bonuses-list */
.all-bonuses-list{
  li{
    padding: 0 0 15px;
    .typ-bonus{
      margin: 12px 0 15px;
      font-size: 13px;
      text-align: center;
      height: 20px;
      overflow: hidden;
    }
    .bonus_img_block{
      padding: 0 17px 15px;
      border-bottom: 1px solid $borderColor;
      margin-bottom: 15px;
      img{
        border-radius: 5px;
        max-width: 100%;
      }
    }
    .news-title{
      text-align: center;
      display: block;
      font-size: 20px;
      border: 0;
      &:hover{
        text-decoration: none;
        color: $titleBlockColor;
      }
    }
    .bonus-text{
      font-size: 13px;
      line-height: 22px;
      padding: 5px 15px 0;
      text-align: center;
    }
    .bonus-btn-block{
      text-align: center;
      padding: 15px 15px 0;
      .btn{
        width: 100%;
      }
    }
  }
}

.html,.hljs,.xml{
  h2,h3,h4{
    color: #8f8f8f;
    text-transform: uppercase;
  }
  a{
    &:hover{
      color: #fff;
    }
  }
  p{
    color: #8f8f8f;
  }
}

.play-btn-container{
  padding: 20px;
}


@media screen and (max-width: 414px){
  .casinos-template .top-rating-casinos {
    li {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
  }

  .casinos-template{
    .list-casinos{
      li {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  .front-slots-cnt{
    .slots-block{
      ul{
        li{
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .list-news{
    li {
      max-width: 100%;
      min-width: 100%;
    }
  }

}

.how_many{
  text-transform: uppercase;
  position: relative;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  height: 22px;
  font-size: 18px;
  display: block;
  text-align: center;
}

.casinos-technical-template{
  .list-casinos{
    li{
      border: 1px solid $borderColor;
      box-shadow: 1px 1px 12px $borderColor;
      padding: 7px 7px 10px;
      border-radius: 5px;
      max-width: 100%;
      position: relative;
      min-width: 100%;
      margin: 0 0 15px;
    }
  }
  .promo-block-left {
    border-radius: 0;
    margin: 0 -10px 0;
  }
  .promotion-block {
    margin: 5px -10px 0;
    margin: 0 -10px;
  }
}

.is_technical{
  .list-technical-link{
    margin: 15px auto;
    padding: 0;
    li{
      display: inline-block;
      list-style: none;
      a{
        padding: 5px 4px 5px 0;
        font-size: $basicFontSize;
        line-height: $basicFontSize;
        &:hover {
          color: $menuLinkHoverColor;
        }
      }
    }
  }
  .promo-block-left{
    border-radius: 0;
    margin: 0 -10px;
  }
  .promotion-block{
    border-radius: 0;
    margin: 0 -10px;
  }
}

.icon-icon-top, .icon-iconUp {
  background-image: url(sprite.png);
}

.icon-iconUp {
  background-position: 0 -41px;
  width: 62px;
  height: 62px;
}

.to_top {
  display: none;
  position: fixed;
  z-index: 4;
  cursor: pointer;
  bottom: 20px;
  height: 53px;
  width: 60px;
  right: 10px;
}

.to_top.display {
  display: block;
}

@import 'fade-slider';