/**
 ** bonus slider styles
 */

.icon-svg {
  display: block;
  max-width: 65%;
  margin: 0 auto;
  height: 44px;
  fill: #fff;
}
.new-bonuses-cnt {
  display: block;
  position: relative;
  margin: 15px 0
}
.fade-slider-container{
  display: block;
  position: relative;
  margin-bottom: 15px;
}
.bonus-kinds-cnt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid $borderColor;
  border-radius: 5px 5px 0 0;
  //box-shadow: 0 -3px 12px $shadowColor;
  overflow: hidden;
}

.one-kind {
  display: inline-block;
  background: $tabsBg;
  border-right: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  width: 10%;
  height: 92px;
  cursor: pointer
}

.one-kind.active, .one-kind.super-active{
  background: #fff;
  .kind-icon{
    background: #fff;
    .icon-svg{
      fill: $kindIconBg;
    }
  }
  .kind-text{
    color: $kindIconBg;
  }
}
.one-kind:first-child {
  border-left: 0;
  border-radius: 3px 0 0 0;
}

.one-kind:last-child{
  border-right: 0;
  margin-right: -1px;
  border-radius: 0 3px 0 0;
}

.kind-icon {
  display: block;
  width: 50px;
  height: 50px;
  background: $kindIconBg;
  border-radius: 50%;
  margin: 7px auto 0;
  padding: 3px
}

.one-kind.no-active {
  cursor: default;
  .kind-icon{
    background: #d0d0d0;
  }
  .kind-text {
    color: #d0d0d0;
  }
}

.kind-icon img {
  display: block;
  max-width: 65%;
  margin: 0 auto;
  height: 40px
}

.kind-text {
  text-align: center;
  color: $titleTabColor;
  font-size: 11px;
  font-weight: $basicFontSemiBoldWeight;
  line-height: 13px;
  padding: 5px 4px
}

.bonus-kind-item-slider-cnt {
  border: 1px solid $borderColor;
  border-radius: 3px 3px 0 0;
  //box-shadow: 0 -3px 12px $borderColor;
  padding: 0 65px;
  margin-top: -1px;
  display: none;
  position: relative
}

.bonus-kind-item-slider-cnt.no-active {
  display: none
}

.bonus-slider-block {
  width: 75%
}

.bonus-slider {
  height: 240px;
  overflow: hidden
}

.slider-item {
  position: relative;
  width: 786px !important
}

.bonus-img-cnt {
  width: 23%;
  position: relative
}

.img-block {
  width: 190px;
  height: 190px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.bonus-img-cnt img {
  display: block;
  margin: auto
}

.bonus-desc {
  width: 530px;
  padding: 20px 30px;
  float: left;
  display: block
}

.bonus-text-preview {
  font-size: 16px;
  padding-right: 15px
}

.bonus-title {
  color: $basicFontColor;
  font-size: 30px;
  line-height: 35px;
  margin: 0 0 20px;
  font-weight: $basicFontBoldWeight;
}

.slider-item-btn-cnt {
  width: 209px;
  display: block;
  float: left;
  position: relative;
  height: 100px;
  margin: 73px 20px
}

.slider-item-btn-cnt .d-bonus-btn {
  width: 90%;
  padding: 22px 10px;
  font-size: 27px;
  margin: 90px auto
}

.slider-item-btn-cnt a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100
}

.slider-item-btn-cnt iframe {
  max-width: 210px;
  pointer-events: none;
  position: relative;
  z-index: 10;
  height: 100px
}

.bonus-slider-block .bx-controls {
  text-align: center;
  margin-left: -23%
}

.bonus-slider-block .bx-viewport {
  height: 220px !important
}
.fade-slider-wrapper-block{
  display: block;
  position: relative;
}
.left-rarr{
  width: 32px;
  position: absolute;
  background: $tabsBg;
  left: 1px;
  color: transparent;
  text-indent: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  i{
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.left-rarr:hover,
.right-rarr:hover
{
  background-color: #fff
}
.right-rarr{
  width: 32px;
  position: absolute;
  background: $tabsBg;
  right: 1px;
  color: transparent;
  text-indent: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  i{
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.one-kind-slider.active{
  display: flex;
  position: relative;
}
.one-slide{
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
.one-slide.active{
  -webkit-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  transition: opacity 1s linear;
  opacity: 1;
}
.paging{
  text-align: center;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 10px auto;
  bottom: -5px;
}
.paging span{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $moreCasinoBTNBG;
  cursor: pointer;
  margin: 2px 0;

}
.paging span.active{
  background: $titleTabBorderColor
}

/**
Mobile bonuses
 */
.mobile-bonuses {
  display: block;
  position: relative
}

.mobile-bonuses .new-bonuses-cnt {
  margin: 7px -30px;
}

.mobile-bonuses .bonus-kinds-cnt {
  display: block;
  align-items: inherit;
  justify-content: inherit;
  flex-direction: inherit;
  width: 1060px;
  border-radius: 0;
}

.mobile-bonuses .one-kind {
  display: inline-block;
  width: 108px;
  margin: 0 -3px;
  vertical-align: top;
  height: 106px;
  border-radius: 0;
}

.mobile-bonuses .scroll-cnt {
  max-width: 100%;
  overflow-x: scroll
}

.mobile-bonuses .bonus-kind-item-slider-cnt{
  border: none;
  padding: 0 20px;
  margin-top: -1px;
  display: none;
}
.mobile-bonuses .bonus-kind-item-slider-cnt.active {
  display: block !important;
}
.mobile-bonuses .bonus-kind-item-slider-cnt.no-active {
  display: none;
}

.mobile-bonuses .bonus-img-cnt {
  width: 100%;
  display: block;
  margin: 15px auto
}

.mobile-bonuses .img-block {
  position: relative;
  width: 150px;
  height: 150px
}

.mobile-bonuses .bonus-slider-block {
  width: 100%;
  border-bottom: 1px solid $borderColor;
}

.mobile-bonuses .bonus-slider {
  height: 500px;
  min-height: 100%;
  overflow: inherit;
  display: block;
  position: relative;
}

.mobile-bonuses .bonus-slider-block .bx-viewport {
  height: 540px !important;
  min-height: 100%
}

.mobile-bonuses .slider-item {
  width: auto !important
}

.mobile-bonuses .bonus-slider-block .bx-controls {
  margin-left: 0
}

.mobile-bonuses .bonus-desc {
  width: auto;
  padding: 20px 30px 0;
  float: none;
  display: block;
  text-align: center
}

.mobile-bonuses .slider-item-btn-cnt {
  float: none;
  padding: 0;
  margin: 15px auto;
  width: 210px
}

.mobile-bonuses .bonus-title {
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  max-height: 103px;
  overflow: hidden
}

.mobile-bonuses .img-block iframe {
  width: 150px;
  height: 150px
}

.icon-svg {
  display: block;
  max-width: 65%;
  margin: 0 auto;
  fill: #fff
}

.mobile-bonuses .kind-text {
  padding: 3px 5px
}

//.mobile-bonuses .one-kind.active .kind-icon,
//.mobile-bonuses .one-kind.super-active .kind-icon
//{
//  background: 0 0
//}

//.mobile-bonuses .one-kind.active .kind-icon .icon-svg,
//.mobile-bonuses .one-kind.super-active .kind-icon .icon-svg
//{
//  stroke: #186aa3;
//  fill: #186aa3
//}

.mobile-bonuses .bonus-text-preview {
  font-size: 14px;
  padding-right: 0;
  max-height: 100px;
  overflow: hidden
}
