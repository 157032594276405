@charset "UTF-8";
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "https://fonts.googleapis.com/css?family=Barlow:100,300,400,500,600,700,800,900&amp;subset=latin-ext,vietnamese";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table tbody + tbody {
  border-top: 2px solid #dee2e6; }

.table .table {
  background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6; }

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e; }

.table-dark.table-bordered {
  border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-responsive > .table-bordered {
  border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto; }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover, .btn:focus {
  text-decoration: none; }

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled, .btn:disabled {
  opacity: 0.65; }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62; }

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent; }

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none; }

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1; }

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0; }

.dropleft .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center; }

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%; }

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px; }

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3; }

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4; }

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2; }

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6; }

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff; }

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-range:focus {
  outline: none; }

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range::-moz-focus-outer {
  border: 0; }

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none; } }

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none; } }

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none; } }

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem; }

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none; } }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }

.nav-link:hover, .nav-link:focus {
  text-decoration: none; }

.nav-link.disabled {
  color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none; }

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a {
  color: #fff; }

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0; }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0; }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0; }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0; }
  .card-group > .card:only-child {
    border-radius: 0.25rem; }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
  .card-columns .card {
    display: inline-block;
    width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-primary hr {
  border-top-color: #9fcdff; }

.alert-primary .alert-link {
  color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.alert-secondary hr {
  border-top-color: #c8cbcf; }

.alert-secondary .alert-link {
  color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-success hr {
  border-top-color: #b1dfbb; }

.alert-success .alert-link {
  color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-info hr {
  border-top-color: #abdde5; }

.alert-info .alert-link {
  color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-warning hr {
  border-top-color: #ffe8a1; }

.alert-warning .alert-link {
  color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-danger hr {
  border-top-color: #f1b0b7; }

.alert-danger .alert-link {
  color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light hr {
  border-top-color: #ececf6; }

.alert-light .alert-link {
  color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.alert-dark hr {
  border-top-color: #b9bbbe; }

.alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

.close:not(:disabled):not(.disabled) {
  cursor: pointer; }

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%); }

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: ""; }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: 0.5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }

.modal-footer > :not(:first-child) {
  margin-left: .25rem; }

.modal-footer > :not(:last-child) {
  margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: 0.9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem; }

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5); }

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators .active {
  background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td,
  .table th {
    background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*# sourceMappingURL=bootstrap.css.map */
/* main var */
/* weight */
/* border */
/* titles */
/* contents block bg */
/* btn */
/* rating */
/* read more btn*/
/* tabs */
/* tabs sort panel*/
/* Promocje */
/* header */
/* header menu */
/* front page */
/* link */
/* slots */
/* Nowe kasyna */
/* footer */
/* copy-rights */
/* casino list */
/* more casino btn */
/* news */
/* breadcrumbs */
/* 404 error */
/* drop menu */
/* slot */
/* comments */
/* sorting */
/* paging */
/* sidebar */
.news .one-new, .menu-link-go-to ul {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  flex-flow: row wrap; }

/* header top bg */
/*fade slider */
/* read more */
/* page */
/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-1-Pay {
  background-image: url(sprite.png);
  background-position: -67px -123px;
  width: 52px;
  height: 39px; }

.icon-18-dmca-gpwa-icons {
  background-image: url(sprite.png);
  background-position: 0px 0px;
  width: 185px;
  height: 21px; }

.icon-21GNET {
  background-image: url(sprite.png);
  background-position: -576px -1311px;
  width: 52px;
  height: 39px; }

.icon-440-Cash {
  background-image: url(sprite.png);
  background-position: -504px -1370px;
  width: 52px;
  height: 39px; }

.icon-4B {
  background-image: url(sprite.png);
  background-position: -1429px -885px;
  width: 52px;
  height: 39px; }

.icon-7Eleven {
  background-image: url(sprite.png);
  background-position: -205px 0px;
  width: 52px;
  height: 39px; }

.icon-900-Pay-by-Neteller {
  background-image: url(sprite.png);
  background-position: -205px -59px;
  width: 52px;
  height: 39px; }

.icon-900Pay-by-Navaho {
  background-image: url(sprite.png);
  background-position: -205px -118px;
  width: 52px;
  height: 39px; }

.icon-ACH {
  background-image: url(sprite.png);
  background-position: -144px -190px;
  width: 52px;
  height: 39px; }

.icon-AGMO {
  background-image: url(sprite.png);
  background-position: -277px -177px;
  width: 52px;
  height: 39px; }

.icon-AKbank {
  background-image: url(sprite.png);
  background-position: -72px -249px;
  width: 52px;
  height: 39px; }

.icon-AMRO {
  background-image: url(sprite.png);
  background-position: -421px -118px;
  width: 52px;
  height: 39px; }

.icon-ATM-Online {
  background-image: url(sprite.png);
  background-position: -288px -426px;
  width: 52px;
  height: 39px; }

.icon-ATM {
  background-image: url(sprite.png);
  background-position: -360px -426px;
  width: 52px;
  height: 39px; }

.icon-Abaqoos {
  background-image: url(sprite.png);
  background-position: 0px -190px;
  width: 52px;
  height: 39px; }

.icon-Account-to-Account-transfer {
  background-image: url(sprite.png);
  background-position: -72px -190px;
  width: 52px;
  height: 39px; }

.icon-Affiliate-Program {
  background-image: url(sprite.png);
  background-position: -1080px -1075px;
  width: 22px;
  height: 22px; }

.icon-Affiliate-programs {
  background-image: url(sprite.png);
  background-position: -1501px -294px;
  width: 22px;
  height: 22px; }

.icon-Agencies-IziPlay {
  background-image: url(sprite.png);
  background-position: -277px -118px;
  width: 52px;
  height: 39px; }

.icon-AirPay {
  background-image: url(sprite.png);
  background-position: 0px -249px;
  width: 52px;
  height: 39px; }

.icon-Aktia-Bank {
  background-image: url(sprite.png);
  background-position: -144px -249px;
  width: 52px;
  height: 39px; }

.icon-Aktia {
  background-image: url(sprite.png);
  background-position: -216px -249px;
  width: 52px;
  height: 39px; }

.icon-Aland-Islands {
  background-image: url(sprite.png);
  background-position: -1480px -1528px;
  width: 20px;
  height: 20px; }

.icon-Albanian-lekë {
  background-image: url(sprite.png);
  background-position: -840px -1568px;
  width: 20px;
  height: 20px; }

.icon-Albanian {
  background-image: url(sprite.png);
  background-position: -480px -1608px;
  width: 20px;
  height: 20px; }

.icon-Alfa-Bank {
  background-image: url(sprite.png);
  background-position: -349px -177px;
  width: 52px;
  height: 39px; }

.icon-Alfa-Click {
  background-image: url(sprite.png);
  background-position: -349px -236px;
  width: 52px;
  height: 39px; }

.icon-Algerian-dinars {
  background-image: url(sprite.png);
  background-position: -1663px -640px;
  width: 20px;
  height: 20px; }

.icon-AloGateway {
  background-image: url(sprite.png);
  background-position: -144px -308px;
  width: 52px;
  height: 39px; }

.icon-Amazon-gift-card {
  background-image: url(sprite.png);
  background-position: -216px -308px;
  width: 52px;
  height: 39px; }

.icon-American-Express {
  background-image: url(sprite.png);
  background-position: -421px 0px;
  width: 52px;
  height: 39px; }

.icon-Amigotechs {
  background-image: url(sprite.png);
  background-position: -421px -59px;
  width: 52px;
  height: 39px; }

.icon-Angolan-kwanzas {
  background-image: url(sprite.png);
  background-position: -1663px -480px;
  width: 20px;
  height: 20px; }

.icon-Arabic {
  background-image: url(sprite.png);
  background-position: -1663px -360px;
  width: 20px;
  height: 20px; }

.icon-Arena-Wallet {
  background-image: url(sprite.png);
  background-position: -72px -367px;
  width: 52px;
  height: 39px; }

.icon-Argentine pesos {
  background-image: url(sprite.png);
  background-position: -1663px -320px;
  width: 20px;
  height: 20px; }

.icon-Argentine-pesos {
  background-image: url(sprite.png);
  background-position: -1663px -280px;
  width: 20px;
  height: 20px; }

.icon-Armenian-drams {
  background-image: url(sprite.png);
  background-position: -1663px -240px;
  width: 20px;
  height: 20px; }

.icon-Asia-Local-Bank {
  background-image: url(sprite.png);
  background-position: 0px -426px;
  width: 52px;
  height: 39px; }

.icon-Asipay8 {
  background-image: url(sprite.png);
  background-position: -72px -426px;
  width: 52px;
  height: 39px; }

.icon-AstroPay-Card {
  background-image: url(sprite.png);
  background-position: -144px -426px;
  width: 52px;
  height: 39px; }

.icon-AstroPay-Direct {
  background-image: url(sprite.png);
  background-position: -216px -426px;
  width: 52px;
  height: 39px; }

.icon-Australian dollars {
  background-image: url(sprite.png);
  background-position: -1663px -40px;
  width: 20px;
  height: 20px; }

.icon-Australian-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -493px -118px;
  width: 52px;
  height: 39px; }

.icon-Australian-dollars {
  background-image: url(sprite.png);
  background-position: -1663px 0px;
  width: 20px;
  height: 20px; }

.icon-Austrian-German {
  background-image: url(sprite.png);
  background-position: -1600px -1608px;
  width: 20px;
  height: 20px; }

.icon-Autoplay-Option {
  background-image: url(sprite.png);
  background-position: -1501px 0px;
  width: 22px;
  height: 22px; }

.icon-Azerbaijani-manats {
  background-image: url(sprite.png);
  background-position: -1480px -1608px;
  width: 20px;
  height: 20px; }

.icon-BACS {
  background-image: url(sprite.png);
  background-position: -144px -485px;
  width: 52px;
  height: 39px; }

.icon-BLESK-peněženka {
  background-image: url(sprite.png);
  background-position: -637px -413px;
  width: 52px;
  height: 39px; }

.icon-BPay {
  background-image: url(sprite.png);
  background-position: -709px -177px;
  width: 52px;
  height: 39px; }

.icon-BT-bill {
  background-image: url(sprite.png);
  background-position: -144px -662px;
  width: 52px;
  height: 39px; }

.icon-Baccarat {
  background-image: url(sprite.png);
  background-position: -432px -426px;
  width: 28px;
  height: 28px; }

.icon-Bahraini-dinars {
  background-image: url(sprite.png);
  background-position: -1120px -1608px;
  width: 20px;
  height: 20px; }

.icon-Baloto {
  background-image: url(sprite.png);
  background-position: -288px -485px;
  width: 52px;
  height: 39px; }

.icon-Bancontact-Mister-Cash {
  background-image: url(sprite.png);
  background-position: -432px -485px;
  width: 52px;
  height: 39px; }

.icon-Bangladeshi-takas {
  background-image: url(sprite.png);
  background-position: -880px -1608px;
  width: 20px;
  height: 20px; }

.icon-Bank-Dependent {
  background-image: url(sprite.png);
  background-position: -565px -59px;
  width: 52px;
  height: 39px; }

.icon-Bank-Draft {
  background-image: url(sprite.png);
  background-position: -565px -177px;
  width: 52px;
  height: 39px; }

.icon-Bank-Pekao {
  background-image: url(sprite.png);
  background-position: -565px -236px;
  width: 52px;
  height: 39px; }

.icon-Bank-Transfer-Express {
  background-image: url(sprite.png);
  background-position: -565px -295px;
  width: 52px;
  height: 39px; }

.icon-Bank-Wire-Transfer {
  background-image: url(sprite.png);
  background-position: -565px -413px;
  width: 52px;
  height: 39px; }

.icon-Bank-transfer {
  background-image: url(sprite.png);
  background-position: -565px -354px;
  width: 52px;
  height: 39px; }

.icon-BankLink {
  background-image: url(sprite.png);
  background-position: -565px -472px;
  width: 52px;
  height: 39px; }

.icon-Barcode-Santander {
  background-image: url(sprite.png);
  background-position: 0px -544px;
  width: 52px;
  height: 39px; }

.icon-Beeline {
  background-image: url(sprite.png);
  background-position: -216px -544px;
  width: 52px;
  height: 39px; }

.icon-Belarusian-rubles {
  background-image: url(sprite.png);
  background-position: -760px -1608px;
  width: 20px;
  height: 20px; }

.icon-Belarusian {
  background-image: url(sprite.png);
  background-position: -720px -1608px;
  width: 20px;
  height: 20px; }

.icon-Betfred-Shops {
  background-image: url(sprite.png);
  background-position: -432px -544px;
  width: 52px;
  height: 39px; }

.icon-Betting-Voucher {
  background-image: url(sprite.png);
  background-position: -504px -544px;
  width: 52px;
  height: 39px; }

.icon-Billingpartner {
  background-image: url(sprite.png);
  background-position: -637px -236px;
  width: 52px;
  height: 39px; }

.icon-Bitcoin {
  background-image: url(sprite.png);
  background-position: -637px -295px;
  width: 52px;
  height: 39px; }

.icon-Blackjack {
  background-image: url(sprite.png);
  background-position: -360px -308px;
  width: 28px;
  height: 28px; }

.icon-Blueprint-Gaming {
  background-image: url(sprite.png);
  background-position: -637px -472px;
  width: 52px;
  height: 39px; }

.icon-Boku {
  background-image: url(sprite.png);
  background-position: -72px -603px;
  width: 52px;
  height: 39px; }

.icon-Boleto-by-Neteller {
  background-image: url(sprite.png);
  background-position: -144px -603px;
  width: 52px;
  height: 39px; }

.icon-Boleto {
  background-image: url(sprite.png);
  background-position: -216px -603px;
  width: 52px;
  height: 39px; }

.icon-Bolivian-bolivianos {
  background-image: url(sprite.png);
  background-position: -1623px -1520px;
  width: 20px;
  height: 20px; }

.icon-Bonifico {
  background-image: url(sprite.png);
  background-position: -360px -603px;
  width: 52px;
  height: 39px; }

.icon-Bonus-Game {
  background-image: url(sprite.png);
  background-position: -1501px -1050px;
  width: 22px;
  height: 22px; }

.icon-Bonus-Value {
  background-image: url(sprite.png);
  background-position: -1501px -1008px;
  width: 22px;
  height: 22px; }

.icon-Book-to-book {
  background-image: url(sprite.png);
  background-position: -709px 0px;
  width: 52px;
  height: 39px; }

.icon-Bosnia-Herzegovina-convertible-marks {
  background-image: url(sprite.png);
  background-position: -1623px -1080px;
  width: 20px;
  height: 20px; }

.icon-Botswanan-pulas {
  background-image: url(sprite.png);
  background-position: -1623px -1040px;
  width: 20px;
  height: 20px; }

.icon-Bradesco {
  background-image: url(sprite.png);
  background-position: -709px -236px;
  width: 52px;
  height: 39px; }

.icon-Brazil-Visa-credit-card {
  background-image: url(sprite.png);
  background-position: -709px -354px;
  width: 52px;
  height: 39px; }

.icon-Brazil-credit-card {
  background-image: url(sprite.png);
  background-position: -709px -295px;
  width: 52px;
  height: 39px; }

.icon-Brazilian-reals {
  background-image: url(sprite.png);
  background-position: -1623px -1000px;
  width: 20px;
  height: 20px; }

.icon-British pounds sterling {
  background-image: url(sprite.png);
  background-position: -1623px -960px;
  width: 20px;
  height: 20px; }

.icon-British-pounds-sterling {
  background-image: url(sprite.png);
  background-position: -1623px -920px;
  width: 20px;
  height: 20px; }

.icon-Brunei dollars {
  background-image: url(sprite.png);
  background-position: -1623px -760px;
  width: 20px;
  height: 20px; }

.icon-Brunei-dollars {
  background-image: url(sprite.png);
  background-position: -1623px -720px;
  width: 20px;
  height: 20px; }

.icon-Bulgarian-leva {
  background-image: url(sprite.png);
  background-position: -1623px -480px;
  width: 20px;
  height: 20px; }

.icon-Bulgarian {
  background-image: url(sprite.png);
  background-position: -1623px -440px;
  width: 20px;
  height: 20px; }

.icon-Burundian-francs {
  background-image: url(sprite.png);
  background-position: -1623px -400px;
  width: 20px;
  height: 20px; }

.icon-C24 {
  background-image: url(sprite.png);
  background-position: 0px -721px;
  width: 52px;
  height: 39px; }

.icon-CADEFT {
  background-image: url(sprite.png);
  background-position: -216px -721px;
  width: 52px;
  height: 39px; }

.icon-CDS {
  background-image: url(sprite.png);
  background-position: -144px -780px;
  width: 52px;
  height: 39px; }

.icon-CEP-Bank {
  background-image: url(sprite.png);
  background-position: -288px -780px;
  width: 52px;
  height: 39px; }

.icon-CFT-Card-Funds-Transfer {
  background-image: url(sprite.png);
  background-position: -360px -780px;
  width: 52px;
  height: 39px; }

.icon-CIMB-Bank {
  background-image: url(sprite.png);
  background-position: -853px -354px;
  width: 52px;
  height: 39px; }

.icon-CLICK2PAY {
  background-image: url(sprite.png);
  background-position: -853px -649px;
  width: 52px;
  height: 39px; }

.icon-CWC-Gaming {
  background-image: url(sprite.png);
  background-position: -925px -708px;
  width: 52px;
  height: 39px; }

.icon-Canadian dollars {
  background-image: url(sprite.png);
  background-position: -1623px -160px;
  width: 20px;
  height: 20px; }

.icon-Canadian-dollars {
  background-image: url(sprite.png);
  background-position: -1623px -120px;
  width: 20px;
  height: 20px; }

.icon-Canadian {
  background-image: url(sprite.png);
  background-position: -1623px -40px;
  width: 20px;
  height: 20px; }

.icon-Cardpay {
  background-image: url(sprite.png);
  background-position: -504px -721px;
  width: 52px;
  height: 39px; }

.icon-CartaSi-by-Skrill {
  background-image: url(sprite.png);
  background-position: -576px -721px;
  width: 52px;
  height: 39px; }

.icon-CartaSi {
  background-image: url(sprite.png);
  background-position: -648px -721px;
  width: 52px;
  height: 39px; }

.icon-Carte-Bleue {
  background-image: url(sprite.png);
  background-position: -781px 0px;
  width: 52px;
  height: 39px; }

.icon-Cash-Transfer {
  background-image: url(sprite.png);
  background-position: -781px -59px;
  width: 52px;
  height: 39px; }

.icon-CashU {
  background-image: url(sprite.png);
  background-position: -781px -295px;
  width: 52px;
  height: 39px; }

.icon-Cashable {
  background-image: url(sprite.png);
  background-position: -1501px -966px;
  width: 22px;
  height: 22px; }

.icon-Cashiers-Check {
  background-image: url(sprite.png);
  background-position: -781px -177px;
  width: 52px;
  height: 39px; }

.icon-Cashlib {
  background-image: url(sprite.png);
  background-position: -781px -236px;
  width: 52px;
  height: 39px; }

.icon-Cashup {
  background-image: url(sprite.png);
  background-position: -781px -354px;
  width: 52px;
  height: 39px; }

.icon-Casino-Owner {
  background-image: url(sprite.png);
  background-position: -1501px -924px;
  width: 22px;
  height: 22px; }

.icon-Casino-type {
  background-image: url(sprite.png);
  background-position: -1501px -882px;
  width: 22px;
  height: 22px; }

.icon-Casino {
  background-image: url(sprite.png);
  background-position: -1501px -840px;
  width: 22px;
  height: 22px; }

.icon-Catalan {
  background-image: url(sprite.png);
  background-position: -1080px -1568px;
  width: 20px;
  height: 20px; }

.icon-Central-Coin {
  background-image: url(sprite.png);
  background-position: -216px -780px;
  width: 52px;
  height: 39px; }

.icon-Check {
  background-image: url(sprite.png);
  background-position: -432px -780px;
  width: 52px;
  height: 39px; }

.icon-Checks-by-Mail {
  background-image: url(sprite.png);
  background-position: -504px -780px;
  width: 52px;
  height: 39px; }

.icon-Cheque {
  background-image: url(sprite.png);
  background-position: -576px -780px;
  width: 52px;
  height: 39px; }

.icon-Chilean pesos {
  background-image: url(sprite.png);
  background-position: -800px -1568px;
  width: 20px;
  height: 20px; }

.icon-Chilean-pesos {
  background-image: url(sprite.png);
  background-position: -760px -1568px;
  width: 20px;
  height: 20px; }

.icon-China-online-payment {
  background-image: url(sprite.png);
  background-position: -853px -59px;
  width: 52px;
  height: 39px; }

.icon-Chinese-Renminbi {
  background-image: url(sprite.png);
  background-position: -720px -1568px;
  width: 20px;
  height: 20px; }

.icon-Chinese-yuan {
  background-image: url(sprite.png);
  background-position: -640px -1568px;
  width: 20px;
  height: 20px; }

.icon-Chinese {
  background-image: url(sprite.png);
  background-position: -600px -1568px;
  width: 20px;
  height: 20px; }

.icon-Citadel-Commerce {
  background-image: url(sprite.png);
  background-position: -853px -413px;
  width: 52px;
  height: 39px; }

.icon-Citadel-Direct {
  background-image: url(sprite.png);
  background-position: -853px -472px;
  width: 52px;
  height: 39px; }

.icon-Citadel-Internet-Bank {
  background-image: url(sprite.png);
  background-position: -853px -531px;
  width: 52px;
  height: 39px; }

.icon-Citadel {
  background-image: url(sprite.png);
  background-position: -853px -590px;
  width: 52px;
  height: 39px; }

.icon-ClickDirectPay {
  background-image: url(sprite.png);
  background-position: -853px -767px;
  width: 52px;
  height: 39px; }

.icon-ClickandBuy {
  background-image: url(sprite.png);
  background-position: -853px -708px;
  width: 52px;
  height: 39px; }

.icon-Colombian pesos {
  background-image: url(sprite.png);
  background-position: -480px -1568px;
  width: 20px;
  height: 20px; }

.icon-Colombian-pesos {
  background-image: url(sprite.png);
  background-position: -440px -1568px;
  width: 20px;
  height: 20px; }

.icon-ComGate {
  background-image: url(sprite.png);
  background-position: -360px -839px;
  width: 52px;
  height: 39px; }

.icon-Comepay {
  background-image: url(sprite.png);
  background-position: -288px -839px;
  width: 52px;
  height: 39px; }

.icon-Congolese-francs {
  background-image: url(sprite.png);
  background-position: -240px -1568px;
  width: 20px;
  height: 20px; }

.icon-Contact {
  background-image: url(sprite.png);
  background-position: -504px -839px;
  width: 52px;
  height: 39px; }

.icon-Costa-Rica {
  background-image: url(sprite.png);
  background-position: -160px -1568px;
  width: 20px;
  height: 20px; }

.icon-Costa-Rican-colóns {
  background-image: url(sprite.png);
  background-position: -80px -1568px;
  width: 20px;
  height: 20px; }

.icon-Coupon {
  background-image: url(sprite.png);
  background-position: -792px -839px;
  width: 52px;
  height: 39px; }

.icon-Courier-Check {
  background-image: url(sprite.png);
  background-position: -925px 0px;
  width: 52px;
  height: 39px; }

.icon-Credit-Card {
  background-image: url(sprite.png);
  background-position: -925px -177px;
  width: 52px;
  height: 39px; }

.icon-Credit-Cards {
  background-image: url(sprite.png);
  background-position: -925px -236px;
  width: 52px;
  height: 39px; }

.icon-Croatian-kunas {
  background-image: url(sprite.png);
  background-position: -1583px -1240px;
  width: 20px;
  height: 20px; }

.icon-Croatian {
  background-image: url(sprite.png);
  background-position: -1583px -1200px;
  width: 20px;
  height: 20px; }

.icon-Cubits {
  background-image: url(sprite.png);
  background-position: -925px -472px;
  width: 52px;
  height: 39px; }

.icon-Cuenta-Digital {
  background-image: url(sprite.png);
  background-position: -925px -531px;
  width: 52px;
  height: 39px; }

.icon-Currencies {
  background-image: url(sprite.png);
  background-position: -1501px -546px;
  width: 22px;
  height: 22px; }

.icon-Custom-Software {
  background-image: url(sprite.png);
  background-position: -925px -649px;
  width: 52px;
  height: 39px; }

.icon-Czech-Republic-korunas {
  background-image: url(sprite.png);
  background-position: -1583px -880px;
  width: 20px;
  height: 20px; }

.icon-Czech {
  background-image: url(sprite.png);
  background-position: -1583px -840px;
  width: 20px;
  height: 20px; }

.icon-Danish {
  background-image: url(sprite.png);
  background-position: -1583px -600px;
  width: 20px;
  height: 20px; }

.icon-Dankort {
  background-image: url(sprite.png);
  background-position: -216px -898px;
  width: 52px;
  height: 39px; }

.icon-Danske-Bank {
  background-image: url(sprite.png);
  background-position: -288px -898px;
  width: 52px;
  height: 39px; }

.icon-Dash {
  background-image: url(sprite.png);
  background-position: -432px -898px;
  width: 52px;
  height: 39px; }

.icon-Debit-Card {
  background-image: url(sprite.png);
  background-position: -576px -898px;
  width: 52px;
  height: 39px; }

.icon-Delta {
  background-image: url(sprite.png);
  background-position: -648px -898px;
  width: 52px;
  height: 39px; }

.icon-Denmark-kroner {
  background-image: url(sprite.png);
  background-position: -1583px -480px;
  width: 20px;
  height: 20px; }

.icon-Deposit-methods {
  background-image: url(sprite.png);
  background-position: -1501px -462px;
  width: 22px;
  height: 22px; }

.icon-DineroMail {
  background-image: url(sprite.png);
  background-position: -864px -898px;
  width: 52px;
  height: 39px; }

.icon-Diners-Club-International-by-Skrill {
  background-image: url(sprite.png);
  background-position: -997px 0px;
  width: 52px;
  height: 39px; }

.icon-Diners-Club-International {
  background-image: url(sprite.png);
  background-position: -997px -59px;
  width: 52px;
  height: 39px; }

.icon-Diners {
  background-image: url(sprite.png);
  background-position: -997px -118px;
  width: 52px;
  height: 39px; }

.icon-Direct-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -997px -236px;
  width: 52px;
  height: 39px; }

.icon-Direct-Cash {
  background-image: url(sprite.png);
  background-position: -997px -295px;
  width: 52px;
  height: 39px; }

.icon-Direct-Money {
  background-image: url(sprite.png);
  background-position: -997px -413px;
  width: 52px;
  height: 39px; }

.icon-Direct-bank-transfer-by-Skrill {
  background-image: url(sprite.png);
  background-position: -997px -177px;
  width: 52px;
  height: 39px; }

.icon-Direct-ebanking {
  background-image: url(sprite.png);
  background-position: -997px -354px;
  width: 52px;
  height: 39px; }

.icon-Discover {
  background-image: url(sprite.png);
  background-position: -997px -472px;
  width: 52px;
  height: 39px; }

.icon-Dogecoin {
  background-image: url(sprite.png);
  background-position: -997px -708px;
  width: 52px;
  height: 39px; }

.icon-Download {
  background-image: url(sprite.png);
  background-position: -792px -780px;
  width: 22px;
  height: 24px; }

.icon-Dragonfish-Random-Logic {
  background-image: url(sprite.png);
  background-position: 0px -957px;
  width: 52px;
  height: 39px; }

.icon-Dutch {
  background-image: url(sprite.png);
  background-position: -1120px -1528px;
  width: 20px;
  height: 20px; }

.icon-E-Pro-e-Vouchers {
  background-image: url(sprite.png);
  background-position: -288px -957px;
  width: 52px;
  height: 39px; }

.icon-EC-ELV {
  background-image: url(sprite.png);
  background-position: -504px -957px;
  width: 52px;
  height: 39px; }

.icon-ECO-Card {
  background-image: url(sprite.png);
  background-position: -792px -957px;
  width: 52px;
  height: 39px; }

.icon-ECOBANk {
  background-image: url(sprite.png);
  background-position: -864px -957px;
  width: 52px;
  height: 39px; }

.icon-ECash-Direct {
  background-image: url(sprite.png);
  background-position: -648px -957px;
  width: 52px;
  height: 39px; }

.icon-ELV-Elektronisches-Lastschriftverfahren {
  background-image: url(sprite.png);
  background-position: -1069px -590px;
  width: 52px;
  height: 39px; }

.icon-EMO---Electronic-Money-Order {
  background-image: url(sprite.png);
  background-position: -1069px -649px;
  width: 52px;
  height: 39px; }

.icon-EMO-Electronic-Money-Order {
  background-image: url(sprite.png);
  background-position: -1069px -708px;
  width: 52px;
  height: 39px; }

.icon-ENets-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1069px -885px;
  width: 52px;
  height: 39px; }

.icon-EPRO {
  background-image: url(sprite.png);
  background-position: -576px -1016px;
  width: 52px;
  height: 39px; }

.icon-EPS {
  background-image: url(sprite.png);
  background-position: -648px -1016px;
  width: 52px;
  height: 39px; }

.icon-ETH {
  background-image: url(sprite.png);
  background-position: 0px -1075px;
  width: 52px;
  height: 39px; }

.icon-EZ-Voucher {
  background-image: url(sprite.png);
  background-position: -1141px -177px;
  width: 52px;
  height: 39px; }

.icon-EZIPay {
  background-image: url(sprite.png);
  background-position: -1141px -236px;
  width: 52px;
  height: 39px; }

.icon-Easy-EFT {
  background-image: url(sprite.png);
  background-position: -360px -957px;
  width: 52px;
  height: 39px; }

.icon-EasyPay {
  background-image: url(sprite.png);
  background-position: -432px -957px;
  width: 52px;
  height: 39px; }

.icon-EcoPay {
  background-image: url(sprite.png);
  background-position: -936px -957px;
  width: 52px;
  height: 39px; }

.icon-EcoPayz {
  background-image: url(sprite.png);
  background-position: -1069px 0px;
  width: 52px;
  height: 39px; }

.icon-Egyptian-pounds {
  background-image: url(sprite.png);
  background-position: -960px -1528px;
  width: 20px;
  height: 20px; }

.icon-Elba {
  background-image: url(sprite.png);
  background-position: -1069px -354px;
  width: 52px;
  height: 39px; }

.icon-Electracade {
  background-image: url(sprite.png);
  background-position: -1069px -413px;
  width: 52px;
  height: 39px; }

.icon-Electron {
  background-image: url(sprite.png);
  background-position: -1069px -472px;
  width: 52px;
  height: 39px; }

.icon-Electronic-Transfer {
  background-image: url(sprite.png);
  background-position: -1069px -531px;
  width: 52px;
  height: 39px; }

.icon-English {
  background-image: url(sprite.png);
  background-position: -680px -1528px;
  width: 20px;
  height: 20px; }

.icon-EnterCash {
  background-image: url(sprite.png);
  background-position: -72px -1016px;
  width: 52px;
  height: 39px; }

.icon-Entropay {
  background-image: url(sprite.png);
  background-position: -144px -1016px;
  width: 52px;
  height: 39px; }

.icon-Envoy {
  background-image: url(sprite.png);
  background-position: -216px -1016px;
  width: 52px;
  height: 39px; }

.icon-Estonian-Kroon {
  background-image: url(sprite.png);
  background-position: -560px -1528px;
  width: 20px;
  height: 20px; }

.icon-Estonian {
  background-image: url(sprite.png);
  background-position: -520px -1528px;
  width: 20px;
  height: 20px; }

.icon-Ethiopian-birrs {
  background-image: url(sprite.png);
  background-position: -360px -1528px;
  width: 20px;
  height: 20px; }

.icon-Euro-Card {
  background-image: url(sprite.png);
  background-position: -216px -1075px;
  width: 52px;
  height: 39px; }

.icon-Euro6000 {
  background-image: url(sprite.png);
  background-position: -288px -1075px;
  width: 52px;
  height: 39px; }

.icon-Euros {
  background-image: url(sprite.png);
  background-position: -280px -1528px;
  width: 20px;
  height: 20px; }

.icon-Euroset {
  background-image: url(sprite.png);
  background-position: -432px -1075px;
  width: 52px;
  height: 39px; }

.icon-Eurp-Standard-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -504px -1075px;
  width: 52px;
  height: 39px; }

.icon-Euteller {
  background-image: url(sprite.png);
  background-position: -576px -1075px;
  width: 52px;
  height: 39px; }

.icon-Evroset {
  background-image: url(sprite.png);
  background-position: -648px -1075px;
  width: 52px;
  height: 39px; }

.icon-Exclusive {
  background-image: url(sprite.png);
  background-position: -1501px -42px;
  width: 22px;
  height: 22px; }

.icon-Express-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -936px -1075px;
  width: 52px;
  height: 39px; }

.icon-Express-Merchants {
  background-image: url(sprite.png);
  background-position: -1008px -1075px;
  width: 52px;
  height: 39px; }

.icon-Express-Wallet {
  background-image: url(sprite.png);
  background-position: -1141px 0px;
  width: 52px;
  height: 39px; }

.icon-Eyecon {
  background-image: url(sprite.png);
  background-position: -1141px -118px;
  width: 52px;
  height: 39px; }

.icon-Ezugi {
  background-image: url(sprite.png);
  background-position: -1141px -295px;
  width: 52px;
  height: 39px; }

.icon-Farsi {
  background-image: url(sprite.png);
  background-position: -160px -1528px;
  width: 20px;
  height: 20px; }

.icon-Fast-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -1141px -413px;
  width: 52px;
  height: 39px; }

.icon-Fast-Pay {
  background-image: url(sprite.png);
  background-position: -1141px -472px;
  width: 52px;
  height: 39px; }

.icon-Fast-Wire {
  background-image: url(sprite.png);
  background-position: -1141px -531px;
  width: 52px;
  height: 39px; }

.icon-Fedex-Checks {
  background-image: url(sprite.png);
  background-position: -1141px -590px;
  width: 52px;
  height: 39px; }

.icon-Finnish-Online-Banking {
  background-image: url(sprite.png);
  background-position: -1141px -767px;
  width: 52px;
  height: 39px; }

.icon-Finnish-ebank {
  background-image: url(sprite.png);
  background-position: -1141px -708px;
  width: 52px;
  height: 39px; }

.icon-Finnish {
  background-image: url(sprite.png);
  background-position: -40px -1528px;
  width: 20px;
  height: 20px; }

.icon-Fio-Banka {
  background-image: url(sprite.png);
  background-position: -1141px -885px;
  width: 52px;
  height: 39px; }

.icon-Firepay {
  background-image: url(sprite.png);
  background-position: -1141px -944px;
  width: 52px;
  height: 39px; }

.icon-Flexepin {
  background-image: url(sprite.png);
  background-position: -1141px -1003px;
  width: 52px;
  height: 39px; }

.icon-Free-Spins {
  background-image: url(sprite.png);
  background-position: -1368px -1370px;
  width: 22px;
  height: 22px; }

.icon-French {
  background-image: url(sprite.png);
  background-position: -1543px -1360px;
  width: 20px;
  height: 20px; }

.icon-FundSend {
  background-image: url(sprite.png);
  background-position: -216px -1134px;
  width: 52px;
  height: 39px; }

.icon-GCNL-icon4 {
  background-image: url(sprite.png);
  background-position: -139px -123px;
  width: 43px;
  height: 27px; }

.icon-GCNL-license {
  background-image: url(sprite.png);
  background-position: -576px -544px;
  width: 29px;
  height: 26px; }

.icon-GCNL-mobile {
  background-image: url(sprite.png);
  background-position: -162px -41px;
  width: 19px;
  height: 29px; }

.icon-GCNL-star {
  background-image: url(sprite.png);
  background-position: -216px -190px;
  width: 30px;
  height: 30px; }

.icon-GTECH-G2 {
  background-image: url(sprite.png);
  background-position: -1213px -708px;
  width: 52px;
  height: 39px; }

.icon-Game-RTP {
  background-image: url(sprite.png);
  background-position: -1224px -1193px;
  width: 22px;
  height: 22px; }

.icon-Gaming-Right-Media {
  background-image: url(sprite.png);
  background-position: -504px -1134px;
  width: 52px;
  height: 39px; }

.icon-Garanti {
  background-image: url(sprite.png);
  background-position: -576px -1134px;
  width: 52px;
  height: 39px; }

.icon-Georgian-laris {
  background-image: url(sprite.png);
  background-position: -1543px -720px;
  width: 20px;
  height: 20px; }

.icon-Georgian {
  background-image: url(sprite.png);
  background-position: -1543px -640px;
  width: 20px;
  height: 20px; }

.icon-German {
  background-image: url(sprite.png);
  background-position: -1543px -600px;
  width: 20px;
  height: 20px; }

.icon-Ghanaian-cedis {
  background-image: url(sprite.png);
  background-position: -1543px -560px;
  width: 20px;
  height: 20px; }

.icon-GiroPay-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1213px -236px;
  width: 52px;
  height: 39px; }

.icon-GiroPay {
  background-image: url(sprite.png);
  background-position: -1213px -295px;
  width: 52px;
  height: 39px; }

.icon-Global-EcoCard {
  background-image: url(sprite.png);
  background-position: -1213px -354px;
  width: 52px;
  height: 39px; }

.icon-Gluepay {
  background-image: url(sprite.png);
  background-position: -1213px -413px;
  width: 52px;
  height: 39px; }

.icon-GoCash88 {
  background-image: url(sprite.png);
  background-position: -1213px -472px;
  width: 52px;
  height: 39px; }

.icon-Golden-Palace-Gaming-Hall {
  background-image: url(sprite.png);
  background-position: -1213px -531px;
  width: 52px;
  height: 39px; }

.icon-Grand-Virtual {
  background-image: url(sprite.png);
  background-position: -1213px -590px;
  width: 52px;
  height: 39px; }

.icon-Greek {
  background-image: url(sprite.png);
  background-position: -1543px -480px;
  width: 20px;
  height: 20px; }

.icon-HSBC {
  background-image: url(sprite.png);
  background-position: -576px -1193px;
  width: 52px;
  height: 39px; }

.icon-Hal-Cash {
  background-image: url(sprite.png);
  background-position: -1213px -767px;
  width: 52px;
  height: 39px; }

.icon-Hall-Cash {
  background-image: url(sprite.png);
  background-position: -1213px -826px;
  width: 52px;
  height: 39px; }

.icon-Handelsbanken {
  background-image: url(sprite.png);
  background-position: -1213px -885px;
  width: 52px;
  height: 39px; }

.icon-Hebrew {
  background-image: url(sprite.png);
  background-position: -1543px -400px;
  width: 20px;
  height: 20px; }

.icon-Help2Pay {
  background-image: url(sprite.png);
  background-position: -1213px -1062px;
  width: 52px;
  height: 39px; }

.icon-Hendelsbanken {
  background-image: url(sprite.png);
  background-position: -1213px -1121px;
  width: 52px;
  height: 39px; }

.icon-High5Games {
  background-image: url(sprite.png);
  background-position: -72px -1193px;
  width: 52px;
  height: 39px; }

.icon-Hindi {
  background-image: url(sprite.png);
  background-position: -1543px -320px;
  width: 20px;
  height: 20px; }

.icon-Hipay {
  background-image: url(sprite.png);
  background-position: -216px -1193px;
  width: 52px;
  height: 39px; }

.icon-Hong-Kong-dollars {
  background-image: url(sprite.png);
  background-position: -1543px -240px;
  width: 20px;
  height: 20px; }

.icon-Hungarian-forints {
  background-image: url(sprite.png);
  background-position: -1543px 0px;
  width: 20px;
  height: 20px; }

.icon-Hungarian {
  background-image: url(sprite.png);
  background-position: -1480px -1488px;
  width: 20px;
  height: 20px; }

.icon-ING {
  background-image: url(sprite.png);
  background-position: -1285px -885px;
  width: 52px;
  height: 39px; }

.icon-Icelandic-krónur {
  background-image: url(sprite.png);
  background-position: -1440px -1488px;
  width: 20px;
  height: 20px; }

.icon-Icelandic {
  background-image: url(sprite.png);
  background-position: -1400px -1488px;
  width: 20px;
  height: 20px; }

.icon-Indian-rupees {
  background-image: url(sprite.png);
  background-position: -1000px -1488px;
  width: 20px;
  height: 20px; }

.icon-Indonesian-rupiahs {
  background-image: url(sprite.png);
  background-position: -960px -1488px;
  width: 20px;
  height: 20px; }

.icon-Indonesian {
  background-image: url(sprite.png);
  background-position: -920px -1488px;
  width: 20px;
  height: 20px; }

.icon-Instabet {
  background-image: url(sprite.png);
  background-position: -1285px -1062px;
  width: 52px;
  height: 39px; }

.icon-Instant-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: 0px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-Bank {
  background-image: url(sprite.png);
  background-position: -72px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-Banking {
  background-image: url(sprite.png);
  background-position: -144px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-Debit {
  background-image: url(sprite.png);
  background-position: -216px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-EFT {
  background-image: url(sprite.png);
  background-position: -288px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-ETF {
  background-image: url(sprite.png);
  background-position: -360px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-Online-Check {
  background-image: url(sprite.png);
  background-position: -432px -1252px;
  width: 52px;
  height: 39px; }

.icon-Instant-Play {
  background-image: url(sprite.png);
  background-position: -334px -1429px;
  width: 24px;
  height: 19px; }

.icon-Instant-Wire-Transfer {
  background-image: url(sprite.png);
  background-position: -576px -1252px;
  width: 52px;
  height: 39px; }

.icon-InstantTransfer {
  background-image: url(sprite.png);
  background-position: -648px -1252px;
  width: 52px;
  height: 39px; }

.icon-Inteligo {
  background-image: url(sprite.png);
  background-position: -720px -1252px;
  width: 52px;
  height: 39px; }

.icon-Intercash {
  background-image: url(sprite.png);
  background-position: -792px -1252px;
  width: 52px;
  height: 39px; }

.icon-International-Bank-transfer {
  background-image: url(sprite.png);
  background-position: -936px -1252px;
  width: 52px;
  height: 39px; }

.icon-Internet-Banking {
  background-image: url(sprite.png);
  background-position: -1008px -1252px;
  width: 52px;
  height: 39px; }

.icon-Internet-Cash {
  background-image: url(sprite.png);
  background-position: -1080px -1252px;
  width: 52px;
  height: 39px; }

.icon-Iranian-rials {
  background-image: url(sprite.png);
  background-position: -760px -1488px;
  width: 20px;
  height: 20px; }

.icon-Iraqi-dinars {
  background-image: url(sprite.png);
  background-position: -720px -1488px;
  width: 20px;
  height: 20px; }

.icon-Irish {
  background-image: url(sprite.png);
  background-position: -680px -1488px;
  width: 20px;
  height: 20px; }

.icon-Israel {
  background-image: url(sprite.png);
  background-position: -520px -1488px;
  width: 20px;
  height: 20px; }

.icon-Israeli-new-sheqels {
  background-image: url(sprite.png);
  background-position: -480px -1488px;
  width: 20px;
  height: 20px; }

.icon-Italian {
  background-image: url(sprite.png);
  background-position: -400px -1488px;
  width: 20px;
  height: 20px; }

.icon-JCB {
  background-image: url(sprite.png);
  background-position: -1357px -944px;
  width: 52px;
  height: 39px; }

.icon-Jackpot {
  background-image: url(sprite.png);
  background-position: -1501px -420px;
  width: 22px;
  height: 22px; }

.icon-Jadestone {
  background-image: url(sprite.png);
  background-position: -1357px -708px;
  width: 52px;
  height: 39px; }

.icon-Japanese yen {
  background-image: url(sprite.png);
  background-position: -200px -1488px;
  width: 20px;
  height: 20px; }

.icon-Japanese-yen {
  background-image: url(sprite.png);
  background-position: -160px -1488px;
  width: 20px;
  height: 20px; }

.icon-Japanese {
  background-image: url(sprite.png);
  background-position: -120px -1488px;
  width: 20px;
  height: 20px; }

.icon-Jordanian-dinars {
  background-image: url(sprite.png);
  background-position: -1501px -1340px;
  width: 20px;
  height: 20px; }

.icon-Kalibra-Card {
  background-image: url(sprite.png);
  background-position: -1357px -1239px;
  width: 52px;
  height: 39px; }

.icon-Kalixa {
  background-image: url(sprite.png);
  background-position: 0px -1311px;
  width: 52px;
  height: 39px; }

.icon-Kazakhstani-tenges {
  background-image: url(sprite.png);
  background-position: -1623px -1120px;
  width: 20px;
  height: 20px; }

.icon-Kenyan-shillings {
  background-image: url(sprite.png);
  background-position: -1623px 0px;
  width: 20px;
  height: 20px; }

.icon-Korean {
  background-image: url(sprite.png);
  background-position: -320px -1568px;
  width: 20px;
  height: 20px; }

.icon-Kuwaiti-dinars {
  background-image: url(sprite.png);
  background-position: -1583px -960px;
  width: 20px;
  height: 20px; }

.icon-LAVA-pay {
  background-image: url(sprite.png);
  background-position: -1152px -1311px;
  width: 52px;
  height: 39px; }

.icon-Languages {
  background-image: url(sprite.png);
  background-position: -1501px -252px;
  width: 22px;
  height: 22px; }

.icon-Laser-by-Skrill {
  background-image: url(sprite.png);
  background-position: -792px -1311px;
  width: 52px;
  height: 39px; }

.icon-Laser {
  background-image: url(sprite.png);
  background-position: -864px -1311px;
  width: 52px;
  height: 39px; }

.icon-LatamBilling {
  background-image: url(sprite.png);
  background-position: -936px -1311px;
  width: 52px;
  height: 39px; }

.icon-Latvian-lati {
  background-image: url(sprite.png);
  background-position: -1320px -1528px;
  width: 20px;
  height: 20px; }

.icon-Latvian {
  background-image: url(sprite.png);
  background-position: -1280px -1528px;
  width: 20px;
  height: 20px; }

.icon-Liberty-Reserve {
  background-image: url(sprite.png);
  background-position: -1296px -1311px;
  width: 52px;
  height: 39px; }

.icon-Licences {
  background-image: url(sprite.png);
  background-position: -1501px -126px;
  width: 22px;
  height: 22px; }

.icon-LifeCell {
  background-image: url(sprite.png);
  background-position: -72px -1370px;
  width: 52px;
  height: 39px; }

.icon-LiqPay {
  background-image: url(sprite.png);
  background-position: -216px -1370px;
  width: 52px;
  height: 39px; }

.icon-Litecoin {
  background-image: url(sprite.png);
  background-position: -288px -1370px;
  width: 52px;
  height: 39px; }

.icon-Lithuanian-litai {
  background-image: url(sprite.png);
  background-position: -1160px -1528px;
  width: 20px;
  height: 20px; }

.icon-Lithuanian {
  background-image: url(sprite.png);
  background-position: -400px -1528px;
  width: 20px;
  height: 20px; }

.icon-Lobanet {
  background-image: url(sprite.png);
  background-position: -576px -1370px;
  width: 52px;
  height: 39px; }

.icon-Local-Bank-In {
  background-image: url(sprite.png);
  background-position: -648px -1370px;
  width: 52px;
  height: 39px; }

.icon-Local-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -720px -1370px;
  width: 52px;
  height: 39px; }

.icon-LocalFast-Bank-Transfers {
  background-image: url(sprite.png);
  background-position: -792px -1370px;
  width: 52px;
  height: 39px; }

.icon-Local_Fast-Bank-Transfers {
  background-image: url(sprite.png);
  background-position: -864px -1370px;
  width: 52px;
  height: 39px; }

.icon-Lottomaticard {
  background-image: url(sprite.png);
  background-position: -936px -1370px;
  width: 52px;
  height: 39px; }

.icon-MST-Gift-Card {
  background-image: url(sprite.png);
  background-position: 0px -1370px;
  width: 52px;
  height: 39px; }

.icon-MTC {
  background-image: url(sprite.png);
  background-position: -1224px -1311px;
  width: 52px;
  height: 39px; }

.icon-Macedonian-denari {
  background-image: url(sprite.png);
  background-position: -1543px -1000px;
  width: 20px;
  height: 20px; }

.icon-Maestro-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1429px -118px;
  width: 52px;
  height: 39px; }

.icon-Maestro {
  background-image: url(sprite.png);
  background-position: -1429px -177px;
  width: 52px;
  height: 39px; }

.icon-Malaysian-ringgits {
  background-image: url(sprite.png);
  background-position: -1543px -800px;
  width: 20px;
  height: 20px; }

.icon-Malaysian {
  background-image: url(sprite.png);
  background-position: -1543px -40px;
  width: 20px;
  height: 20px; }

.icon-MasterCard {
  background-image: url(sprite.png);
  background-position: -1429px -472px;
  width: 52px;
  height: 39px; }

.icon-Mastercard-by-Neteller {
  background-image: url(sprite.png);
  background-position: -1429px -354px;
  width: 52px;
  height: 39px; }

.icon-Mastercard-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1429px -413px;
  width: 52px;
  height: 39px; }

.icon-Mauritian-rupees {
  background-image: url(sprite.png);
  background-position: -1360px -1488px;
  width: 20px;
  height: 20px; }

.icon-Max-Coins-Per-Line {
  background-image: url(sprite.png);
  background-position: -1368px -1311px;
  width: 22px;
  height: 22px; }

.icon-Max-Coins-Size {
  background-image: url(sprite.png);
  background-position: -1296px -1252px;
  width: 22px;
  height: 22px; }

.icon-Maximum-Bonus-Amount {
  background-image: url(sprite.png);
  background-position: -1152px -1134px;
  width: 22px;
  height: 22px; }

.icon-Maybank {
  background-image: url(sprite.png);
  background-position: -1429px -767px;
  width: 52px;
  height: 39px; }

.icon-Medialive-Casino {
  background-image: url(sprite.png);
  background-position: -1429px -944px;
  width: 52px;
  height: 39px; }

.icon-Megafon {
  background-image: url(sprite.png);
  background-position: -1429px -1003px;
  width: 52px;
  height: 39px; }

.icon-Merge-Gaming-Network {
  background-image: url(sprite.png);
  background-position: -1429px -1121px;
  width: 52px;
  height: 39px; }

.icon-Mexican pesos {
  background-image: url(sprite.png);
  background-position: -1240px -1488px;
  width: 20px;
  height: 20px; }

.icon-Mexican-pesos {
  background-image: url(sprite.png);
  background-position: -240px -1488px;
  width: 20px;
  height: 20px; }

.icon-Min-Coins-Per-Line {
  background-image: url(sprite.png);
  background-position: -1080px -1016px;
  width: 22px;
  height: 22px; }

.icon-Min-Coins-Size {
  background-image: url(sprite.png);
  background-position: -1501px -1218px;
  width: 22px;
  height: 22px; }

.icon-Min-Deposit {
  background-image: url(sprite.png);
  background-position: -1501px -210px;
  width: 22px;
  height: 22px; }

.icon-MisterCash {
  background-image: url(sprite.png);
  background-position: -72px -1429px;
  width: 52px;
  height: 39px; }

.icon-Mobile-Commerce {
  background-image: url(sprite.png);
  background-position: 0px -1429px;
  width: 52px;
  height: 39px; }

.icon-Mobile-Deposit {
  background-image: url(sprite.png);
  background-position: -1429px -1357px;
  width: 52px;
  height: 39px; }

.icon-Mobile-bill {
  background-image: url(sprite.png);
  background-position: -144px -1429px;
  width: 52px;
  height: 39px; }

.icon-Mobile-payments-Beeline {
  background-image: url(sprite.png);
  background-position: -1429px -1239px;
  width: 52px;
  height: 39px; }

.icon-Mobile {
  background-image: url(sprite.png);
  background-position: -1663px -764px;
  width: 15px;
  height: 24px; }

.icon-Mojeplatba {
  background-image: url(sprite.png);
  background-position: -1429px -1180px;
  width: 52px;
  height: 39px; }

.icon-Moldovan-lei {
  background-image: url(sprite.png);
  background-position: -1200px -1528px;
  width: 20px;
  height: 20px; }

.icon-Monero {
  background-image: url(sprite.png);
  background-position: -1429px -1062px;
  width: 52px;
  height: 39px; }

.icon-Moneta ru {
  background-image: url(sprite.png);
  background-position: -82px -41px;
  width: 60px;
  height: 45px; }

.icon-Moneta-For-Russia {
  background-image: url(sprite.png);
  background-position: -1429px -708px;
  width: 52px;
  height: 39px; }

.icon-Moneta {
  background-image: url(sprite.png);
  background-position: -1429px -590px;
  width: 52px;
  height: 39px; }

.icon-Money-Global {
  background-image: url(sprite.png);
  background-position: -1429px -531px;
  width: 52px;
  height: 39px; }

.icon-Money-Gram {
  background-image: url(sprite.png);
  background-position: -1429px -295px;
  width: 52px;
  height: 39px; }

.icon-Money-Line-Wallet {
  background-image: url(sprite.png);
  background-position: -1429px -236px;
  width: 52px;
  height: 39px; }

.icon-Money-Mail {
  background-image: url(sprite.png);
  background-position: -1429px -59px;
  width: 52px;
  height: 39px; }

.icon-Money-Order {
  background-image: url(sprite.png);
  background-position: -1429px 0px;
  width: 52px;
  height: 39px; }

.icon-Money-Transfer {
  background-image: url(sprite.png);
  background-position: -1296px -1370px;
  width: 52px;
  height: 39px; }

.icon-Money-Wire {
  background-image: url(sprite.png);
  background-position: -1224px -1370px;
  width: 52px;
  height: 39px; }

.icon-MoneyPak {
  background-image: url(sprite.png);
  background-position: -1152px -1370px;
  width: 52px;
  height: 39px; }

.icon-MoneySafe {
  background-image: url(sprite.png);
  background-position: -1080px -1370px;
  width: 52px;
  height: 39px; }

.icon-Moroccan-dirhams {
  background-image: url(sprite.png);
  background-position: -1543px -1480px;
  width: 20px;
  height: 20px; }

.icon-Moscow-Credit-Bank {
  background-image: url(sprite.png);
  background-position: -1008px -1370px;
  width: 52px;
  height: 39px; }

.icon-Mozambican-meticals {
  background-image: url(sprite.png);
  background-position: -240px -1528px;
  width: 20px;
  height: 20px; }

.icon-Mplatba {
  background-image: url(sprite.png);
  background-position: -360px -1370px;
  width: 52px;
  height: 39px; }

.icon-Multibanco {
  background-image: url(sprite.png);
  background-position: -1080px -1311px;
  width: 52px;
  height: 39px; }

.icon-Multicommerce-Game-Studio {
  background-image: url(sprite.png);
  background-position: -1008px -1311px;
  width: 52px;
  height: 39px; }

.icon-Multiplier {
  background-image: url(sprite.png);
  background-position: -1501px -168px;
  width: 22px;
  height: 22px; }

.icon-My-Cash-card {
  background-image: url(sprite.png);
  background-position: -720px -1311px;
  width: 52px;
  height: 39px; }

.icon-MyBank {
  background-image: url(sprite.png);
  background-position: -648px -1311px;
  width: 52px;
  height: 39px; }

.icon-MyCitadel {
  background-image: url(sprite.png);
  background-position: -504px -1311px;
  width: 52px;
  height: 39px; }

.icon-MyMonedero {
  background-image: url(sprite.png);
  background-position: -432px -1311px;
  width: 52px;
  height: 39px; }

.icon-MyVoucher {
  background-image: url(sprite.png);
  background-position: -288px -1311px;
  width: 52px;
  height: 39px; }

.icon-MypaylinQ {
  background-image: url(sprite.png);
  background-position: -360px -1311px;
  width: 52px;
  height: 39px; }

.icon-Namibian dollars {
  background-image: url(sprite.png);
  background-position: -1360px -1568px;
  width: 20px;
  height: 20px; }

.icon-Namibian-dollars {
  background-image: url(sprite.png);
  background-position: -1400px -1568px;
  width: 20px;
  height: 20px; }

.icon-Neosurf {
  background-image: url(sprite.png);
  background-position: -216px -1311px;
  width: 52px;
  height: 39px; }

.icon-Neteller-1-Pay {
  background-image: url(sprite.png);
  background-position: -144px -1311px;
  width: 52px;
  height: 39px; }

.icon-Neteller {
  background-image: url(sprite.png);
  background-position: -72px -1311px;
  width: 52px;
  height: 39px; }

.icon-Netpay-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1357px -1180px;
  width: 52px;
  height: 39px; }

.icon-Netpay {
  background-image: url(sprite.png);
  background-position: -1357px -1121px;
  width: 52px;
  height: 39px; }

.icon-New Zealand dollars {
  background-image: url(sprite.png);
  background-position: -1623px -1440px;
  width: 20px;
  height: 20px; }

.icon-New-Taiwan-dollars {
  background-image: url(sprite.png);
  background-position: -160px -1608px;
  width: 20px;
  height: 20px; }

.icon-New-Zealand-dollars {
  background-image: url(sprite.png);
  background-position: -1280px -1608px;
  width: 20px;
  height: 20px; }

.icon-Nigerian-nairas {
  background-image: url(sprite.png);
  background-position: -1560px -1608px;
  width: 20px;
  height: 20px; }

.icon-Nordea-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1357px -1062px;
  width: 52px;
  height: 39px; }

.icon-Nordea {
  background-image: url(sprite.png);
  background-position: -1357px -1003px;
  width: 52px;
  height: 39px; }

.icon-Norwegian kroner {
  background-image: url(sprite.png);
  background-position: -1501px -1420px;
  width: 20px;
  height: 20px; }

.icon-Norwegian-kroner {
  background-image: url(sprite.png);
  background-position: 0px -1488px;
  width: 20px;
  height: 20px; }

.icon-Norwegian {
  background-image: url(sprite.png);
  background-position: -40px -1488px;
  width: 20px;
  height: 20px; }

.icon-OKPay {
  background-image: url(sprite.png);
  background-position: -1357px -649px;
  width: 52px;
  height: 39px; }

.icon-OP-Pohjola-Group {
  background-image: url(sprite.png);
  background-position: -1357px -177px;
  width: 52px;
  height: 39px; }

.icon-OTP {
  background-image: url(sprite.png);
  background-position: -1357px -59px;
  width: 52px;
  height: 39px; }

.icon-Oaysafe {
  background-image: url(sprite.png);
  background-position: -1357px -885px;
  width: 52px;
  height: 39px; }

.icon-OchaPay {
  background-image: url(sprite.png);
  background-position: -1357px -826px;
  width: 52px;
  height: 39px; }

.icon-Ogone {
  background-image: url(sprite.png);
  background-position: -1357px -767px;
  width: 52px;
  height: 39px; }

.icon-Omani-rials {
  background-image: url(sprite.png);
  background-position: -280px -1488px;
  width: 20px;
  height: 20px; }

.icon-On-Demand-Funds {
  background-image: url(sprite.png);
  background-position: -1357px -590px;
  width: 52px;
  height: 39px; }

.icon-Online-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -1357px -472px;
  width: 52px;
  height: 39px; }

.icon-Online-Banking {
  background-image: url(sprite.png);
  background-position: -1357px -413px;
  width: 52px;
  height: 39px; }

.icon-Online-Checks {
  background-image: url(sprite.png);
  background-position: -1357px -354px;
  width: 52px;
  height: 39px; }

.icon-Online-bank-transfer-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1357px -531px;
  width: 52px;
  height: 39px; }

.icon-Online_berweisung {
  background-image: url(sprite.png);
  background-position: -1357px -236px;
  width: 52px;
  height: 39px; }

.icon-Onlineberweisung {
  background-image: url(sprite.png);
  background-position: -1357px -295px;
  width: 52px;
  height: 39px; }

.icon-Otopay {
  background-image: url(sprite.png);
  background-position: -1357px -118px;
  width: 52px;
  height: 39px; }

.icon-Overnight-Express {
  background-image: url(sprite.png);
  background-position: -1357px 0px;
  width: 52px;
  height: 39px; }

.icon-P24 {
  background-image: url(sprite.png);
  background-position: -504px -1252px;
  width: 52px;
  height: 39px; }

.icon-PAGOFACIL {
  background-image: url(sprite.png);
  background-position: -1285px -826px;
  width: 52px;
  height: 39px; }

.icon-PLATBA24 {
  background-image: url(sprite.png);
  background-position: -792px -1134px;
  width: 52px;
  height: 39px; }

.icon-POL {
  background-image: url(sprite.png);
  background-position: -648px -1134px;
  width: 52px;
  height: 39px; }

.icon-POLi {
  background-image: url(sprite.png);
  background-position: -360px -1134px;
  width: 52px;
  height: 39px; }

.icon-POP-Bank {
  background-image: url(sprite.png);
  background-position: -144px -1134px;
  width: 52px;
  height: 39px; }

.icon-Pago-efectivo {
  background-image: url(sprite.png);
  background-position: -1285px -1003px;
  width: 52px;
  height: 39px; }

.icon-Papaya-Card {
  background-image: url(sprite.png);
  background-position: -1285px -767px;
  width: 52px;
  height: 39px; }

.icon-Paper-Check {
  background-image: url(sprite.png);
  background-position: -1285px -708px;
  width: 52px;
  height: 39px; }

.icon-Paraguayan-guaranis {
  background-image: url(sprite.png);
  background-position: -1040px -1488px;
  width: 20px;
  height: 20px; }

.icon-PariPlay {
  background-image: url(sprite.png);
  background-position: -1285px -649px;
  width: 52px;
  height: 39px; }

.icon-PastePay {
  background-image: url(sprite.png);
  background-position: -1285px -590px;
  width: 52px;
  height: 39px; }

.icon-Pay-2-Card {
  background-image: url(sprite.png);
  background-position: -1285px -531px;
  width: 52px;
  height: 39px; }

.icon-Pay-Spark {
  background-image: url(sprite.png);
  background-position: -1285px -177px;
  width: 52px;
  height: 39px; }

.icon-Pay-by-Phone {
  background-image: url(sprite.png);
  background-position: -1285px -295px;
  width: 52px;
  height: 39px; }

.icon-Pay2 {
  background-image: url(sprite.png);
  background-position: -1285px -118px;
  width: 52px;
  height: 39px; }

.icon-PayAnyWay {
  background-image: url(sprite.png);
  background-position: -1285px -59px;
  width: 52px;
  height: 39px; }

.icon-PayKasa {
  background-image: url(sprite.png);
  background-position: -1008px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayKwik {
  background-image: url(sprite.png);
  background-position: -864px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayP2P {
  background-image: url(sprite.png);
  background-position: -504px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayPal {
  background-image: url(sprite.png);
  background-position: -432px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayPlay {
  background-image: url(sprite.png);
  background-position: -360px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayPoint-Card {
  background-image: url(sprite.png);
  background-position: -288px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayPoint-e-Voucher {
  background-image: url(sprite.png);
  background-position: -144px -1193px;
  width: 52px;
  height: 39px; }

.icon-PayU {
  background-image: url(sprite.png);
  background-position: -1213px -118px;
  width: 52px;
  height: 39px; }

.icon-PayVision {
  background-image: url(sprite.png);
  background-position: -1213px -59px;
  width: 52px;
  height: 39px; }

.icon-Payeer {
  background-image: url(sprite.png);
  background-position: -1285px 0px;
  width: 52px;
  height: 39px; }

.icon-Payforit {
  background-image: url(sprite.png);
  background-position: -1080px -1193px;
  width: 52px;
  height: 39px; }

.icon-Paylevo {
  background-image: url(sprite.png);
  background-position: -792px -1193px;
  width: 52px;
  height: 39px; }

.icon-Paylines {
  background-image: url(sprite.png);
  background-position: -1501px -1176px;
  width: 22px;
  height: 22px; }

.icon-Paymat-Card {
  background-image: url(sprite.png);
  background-position: -720px -1193px;
  width: 52px;
  height: 39px; }

.icon-Payolution {
  background-image: url(sprite.png);
  background-position: -648px -1193px;
  width: 52px;
  height: 39px; }

.icon-Paysafe-Card {
  background-image: url(sprite.png);
  background-position: 0px -1193px;
  width: 52px;
  height: 39px; }

.icon-Paysec-THB {
  background-image: url(sprite.png);
  background-position: -1213px -1003px;
  width: 52px;
  height: 39px; }

.icon-Paysera {
  background-image: url(sprite.png);
  background-position: -1213px -944px;
  width: 52px;
  height: 39px; }

.icon-Payson {
  background-image: url(sprite.png);
  background-position: -1213px -649px;
  width: 52px;
  height: 39px; }

.icon-Paytrail {
  background-image: url(sprite.png);
  background-position: -1213px -177px;
  width: 52px;
  height: 39px; }

.icon-Peercoin {
  background-image: url(sprite.png);
  background-position: -1213px 0px;
  width: 52px;
  height: 39px; }

.icon-Perfect-Money {
  background-image: url(sprite.png);
  background-position: -1080px -1134px;
  width: 52px;
  height: 39px; }

.icon-Person-to-Person {
  background-image: url(sprite.png);
  background-position: -1008px -1134px;
  width: 52px;
  height: 39px; }

.icon-Person2Person {
  background-image: url(sprite.png);
  background-position: -936px -1134px;
  width: 52px;
  height: 39px; }

.icon-Philippine-pesos {
  background-image: url(sprite.png);
  background-position: -1543px -840px;
  width: 20px;
  height: 20px; }

.icon-Phone {
  background-image: url(sprite.png);
  background-position: -864px -1134px;
  width: 52px;
  height: 39px; }

.icon-PlusPay {
  background-image: url(sprite.png);
  background-position: -720px -1134px;
  width: 52px;
  height: 39px; }

.icon-Poli-by-Skrill {
  background-image: url(sprite.png);
  background-position: -432px -1134px;
  width: 52px;
  height: 39px; }

.icon-Polish-banks {
  background-image: url(sprite.png);
  background-position: -288px -1134px;
  width: 52px;
  height: 39px; }

.icon-Polish-zlotys {
  background-image: url(sprite.png);
  background-position: -1543px -1280px;
  width: 20px;
  height: 20px; }

.icon-Polish {
  background-image: url(sprite.png);
  background-position: -1543px -1320px;
  width: 20px;
  height: 20px; }

.icon-Portuguese {
  background-image: url(sprite.png);
  background-position: -1543px -1440px;
  width: 20px;
  height: 20px; }

.icon-Postal-Check {
  background-image: url(sprite.png);
  background-position: -72px -1134px;
  width: 52px;
  height: 39px; }

.icon-Postepay-by-Skrill {
  background-image: url(sprite.png);
  background-position: 0px -1134px;
  width: 52px;
  height: 39px; }

.icon-Postepay {
  background-image: url(sprite.png);
  background-position: -1141px -826px;
  width: 52px;
  height: 39px; }

.icon-Pragmatic-Play {
  background-image: url(sprite.png);
  background-position: -1141px -649px;
  width: 52px;
  height: 39px; }

.icon-Prepaid-ATM {
  background-image: url(sprite.png);
  background-position: -1141px -354px;
  width: 52px;
  height: 39px; }

.icon-Prepaid-Card {
  background-image: url(sprite.png);
  background-position: -1141px -59px;
  width: 52px;
  height: 39px; }

.icon-Prepaid-Cards {
  background-image: url(sprite.png);
  background-position: -864px -1075px;
  width: 52px;
  height: 39px; }

.icon-Prepaid-Gift-Card {
  background-image: url(sprite.png);
  background-position: -360px -1075px;
  width: 52px;
  height: 39px; }

.icon-Privat24 {
  background-image: url(sprite.png);
  background-position: -144px -1075px;
  width: 52px;
  height: 39px; }

.icon-Probability {
  background-image: url(sprite.png);
  background-position: -72px -1075px;
  width: 52px;
  height: 39px; }

.icon-Progressive {
  background-image: url(sprite.png);
  background-position: -1501px -84px;
  width: 22px;
  height: 22px; }

.icon-Promsvyazbank {
  background-image: url(sprite.png);
  background-position: -1008px -1016px;
  width: 52px;
  height: 39px; }

.icon-Przelewy {
  background-image: url(sprite.png);
  background-position: -936px -1016px;
  width: 52px;
  height: 39px; }

.icon-Przelewy24-by-Skrill {
  background-image: url(sprite.png);
  background-position: -864px -1016px;
  width: 52px;
  height: 39px; }

.icon-Przelewy24 {
  background-image: url(sprite.png);
  background-position: -792px -1016px;
  width: 52px;
  height: 39px; }

.icon-Public-Bank {
  background-image: url(sprite.png);
  background-position: -720px -1016px;
  width: 52px;
  height: 39px; }

.icon-PugglePay {
  background-image: url(sprite.png);
  background-position: 0px -1016px;
  width: 52px;
  height: 39px; }

.icon-QIWI {
  background-image: url(sprite.png);
  background-position: -1069px -826px;
  width: 52px;
  height: 39px; }

.icon-Qatari rials {
  background-image: url(sprite.png);
  background-position: -800px -1528px;
  width: 20px;
  height: 20px; }

.icon-Qatari-rials {
  background-image: url(sprite.png);
  background-position: -840px -1528px;
  width: 20px;
  height: 20px; }

.icon-Quick-Cash {
  background-image: url(sprite.png);
  background-position: -1069px -295px;
  width: 52px;
  height: 39px; }

.icon-Quick-Pay {
  background-image: url(sprite.png);
  background-position: -1069px -177px;
  width: 52px;
  height: 39px; }

.icon-QuickTender {
  background-image: url(sprite.png);
  background-position: -1069px -118px;
  width: 52px;
  height: 39px; }

.icon-QuickTransfer {
  background-image: url(sprite.png);
  background-position: -1069px -59px;
  width: 52px;
  height: 39px; }

.icon-Rabobank {
  background-image: url(sprite.png);
  background-position: -144px -957px;
  width: 52px;
  height: 39px; }

.icon-Rapida {
  background-image: url(sprite.png);
  background-position: -72px -957px;
  width: 52px;
  height: 39px; }

.icon-Redeem-voucher {
  background-image: url(sprite.png);
  background-position: -997px -885px;
  width: 52px;
  height: 39px; }

.icon-Redpagos-by-Neteller {
  background-image: url(sprite.png);
  background-position: -997px -826px;
  width: 52px;
  height: 39px; }

.icon-Reels {
  background-image: url(sprite.png);
  background-position: -1501px -336px;
  width: 22px;
  height: 22px; }

.icon-Reloadit {
  background-image: url(sprite.png);
  background-position: -997px -649px;
  width: 52px;
  height: 39px; }

.icon-Restricted-countries {
  background-image: url(sprite.png);
  background-position: -1501px -378px;
  width: 22px;
  height: 22px; }

.icon-Romanian-lei {
  background-image: url(sprite.png);
  background-position: -1583px 0px;
  width: 20px;
  height: 20px; }

.icon-Romanian {
  background-image: url(sprite.png);
  background-position: -1583px -40px;
  width: 20px;
  height: 20px; }

.icon-Roulette {
  background-image: url(sprite.png);
  background-position: -504px -485px;
  width: 28px;
  height: 28px; }

.icon-Russian-Cash-Terminal {
  background-image: url(sprite.png);
  background-position: -997px -590px;
  width: 52px;
  height: 39px; }

.icon-Russian-rubles {
  background-image: url(sprite.png);
  background-position: -1583px -320px;
  width: 20px;
  height: 20px; }

.icon-Russian {
  background-image: url(sprite.png);
  background-position: -1583px -360px;
  width: 20px;
  height: 20px; }

.icon-S-Bank {
  background-image: url(sprite.png);
  background-position: -997px -531px;
  width: 52px;
  height: 39px; }

.icon-SEB-Bank {
  background-image: url(sprite.png);
  background-position: -925px -354px;
  width: 52px;
  height: 39px; }

.icon-SID {
  background-image: url(sprite.png);
  background-position: -925px -59px;
  width: 52px;
  height: 39px; }

.icon-SMS-Bill {
  background-image: url(sprite.png);
  background-position: -216px -839px;
  width: 52px;
  height: 39px; }

.icon-SMS-Plus {
  background-image: url(sprite.png);
  background-position: -144px -839px;
  width: 52px;
  height: 39px; }

.icon-SMS {
  background-image: url(sprite.png);
  background-position: -72px -839px;
  width: 52px;
  height: 39px; }

.icon-SNS-Bank {
  background-image: url(sprite.png);
  background-position: 0px -839px;
  width: 52px;
  height: 39px; }

.icon-Safe2pay {
  background-image: url(sprite.png);
  background-position: -792px -898px;
  width: 52px;
  height: 39px; }

.icon-SafeCharge {
  background-image: url(sprite.png);
  background-position: -720px -898px;
  width: 52px;
  height: 39px; }

.icon-SafetyPay {
  background-image: url(sprite.png);
  background-position: -360px -898px;
  width: 52px;
  height: 39px; }

.icon-Safetypay-Online-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -504px -898px;
  width: 52px;
  height: 39px; }

.icon-SampoPankki {
  background-image: url(sprite.png);
  background-position: -144px -898px;
  width: 52px;
  height: 39px; }

.icon-Santander {
  background-image: url(sprite.png);
  background-position: -72px -898px;
  width: 52px;
  height: 39px; }

.icon-Saudi riyals {
  background-image: url(sprite.png);
  background-position: -1583px -720px;
  width: 20px;
  height: 20px; }

.icon-Saudi-riyals {
  background-image: url(sprite.png);
  background-position: -1583px -760px;
  width: 20px;
  height: 20px; }

.icon-Savings-Bank {
  background-image: url(sprite.png);
  background-position: 0px -898px;
  width: 52px;
  height: 39px; }

.icon-Sberbank-Online {
  background-image: url(sprite.png);
  background-position: -925px -826px;
  width: 52px;
  height: 39px; }

.icon-Sberbank {
  background-image: url(sprite.png);
  background-position: -925px -767px;
  width: 52px;
  height: 39px; }

.icon-Scatter-Symbol {
  background-image: url(sprite.png);
  background-position: -1501px -504px;
  width: 22px;
  height: 22px; }

.icon-Scotiabank {
  background-image: url(sprite.png);
  background-position: -925px -590px;
  width: 52px;
  height: 39px; }

.icon-Scratch-Card {
  background-image: url(sprite.png);
  background-position: -925px -413px;
  width: 52px;
  height: 39px; }

.icon-Secure-Voucher-payment {
  background-image: url(sprite.png);
  background-position: -925px -295px;
  width: 52px;
  height: 39px; }

.icon-Sepa {
  background-image: url(sprite.png);
  background-position: -925px -118px;
  width: 52px;
  height: 39px; }

.icon-Serbian-dinars {
  background-image: url(sprite.png);
  background-position: -1583px -1360px;
  width: 20px;
  height: 20px; }

.icon-Serbian {
  background-image: url(sprite.png);
  background-position: -1583px -1400px;
  width: 20px;
  height: 20px; }

.icon-Simplified-Chinese {
  background-image: url(sprite.png);
  background-position: -1583px -1520px;
  width: 20px;
  height: 20px; }

.icon-Singapore dollars {
  background-image: url(sprite.png);
  background-position: 0px -1568px;
  width: 20px;
  height: 20px; }

.icon-Singapore-dollars {
  background-image: url(sprite.png);
  background-position: -40px -1568px;
  width: 20px;
  height: 20px; }

.icon-Siru-Mobile {
  background-image: url(sprite.png);
  background-position: -720px -839px;
  width: 52px;
  height: 39px; }

.icon-Skandiabanken-and-Lansforsakringar {
  background-image: url(sprite.png);
  background-position: -648px -839px;
  width: 52px;
  height: 39px; }

.icon-Skrill-1-Tap {
  background-image: url(sprite.png);
  background-position: -576px -839px;
  width: 52px;
  height: 39px; }

.icon-Skrill {
  background-image: url(sprite.png);
  background-position: -432px -839px;
  width: 52px;
  height: 39px; }

.icon-Slot-Type {
  background-image: url(sprite.png);
  background-position: -1501px -630px;
  width: 22px;
  height: 22px; }

.icon-Slovak {
  background-image: url(sprite.png);
  background-position: -360px -1568px;
  width: 20px;
  height: 20px; }

.icon-Slovenian {
  background-image: url(sprite.png);
  background-position: -400px -1568px;
  width: 20px;
  height: 20px; }

.icon-Sofort-by-Skrill {
  background-image: url(sprite.png);
  background-position: -853px -295px;
  width: 52px;
  height: 39px; }

.icon-Sofortuberwaisung {
  background-image: url(sprite.png);
  background-position: -853px -236px;
  width: 52px;
  height: 39px; }

.icon-Software {
  background-image: url(sprite.png);
  background-position: -1501px -672px;
  width: 22px;
  height: 22px; }

.icon-Solid-Debit-Card {
  background-image: url(sprite.png);
  background-position: -853px -177px;
  width: 52px;
  height: 39px; }

.icon-Solo-by-Skrill {
  background-image: url(sprite.png);
  background-position: -853px 0px;
  width: 52px;
  height: 39px; }

.icon-Solo {
  background-image: url(sprite.png);
  background-position: -720px -780px;
  width: 52px;
  height: 39px; }

.icon-South-African-Rand {
  background-image: url(sprite.png);
  background-position: -920px -1568px;
  width: 20px;
  height: 20px; }

.icon-Spanish {
  background-image: url(sprite.png);
  background-position: -960px -1568px;
  width: 20px;
  height: 20px; }

.icon-Speed-Pay {
  background-image: url(sprite.png);
  background-position: -648px -780px;
  width: 52px;
  height: 39px; }

.icon-Speedcard {
  background-image: url(sprite.png);
  background-position: -72px -780px;
  width: 52px;
  height: 39px; }

.icon-SporoPay {
  background-image: url(sprite.png);
  background-position: 0px -780px;
  width: 52px;
  height: 39px; }

.icon-Stander {
  background-image: url(sprite.png);
  background-position: -781px -708px;
  width: 52px;
  height: 39px; }

.icon-Sudanese-pounds {
  background-image: url(sprite.png);
  background-position: -1240px -1568px;
  width: 20px;
  height: 20px; }

.icon-Suomen-Verkkomaksut {
  background-image: url(sprite.png);
  background-position: -781px -649px;
  width: 52px;
  height: 39px; }

.icon-Surefire-Commerce {
  background-image: url(sprite.png);
  background-position: -781px -590px;
  width: 52px;
  height: 39px; }

.icon-Svyazno {
  background-image: url(sprite.png);
  background-position: -781px -531px;
  width: 52px;
  height: 39px; }

.icon-Swedbank {
  background-image: url(sprite.png);
  background-position: -781px -472px;
  width: 52px;
  height: 39px; }

.icon-Swedish kronor {
  background-image: url(sprite.png);
  background-position: -1440px -1568px;
  width: 20px;
  height: 20px; }

.icon-Swedish-kronor {
  background-image: url(sprite.png);
  background-position: -1480px -1568px;
  width: 20px;
  height: 20px; }

.icon-Swedish {
  background-image: url(sprite.png);
  background-position: -1520px -1568px;
  width: 20px;
  height: 20px; }

.icon-Swiff {
  background-image: url(sprite.png);
  background-position: -781px -413px;
  width: 52px;
  height: 39px; }

.icon-Swift-Check {
  background-image: url(sprite.png);
  background-position: -781px -118px;
  width: 52px;
  height: 39px; }

.icon-Swiftvoucher {
  background-image: url(sprite.png);
  background-position: -432px -721px;
  width: 52px;
  height: 39px; }

.icon-Swiss-francs {
  background-image: url(sprite.png);
  background-position: -1623px -80px;
  width: 20px;
  height: 20px; }

.icon-Swissgame {
  background-image: url(sprite.png);
  background-position: -360px -721px;
  width: 52px;
  height: 39px; }

.icon-Switch {
  background-image: url(sprite.png);
  background-position: -288px -721px;
  width: 52px;
  height: 39px; }

.icon-Tanzanian-shillings {
  background-image: url(sprite.png);
  background-position: -1623px -200px;
  width: 20px;
  height: 20px; }

.icon-Tapiola-Bank {
  background-image: url(sprite.png);
  background-position: -144px -721px;
  width: 52px;
  height: 39px; }

.icon-Tapiola {
  background-image: url(sprite.png);
  background-position: -72px -721px;
  width: 52px;
  height: 39px; }

.icon-Tatrabank {
  background-image: url(sprite.png);
  background-position: -648px -662px;
  width: 52px;
  height: 39px; }

.icon-Tele2 {
  background-image: url(sprite.png);
  background-position: -576px -662px;
  width: 52px;
  height: 39px; }

.icon-Telebuy {
  background-image: url(sprite.png);
  background-position: -504px -662px;
  width: 52px;
  height: 39px; }

.icon-Telegraphic-Transfer {
  background-image: url(sprite.png);
  background-position: -360px -662px;
  width: 52px;
  height: 39px; }

.icon-Teleingreso {
  background-image: url(sprite.png);
  background-position: -288px -662px;
  width: 52px;
  height: 39px; }

.icon-Thai-baht {
  background-image: url(sprite.png);
  background-position: -1623px -560px;
  width: 20px;
  height: 20px; }

.icon-Thai {
  background-image: url(sprite.png);
  background-position: -1623px -600px;
  width: 20px;
  height: 20px; }

.icon-ThaiDirect {
  background-image: url(sprite.png);
  background-position: -216px -662px;
  width: 52px;
  height: 39px; }

.icon-The-Bank-of-Aland {
  background-image: url(sprite.png);
  background-position: -72px -662px;
  width: 52px;
  height: 39px; }

.icon-Ticket-Premium {
  background-image: url(sprite.png);
  background-position: 0px -662px;
  width: 52px;
  height: 39px; }

.icon-TicketSurf {
  background-image: url(sprite.png);
  background-position: -709px -590px;
  width: 52px;
  height: 39px; }

.icon-Todito-Cash {
  background-image: url(sprite.png);
  background-position: -709px -531px;
  width: 52px;
  height: 39px; }

.icon-Transfer-Money {
  background-image: url(sprite.png);
  background-position: -709px -472px;
  width: 52px;
  height: 39px; }

.icon-Transferencia-Bancaria-Local {
  background-image: url(sprite.png);
  background-position: -709px -413px;
  width: 52px;
  height: 39px; }

.icon-Transferuj-pl {
  background-image: url(sprite.png);
  background-position: -709px -118px;
  width: 52px;
  height: 39px; }

.icon-TrustPay {
  background-image: url(sprite.png);
  background-position: -576px -603px;
  width: 52px;
  height: 39px; }

.icon-Trustly {
  background-image: url(sprite.png);
  background-position: -709px -59px;
  width: 52px;
  height: 39px; }

.icon-Tunisian-dinars {
  background-image: url(sprite.png);
  background-position: -1623px -1200px;
  width: 20px;
  height: 20px; }

.icon-Turkey-Bank-Wire {
  background-image: url(sprite.png);
  background-position: -504px -603px;
  width: 52px;
  height: 39px; }

.icon-Turkish-Lira {
  background-image: url(sprite.png);
  background-position: -1623px -1280px;
  width: 20px;
  height: 20px; }

.icon-Turkish {
  background-image: url(sprite.png);
  background-position: -1623px -1320px;
  width: 20px;
  height: 20px; }

.icon-Turkiye-Bankasi {
  background-image: url(sprite.png);
  background-position: -432px -603px;
  width: 52px;
  height: 39px; }

.icon-Type-of-bonus {
  background-image: url(sprite.png);
  background-position: -1501px -1134px;
  width: 22px;
  height: 22px; }

.icon-UK-Betting-Shops {
  background-image: url(sprite.png);
  background-position: 0px -603px;
  width: 52px;
  height: 39px; }

.icon-UK-Visa-credit-card {
  background-image: url(sprite.png);
  background-position: -637px -354px;
  width: 52px;
  height: 39px; }

.icon-UK-credit-card {
  background-image: url(sprite.png);
  background-position: -637px -531px;
  width: 52px;
  height: 39px; }

.icon-UPayCard {
  background-image: url(sprite.png);
  background-position: -637px 0px;
  width: 52px;
  height: 39px; }

.icon-US-dollars {
  background-image: url(sprite.png);
  background-position: -600px -1608px;
  width: 20px;
  height: 20px; }

.icon-UTEL {
  background-image: url(sprite.png);
  background-position: -360px -485px;
  width: 52px;
  height: 39px; }

.icon-Ugandan-shillings {
  background-image: url(sprite.png);
  background-position: 0px -1608px;
  width: 20px;
  height: 20px; }

.icon-Ukash {
  background-image: url(sprite.png);
  background-position: -637px -177px;
  width: 52px;
  height: 39px; }

.icon-Ukrainian-hryvnias {
  background-image: url(sprite.png);
  background-position: -280px -1608px;
  width: 20px;
  height: 20px; }

.icon-Ukrainian {
  background-image: url(sprite.png);
  background-position: -320px -1608px;
  width: 20px;
  height: 20px; }

.icon-UnionPay {
  background-image: url(sprite.png);
  background-position: -637px -118px;
  width: 52px;
  height: 39px; }

.icon-United-States {
  background-image: url(sprite.png);
  background-position: -400px -1608px;
  width: 20px;
  height: 20px; }

.icon-Univoucher {
  background-image: url(sprite.png);
  background-position: -637px -59px;
  width: 52px;
  height: 39px; }

.icon-Uruguayan-pesos {
  background-image: url(sprite.png);
  background-position: -560px -1608px;
  width: 20px;
  height: 20px; }

.icon-Use-My-Wallet {
  background-image: url(sprite.png);
  background-position: -360px -544px;
  width: 52px;
  height: 39px; }

.icon-UseMyBank {
  background-image: url(sprite.png);
  background-position: -288px -544px;
  width: 52px;
  height: 39px; }

.icon-UseMyFunds {
  background-image: url(sprite.png);
  background-position: -144px -544px;
  width: 52px;
  height: 39px; }

.icon-UseMyServices {
  background-image: url(sprite.png);
  background-position: -72px -544px;
  width: 52px;
  height: 39px; }

.icon-UseMyWallet {
  background-image: url(sprite.png);
  background-position: -565px 0px;
  width: 52px;
  height: 39px; }

.icon-Uzbekistan som {
  background-image: url(sprite.png);
  background-position: -1000px -1608px;
  width: 20px;
  height: 20px; }

.icon-Uzbekistan-som {
  background-image: url(sprite.png);
  background-position: -1040px -1608px;
  width: 20px;
  height: 20px; }

.icon-Vanilla {
  background-image: url(sprite.png);
  background-position: -216px -485px;
  width: 52px;
  height: 39px; }

.icon-Venezuelan-bolívars {
  background-image: url(sprite.png);
  background-position: -1200px -1608px;
  width: 20px;
  height: 20px; }

.icon-Video-Poker {
  background-image: url(sprite.png);
  background-position: -288px -249px;
  width: 28px;
  height: 28px; }

.icon-Vietnamese-dong {
  background-image: url(sprite.png);
  background-position: -1320px -1608px;
  width: 20px;
  height: 20px; }

.icon-Vietnamese {
  background-image: url(sprite.png);
  background-position: -1360px -1608px;
  width: 20px;
  height: 20px; }

.icon-Vimo-Wallet {
  background-image: url(sprite.png);
  background-position: -72px -485px;
  width: 52px;
  height: 39px; }

.icon-Virtual-Card {
  background-image: url(sprite.png);
  background-position: 0px -485px;
  width: 52px;
  height: 39px; }

.icon-Visa-Debit {
  background-image: url(sprite.png);
  background-position: -493px -295px;
  width: 52px;
  height: 39px; }

.icon-Visa-Delta {
  background-image: url(sprite.png);
  background-position: -493px -236px;
  width: 52px;
  height: 39px; }

.icon-Visa-Electron {
  background-image: url(sprite.png);
  background-position: -493px -177px;
  width: 52px;
  height: 39px; }

.icon-Visa-Virtuon {
  background-image: url(sprite.png);
  background-position: -493px -59px;
  width: 52px;
  height: 39px; }

.icon-Visa-by-Neteller {
  background-image: url(sprite.png);
  background-position: -493px -413px;
  width: 52px;
  height: 39px; }

.icon-Visa-by-Skrill {
  background-image: url(sprite.png);
  background-position: -493px -354px;
  width: 52px;
  height: 39px; }

.icon-Visa {
  background-image: url(sprite.png);
  background-position: -493px 0px;
  width: 52px;
  height: 39px; }

.icon-Voucher {
  background-image: url(sprite.png);
  background-position: -360px -367px;
  width: 52px;
  height: 39px; }

.icon-WING {
  background-image: url(sprite.png);
  background-position: -72px -308px;
  width: 52px;
  height: 39px; }

.icon-Wagering-requirements {
  background-image: url(sprite.png);
  background-position: -1501px -588px;
  width: 22px;
  height: 22px; }

.icon-Wallet-One {
  background-image: url(sprite.png);
  background-position: -288px -367px;
  width: 52px;
  height: 39px; }

.icon-Walmart-Gift-Card {
  background-image: url(sprite.png);
  background-position: -216px -367px;
  width: 52px;
  height: 39px; }

.icon-WeChat-Pay {
  background-image: url(sprite.png);
  background-position: -421px -236px;
  width: 52px;
  height: 39px; }

.icon-Web-Card {
  background-image: url(sprite.png);
  background-position: -144px -367px;
  width: 52px;
  height: 39px; }

.icon-WebMoney {
  background-image: url(sprite.png);
  background-position: 0px -367px;
  width: 52px;
  height: 39px; }

.icon-Webpay-by-Neteller {
  background-image: url(sprite.png);
  background-position: -421px -295px;
  width: 52px;
  height: 39px; }

.icon-Western-Union {
  background-image: url(sprite.png);
  background-position: -421px -177px;
  width: 52px;
  height: 39px; }

.icon-Wild-Symbol {
  background-image: url(sprite.png);
  background-position: -1501px -756px;
  width: 22px;
  height: 22px; }

.icon-William-Hill-cash-direct {
  background-image: url(sprite.png);
  background-position: -288px -308px;
  width: 52px;
  height: 39px; }

.icon-Wire-Transfer {
  background-image: url(sprite.png);
  background-position: 0px -308px;
  width: 52px;
  height: 39px; }

.icon-WireCard {
  background-image: url(sprite.png);
  background-position: -349px -118px;
  width: 52px;
  height: 39px; }

.icon-Withdrawal-Limits {
  background-image: url(sprite.png);
  background-position: -1501px -1092px;
  width: 22px;
  height: 22px; }

.icon-Withdrawal-methods {
  background-image: url(sprite.png);
  background-position: -1501px -714px;
  width: 22px;
  height: 22px; }

.icon-Yandex-Money {
  background-image: url(sprite.png);
  background-position: -349px -59px;
  width: 52px;
  height: 39px; }

.icon-Yap-Kredi {
  background-image: url(sprite.png);
  background-position: -349px 0px;
  width: 52px;
  height: 39px; }

.icon-Yap_-Kredi {
  background-image: url(sprite.png);
  background-position: -277px -59px;
  width: 52px;
  height: 39px; }

.icon-Year-established {
  background-image: url(sprite.png);
  background-position: -1501px -798px;
  width: 22px;
  height: 22px; }

.icon-Zambian-kwachas {
  background-image: url(sprite.png);
  background-position: -1320px -1488px;
  width: 20px;
  height: 20px; }

.icon-Zimpler {
  background-image: url(sprite.png);
  background-position: -277px 0px;
  width: 52px;
  height: 39px; }

.icon-all {
  background-image: url(sprite.png);
  background-position: -1663px -600px;
  width: 20px;
  height: 20px; }

.icon-amd {
  background-image: url(sprite.png);
  background-position: -1663px -560px;
  width: 20px;
  height: 20px; }

.icon-aoa {
  background-image: url(sprite.png);
  background-position: -1663px -440px;
  width: 20px;
  height: 20px; }

.icon-ar {
  background-image: url(sprite.png);
  background-position: -1663px -400px;
  width: 20px;
  height: 20px; }

.icon-ars {
  background-image: url(sprite.png);
  background-position: -1663px -160px;
  width: 20px;
  height: 20px; }

.icon-aud {
  background-image: url(sprite.png);
  background-position: -1663px -80px;
  width: 20px;
  height: 20px; }

.icon-ax {
  background-image: url(sprite.png);
  background-position: -1520px -1608px;
  width: 20px;
  height: 20px; }

.icon-azn {
  background-image: url(sprite.png);
  background-position: -1440px -1608px;
  width: 20px;
  height: 20px; }

.icon-bam {
  background-image: url(sprite.png);
  background-position: -920px -1608px;
  width: 20px;
  height: 20px; }

.icon-bank-draft-express {
  background-image: url(sprite.png);
  background-position: -565px -118px;
  width: 52px;
  height: 39px; }

.icon-bdt {
  background-image: url(sprite.png);
  background-position: -840px -1608px;
  width: 20px;
  height: 20px; }

.icon-be {
  background-image: url(sprite.png);
  background-position: -800px -1608px;
  width: 20px;
  height: 20px; }

.icon-bg {
  background-image: url(sprite.png);
  background-position: -520px -1608px;
  width: 20px;
  height: 20px; }

.icon-bgn {
  background-image: url(sprite.png);
  background-position: -440px -1608px;
  width: 20px;
  height: 20px; }

.icon-bhd {
  background-image: url(sprite.png);
  background-position: -360px -1608px;
  width: 20px;
  height: 20px; }

.icon-bif {
  background-image: url(sprite.png);
  background-position: -240px -1608px;
  width: 20px;
  height: 20px; }

.icon-bnd {
  background-image: url(sprite.png);
  background-position: -120px -1608px;
  width: 20px;
  height: 20px; }

.icon-bob {
  background-image: url(sprite.png);
  background-position: -80px -1608px;
  width: 20px;
  height: 20px; }

.icon-bonus-btn-casino-ico {
  background-image: url(sprite.png);
  background-position: -1663px -960px;
  width: 17px;
  height: 15px; }

.icon-brl {
  background-image: url(sprite.png);
  background-position: -1623px -800px;
  width: 20px;
  height: 20px; }

.icon-btc {
  background-image: url(sprite.png);
  background-position: -1623px -640px;
  width: 20px;
  height: 20px; }

.icon-bulletin-post {
  background-image: url(sprite.png);
  background-position: -432px -662px;
  width: 52px;
  height: 39px; }

.icon-bwp {
  background-image: url(sprite.png);
  background-position: -1623px -360px;
  width: 20px;
  height: 20px; }

.icon-byr {
  background-image: url(sprite.png);
  background-position: -1623px -320px;
  width: 20px;
  height: 20px; }

.icon-ca {
  background-image: url(sprite.png);
  background-position: -1623px -280px;
  width: 20px;
  height: 20px; }

.icon-cad {
  background-image: url(sprite.png);
  background-position: -1623px -240px;
  width: 20px;
  height: 20px; }

.icon-casino_type_download {
  background-image: url(sprite.png);
  background-position: -864px -839px;
  width: 22px;
  height: 24px; }

.icon-casino_type_instant_play {
  background-image: url(sprite.png);
  background-position: -290px -1429px;
  width: 24px;
  height: 19px; }

.icon-casino_type_mobile {
  background-image: url(sprite.png);
  background-position: -1663px -720px;
  width: 15px;
  height: 24px; }

.icon-cdf {
  background-image: url(sprite.png);
  background-position: -1040px -1568px;
  width: 20px;
  height: 20px; }

.icon-chf {
  background-image: url(sprite.png);
  background-position: -1000px -1568px;
  width: 20px;
  height: 20px; }

.icon-china-union-pay {
  background-image: url(sprite.png);
  background-position: -853px -118px;
  width: 52px;
  height: 39px; }

.icon-clp {
  background-image: url(sprite.png);
  background-position: -560px -1568px;
  width: 20px;
  height: 20px; }

.icon-cny {
  background-image: url(sprite.png);
  background-position: -520px -1568px;
  width: 20px;
  height: 20px; }

.icon-cop {
  background-image: url(sprite.png);
  background-position: -200px -1568px;
  width: 20px;
  height: 20px; }

.icon-cr {
  background-image: url(sprite.png);
  background-position: -1583px -1480px;
  width: 20px;
  height: 20px; }

.icon-crc {
  background-image: url(sprite.png);
  background-position: -1583px -1320px;
  width: 20px;
  height: 20px; }

.icon-cs {
  background-image: url(sprite.png);
  background-position: -1583px -1000px;
  width: 20px;
  height: 20px; }

.icon-czk {
  background-image: url(sprite.png);
  background-position: -1583px -800px;
  width: 20px;
  height: 20px; }

.icon-da {
  background-image: url(sprite.png);
  background-position: -1583px -640px;
  width: 20px;
  height: 20px; }

.icon-dao {
  background-image: url(sprite.png);
  background-position: -1583px -560px;
  width: 20px;
  height: 20px; }

.icon-de {
  background-image: url(sprite.png);
  background-position: -1583px -520px;
  width: 20px;
  height: 20px; }

.icon-dk {
  background-image: url(sprite.png);
  background-position: -1583px -400px;
  width: 20px;
  height: 20px; }

.icon-dkk {
  background-image: url(sprite.png);
  background-position: -1501px -1260px;
  width: 20px;
  height: 20px; }

.icon-doge {
  background-image: url(sprite.png);
  background-position: -1400px -1528px;
  width: 20px;
  height: 20px; }

.icon-dotpay {
  background-image: url(sprite.png);
  background-position: -997px -767px;
  width: 52px;
  height: 39px; }

.icon-down-rarr {
  background-image: url(sprite.png);
  background-position: -1663px -995px;
  width: 18px;
  height: 10px; }

.icon-dzd {
  background-image: url(sprite.png);
  background-position: -1080px -1528px;
  width: 20px;
  height: 20px; }

.icon-e-Check {
  background-image: url(sprite.png);
  background-position: -216px -957px;
  width: 52px;
  height: 39px; }

.icon-eCard {
  background-image: url(sprite.png);
  background-position: -576px -957px;
  width: 52px;
  height: 39px; }

.icon-eChecks-by-iGM-Pay {
  background-image: url(sprite.png);
  background-position: -720px -957px;
  width: 52px;
  height: 39px; }

.icon-eKonto {
  background-image: url(sprite.png);
  background-position: -1069px -236px;
  width: 52px;
  height: 39px; }

.icon-eMoney-Safe {
  background-image: url(sprite.png);
  background-position: -1069px -767px;
  width: 52px;
  height: 39px; }

.icon-eNETS {
  background-image: url(sprite.png);
  background-position: -1069px -944px;
  width: 52px;
  height: 39px; }

.icon-ePassporte {
  background-image: url(sprite.png);
  background-position: -288px -1016px;
  width: 52px;
  height: 39px; }

.icon-ePay-bg {
  background-image: url(sprite.png);
  background-position: -360px -1016px;
  width: 52px;
  height: 39px; }

.icon-ePay {
  background-image: url(sprite.png);
  background-position: -432px -1016px;
  width: 52px;
  height: 39px; }

.icon-ePlatby {
  background-image: url(sprite.png);
  background-position: -504px -1016px;
  width: 52px;
  height: 39px; }

.icon-eWallet-Xpress {
  background-image: url(sprite.png);
  background-position: -720px -1075px;
  width: 52px;
  height: 39px; }

.icon-eek {
  background-image: url(sprite.png);
  background-position: -1040px -1528px;
  width: 20px;
  height: 20px; }

.icon-egp {
  background-image: url(sprite.png);
  background-position: -1000px -1528px;
  width: 20px;
  height: 20px; }

.icon-el {
  background-image: url(sprite.png);
  background-position: -920px -1528px;
  width: 20px;
  height: 20px; }

.icon-en {
  background-image: url(sprite.png);
  background-position: -880px -1528px;
  width: 20px;
  height: 20px; }

.icon-es {
  background-image: url(sprite.png);
  background-position: -640px -1528px;
  width: 20px;
  height: 20px; }

.icon-et {
  background-image: url(sprite.png);
  background-position: -480px -1528px;
  width: 20px;
  height: 20px; }

.icon-etb {
  background-image: url(sprite.png);
  background-position: -440px -1528px;
  width: 20px;
  height: 20px; }

.icon-eur {
  background-image: url(sprite.png);
  background-position: -320px -1528px;
  width: 20px;
  height: 20px; }

.icon-ewire {
  background-image: url(sprite.png);
  background-position: -792px -1075px;
  width: 52px;
  height: 39px; }

.icon-eye {
  background-image: url(sprite.png);
  background-position: -378px -1429px;
  width: 26px;
  height: 17px; }

.icon-fi {
  background-image: url(sprite.png);
  background-position: -120px -1528px;
  width: 20px;
  height: 20px; }

.icon-fon2fon {
  background-image: url(sprite.png);
  background-position: -1141px -1062px;
  width: 52px;
  height: 39px; }

.icon-fr {
  background-image: url(sprite.png);
  background-position: 0px -1528px;
  width: 20px;
  height: 20px; }

.icon-ga {
  background-image: url(sprite.png);
  background-position: -1543px -1240px;
  width: 20px;
  height: 20px; }

.icon-gamblingtherapy-logo {
  background-image: url(sprite.png);
  background-position: -216px -1429px;
  width: 54px;
  height: 20px; }

.icon-gbp {
  background-image: url(sprite.png);
  background-position: -1543px -1120px;
  width: 20px;
  height: 20px; }

.icon-gel {
  background-image: url(sprite.png);
  background-position: -1543px -760px;
  width: 20px;
  height: 20px; }

.icon-ghs {
  background-image: url(sprite.png);
  background-position: -1543px -520px;
  width: 20px;
  height: 20px; }

.icon-he {
  background-image: url(sprite.png);
  background-position: -1543px -440px;
  width: 20px;
  height: 20px; }

.icon-hi {
  background-image: url(sprite.png);
  background-position: -1543px -360px;
  width: 20px;
  height: 20px; }

.icon-hkd {
  background-image: url(sprite.png);
  background-position: -1543px -280px;
  width: 20px;
  height: 20px; }

.icon-hr {
  background-image: url(sprite.png);
  background-position: -1543px -200px;
  width: 20px;
  height: 20px; }

.icon-hrk {
  background-image: url(sprite.png);
  background-position: -1543px -160px;
  width: 20px;
  height: 20px; }

.icon-hu {
  background-image: url(sprite.png);
  background-position: -1543px -120px;
  width: 20px;
  height: 20px; }

.icon-huf {
  background-image: url(sprite.png);
  background-position: -1543px -80px;
  width: 20px;
  height: 20px; }

.icon-iBanq {
  background-image: url(sprite.png);
  background-position: -936px -1193px;
  width: 52px;
  height: 39px; }

.icon-iCheque {
  background-image: url(sprite.png);
  background-position: -1152px -1193px;
  width: 52px;
  height: 39px; }

.icon-iDEAL {
  background-image: url(sprite.png);
  background-position: -1285px -413px;
  width: 52px;
  height: 39px; }

.icon-iDeal-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1285px -354px;
  width: 52px;
  height: 39px; }

.icon-iDebit {
  background-image: url(sprite.png);
  background-position: -1285px -472px;
  width: 52px;
  height: 39px; }

.icon-iPoint {
  background-image: url(sprite.png);
  background-position: -1152px -1252px;
  width: 52px;
  height: 39px; }

.icon-icon-down {
  background-image: url(sprite.png);
  background-position: -1663px -1120px;
  width: 12px;
  height: 7px; }

.icon-icon-down {
  background-image: url(sprite.png);
  background-position: -1663px -888px;
  width: 20px;
  height: 14px; }

.icon-icon-top {
  background-image: url(sprite.png);
  background-position: -1663px -1093px;
  width: 12px;
  height: 7px; }

.icon-icon-top {
  background-image: url(sprite.png);
  background-position: -936px -898px;
  width: 23px;
  height: 22px; }

.icon-iconUp {
  background-image: url(sprite.png);
  background-position: 0px -41px;
  width: 62px;
  height: 62px; }

.icon-id {
  background-image: url(sprite.png);
  background-position: -1200px -1488px;
  width: 20px;
  height: 20px; }

.icon-idr {
  background-image: url(sprite.png);
  background-position: -1160px -1488px;
  width: 20px;
  height: 20px; }

.icon-il {
  background-image: url(sprite.png);
  background-position: -1120px -1488px;
  width: 20px;
  height: 20px; }

.icon-ils {
  background-image: url(sprite.png);
  background-position: -1080px -1488px;
  width: 20px;
  height: 20px; }

.icon-inpay {
  background-image: url(sprite.png);
  background-position: -1285px -944px;
  width: 52px;
  height: 39px; }

.icon-inr {
  background-image: url(sprite.png);
  background-position: -880px -1488px;
  width: 20px;
  height: 20px; }

.icon-instaCash {
  background-image: url(sprite.png);
  background-position: -1285px -1121px;
  width: 52px;
  height: 39px; }

.icon-instaDebit {
  background-image: url(sprite.png);
  background-position: -1285px -1180px;
  width: 52px;
  height: 39px; }

.icon-interkassa {
  background-image: url(sprite.png);
  background-position: -864px -1252px;
  width: 52px;
  height: 39px; }

.icon-iqd {
  background-image: url(sprite.png);
  background-position: -800px -1488px;
  width: 20px;
  height: 20px; }

.icon-irr {
  background-image: url(sprite.png);
  background-position: -640px -1488px;
  width: 20px;
  height: 20px; }

.icon-is {
  background-image: url(sprite.png);
  background-position: -600px -1488px;
  width: 20px;
  height: 20px; }

.icon-isk {
  background-image: url(sprite.png);
  background-position: -560px -1488px;
  width: 20px;
  height: 20px; }

.icon-it {
  background-image: url(sprite.png);
  background-position: -440px -1488px;
  width: 20px;
  height: 20px; }

.icon-ja {
  background-image: url(sprite.png);
  background-position: -360px -1488px;
  width: 20px;
  height: 20px; }

.icon-jod {
  background-image: url(sprite.png);
  background-position: -1501px -1380px;
  width: 20px;
  height: 20px; }

.icon-jpy {
  background-image: url(sprite.png);
  background-position: -1623px -1360px;
  width: 20px;
  height: 20px; }

.icon-ka {
  background-image: url(sprite.png);
  background-position: -1623px -1240px;
  width: 20px;
  height: 20px; }

.icon-kes {
  background-image: url(sprite.png);
  background-position: -1560px -1568px;
  width: 20px;
  height: 20px; }

.icon-ko {
  background-image: url(sprite.png);
  background-position: -1200px -1568px;
  width: 20px;
  height: 20px; }

.icon-krw {
  background-image: url(sprite.png);
  background-position: -1583px -1120px;
  width: 20px;
  height: 20px; }

.icon-kwd {
  background-image: url(sprite.png);
  background-position: -1663px -680px;
  width: 20px;
  height: 20px; }

.icon-kzt {
  background-image: url(sprite.png);
  background-position: -1583px -920px;
  width: 20px;
  height: 20px; }

.icon-left {
  background-image: url(sprite.png);
  background-position: -1663px -922px;
  width: 15px;
  height: 18px; }

.icon-liqpay-com {
  background-image: url(sprite.png);
  background-position: -144px -1370px;
  width: 52px;
  height: 39px; }

.icon-little-ref-btn-ico {
  background-image: url(sprite.png);
  background-position: 0px -123px;
  width: 47px;
  height: 47px; }

.icon-lt {
  background-image: url(sprite.png);
  background-position: -200px -1528px;
  width: 20px;
  height: 20px; }

.icon-ltc {
  background-image: url(sprite.png);
  background-position: -1543px -1400px;
  width: 20px;
  height: 20px; }

.icon-ltl {
  background-image: url(sprite.png);
  background-position: -1543px -1200px;
  width: 20px;
  height: 20px; }

.icon-lv {
  background-image: url(sprite.png);
  background-position: -1543px -1160px;
  width: 20px;
  height: 20px; }

.icon-lvl {
  background-image: url(sprite.png);
  background-position: -1543px -1080px;
  width: 20px;
  height: 20px; }

.icon-mBankomat {
  background-image: url(sprite.png);
  background-position: -1429px -826px;
  width: 52px;
  height: 39px; }

.icon-mPeníze {
  background-image: url(sprite.png);
  background-position: -432px -1370px;
  width: 52px;
  height: 39px; }

.icon-mad {
  background-image: url(sprite.png);
  background-position: -1543px -880px;
  width: 20px;
  height: 20px; }

.icon-mdl {
  background-image: url(sprite.png);
  background-position: -1583px -440px;
  width: 20px;
  height: 20px; }

.icon-menuUp {
  background-image: url(sprite.png);
  background-position: -1663px -1147px;
  width: 9px;
  height: 6px; }

.icon-mkd {
  background-image: url(sprite.png);
  background-position: -1583px -280px;
  width: 20px;
  height: 20px; }

.icon-ml {
  background-image: url(sprite.png);
  background-position: -1360px -1528px;
  width: 20px;
  height: 20px; }

.icon-mobile-payment {
  background-image: url(sprite.png);
  background-position: -1429px -1298px;
  width: 52px;
  height: 39px; }

.icon-moneta-ru {
  background-image: url(sprite.png);
  background-position: -1429px -649px;
  width: 52px;
  height: 39px; }

.icon-mur {
  background-image: url(sprite.png);
  background-position: -1440px -1528px;
  width: 20px;
  height: 20px; }

.icon-mxn {
  background-image: url(sprite.png);
  background-position: -1583px -120px;
  width: 20px;
  height: 20px; }

.icon-myr {
  background-image: url(sprite.png);
  background-position: -680px -1568px;
  width: 20px;
  height: 20px; }

.icon-mzn {
  background-image: url(sprite.png);
  background-position: -1280px -1568px;
  width: 20px;
  height: 20px; }

.icon-nad {
  background-image: url(sprite.png);
  background-position: -1320px -1568px;
  width: 20px;
  height: 20px; }

.icon-ngn {
  background-image: url(sprite.png);
  background-position: -1400px -1608px;
  width: 20px;
  height: 20px; }

.icon-nl {
  background-image: url(sprite.png);
  background-position: -1663px -200px;
  width: 20px;
  height: 20px; }

.icon-no {
  background-image: url(sprite.png);
  background-position: -1663px -520px;
  width: 20px;
  height: 20px; }

.icon-nok {
  background-image: url(sprite.png);
  background-position: -1501px -1300px;
  width: 20px;
  height: 20px; }

.icon-nzd {
  background-image: url(sprite.png);
  background-position: -80px -1488px;
  width: 20px;
  height: 20px; }

.icon-omr {
  background-image: url(sprite.png);
  background-position: -320px -1488px;
  width: 20px;
  height: 20px; }

.icon-oxxo {
  background-image: url(sprite.png);
  background-position: -1224px -1252px;
  width: 52px;
  height: 39px; }

.icon-pen {
  background-image: url(sprite.png);
  background-position: -1543px -680px;
  width: 20px;
  height: 20px; }

.icon-php {
  background-image: url(sprite.png);
  background-position: -1543px -920px;
  width: 20px;
  height: 20px; }

.icon-pl {
  background-image: url(sprite.png);
  background-position: -1543px -960px;
  width: 20px;
  height: 20px; }

.icon-pln {
  background-image: url(sprite.png);
  background-position: -1543px -1040px;
  width: 20px;
  height: 20px; }

.icon-popular {
  background-image: url(sprite.png);
  background-position: -432px -367px;
  width: 28px;
  height: 28px; }

.icon-ppc {
  background-image: url(sprite.png);
  background-position: -80px -1528px;
  width: 20px;
  height: 20px; }

.icon-pt {
  background-image: url(sprite.png);
  background-position: -600px -1528px;
  width: 20px;
  height: 20px; }

.icon-pyg {
  background-image: url(sprite.png);
  background-position: -720px -1528px;
  width: 20px;
  height: 20px; }

.icon-qar {
  background-image: url(sprite.png);
  background-position: -760px -1528px;
  width: 20px;
  height: 20px; }

.icon-rarr {
  background-image: url(sprite.png);
  background-position: -720px -721px;
  width: 25px;
  height: 25px; }

.icon-rarr {
  background-image: url(sprite.png);
  background-position: -720px -662px;
  width: 27px;
  height: 24px; }

.icon-red-triangle {
  background-image: url(sprite.png);
  background-position: -648px -603px;
  width: 35px;
  height: 18px; }

.icon-right {
  background-image: url(sprite.png);
  background-position: -1663px -848px;
  width: 15px;
  height: 20px; }

.icon-ro {
  background-image: url(sprite.png);
  background-position: -1520px -1528px;
  width: 20px;
  height: 20px; }

.icon-ron {
  background-image: url(sprite.png);
  background-position: -1583px -80px;
  width: 20px;
  height: 20px; }

.icon-rsd {
  background-image: url(sprite.png);
  background-position: -1583px -160px;
  width: 20px;
  height: 20px; }

.icon-ru {
  background-image: url(sprite.png);
  background-position: -1583px -200px;
  width: 20px;
  height: 20px; }

.icon-rub {
  background-image: url(sprite.png);
  background-position: -1583px -240px;
  width: 20px;
  height: 20px; }

.icon-sar {
  background-image: url(sprite.png);
  background-position: -1583px -680px;
  width: 20px;
  height: 20px; }

.icon-sdg {
  background-image: url(sprite.png);
  background-position: -1583px -1040px;
  width: 20px;
  height: 20px; }

.icon-se {
  background-image: url(sprite.png);
  background-position: -1583px -1080px;
  width: 20px;
  height: 20px; }

.icon-search-ico-hover {
  background-image: url(sprite.png);
  background-position: -1008px -957px;
  width: 20px;
  height: 25px; }

.icon-search-ico {
  background-image: url(sprite.png);
  background-position: -1583px -1160px;
  width: 20px;
  height: 20px; }

.icon-sek {
  background-image: url(sprite.png);
  background-position: -1583px -1280px;
  width: 20px;
  height: 20px; }

.icon-sgd {
  background-image: url(sprite.png);
  background-position: -1583px -1440px;
  width: 20px;
  height: 20px; }

.icon-sk {
  background-image: url(sprite.png);
  background-position: -120px -1568px;
  width: 20px;
  height: 20px; }

.icon-sl {
  background-image: url(sprite.png);
  background-position: -280px -1568px;
  width: 20px;
  height: 20px; }

.icon-sort-bottom {
  background-image: url(sprite.png);
  background-position: -1663px -1025px;
  width: 6px;
  height: 14px; }

.icon-sort-up {
  background-image: url(sprite.png);
  background-position: -1663px -1059px;
  width: 6px;
  height: 14px; }

.icon-sq {
  background-image: url(sprite.png);
  background-position: -1120px -1568px;
  width: 20px;
  height: 20px; }

.icon-sr {
  background-image: url(sprite.png);
  background-position: -1160px -1568px;
  width: 20px;
  height: 20px; }

.icon-th {
  background-image: url(sprite.png);
  background-position: -1623px -520px;
  width: 20px;
  height: 20px; }

.icon-thb {
  background-image: url(sprite.png);
  background-position: -1623px -680px;
  width: 20px;
  height: 20px; }

.icon-tk {
  background-image: url(sprite.png);
  background-position: -1623px -840px;
  width: 20px;
  height: 20px; }

.icon-tnd {
  background-image: url(sprite.png);
  background-position: -1623px -880px;
  width: 20px;
  height: 20px; }

.icon-try {
  background-image: url(sprite.png);
  background-position: -1623px -1160px;
  width: 20px;
  height: 20px; }

.icon-twd {
  background-image: url(sprite.png);
  background-position: -1623px -1400px;
  width: 20px;
  height: 20px; }

.icon-tzs {
  background-image: url(sprite.png);
  background-position: -1623px -1480px;
  width: 20px;
  height: 20px; }

.icon-u-net {
  background-image: url(sprite.png);
  background-position: -288px -603px;
  width: 52px;
  height: 39px; }

.icon-uah {
  background-image: url(sprite.png);
  background-position: -1623px -1560px;
  width: 20px;
  height: 20px; }

.icon-ugx {
  background-image: url(sprite.png);
  background-position: -40px -1608px;
  width: 20px;
  height: 20px; }

.icon-uk {
  background-image: url(sprite.png);
  background-position: -200px -1608px;
  width: 20px;
  height: 20px; }

.icon-up-rarr {
  background-image: url(sprite.png);
  background-position: -1663px -808px;
  width: 18px;
  height: 20px; }

.icon-us {
  background-image: url(sprite.png);
  background-position: -640px -1608px;
  width: 20px;
  height: 20px; }

.icon-usd {
  background-image: url(sprite.png);
  background-position: -680px -1608px;
  width: 20px;
  height: 20px; }

.icon-uyu {
  background-image: url(sprite.png);
  background-position: -960px -1608px;
  width: 20px;
  height: 20px; }

.icon-uzs {
  background-image: url(sprite.png);
  background-position: -1080px -1608px;
  width: 20px;
  height: 20px; }

.icon-veb {
  background-image: url(sprite.png);
  background-position: -1160px -1608px;
  width: 20px;
  height: 20px; }

.icon-vi {
  background-image: url(sprite.png);
  background-position: -1240px -1608px;
  width: 20px;
  height: 20px; }

.icon-vnd {
  background-image: url(sprite.png);
  background-position: -1663px -120px;
  width: 20px;
  height: 20px; }

.icon-xmr {
  background-image: url(sprite.png);
  background-position: -880px -1568px;
  width: 20px;
  height: 20px; }

.icon-zar {
  background-image: url(sprite.png);
  background-position: -1280px -1488px;
  width: 20px;
  height: 20px; }

.icon-zh {
  background-image: url(sprite.png);
  background-position: -1240px -1528px;
  width: 20px;
  height: 20px; }

.icon-zmk {
  background-image: url(sprite.png);
  background-position: -840px -1488px;
  width: 20px;
  height: 20px; }

.icon-ČSOB {
  background-image: url(sprite.png);
  background-position: -1285px -236px;
  width: 52px;
  height: 39px; }

body, html {
  overflow-x: hidden; }

body {
  font-size: 14px;
  min-width: 320px;
  position: relative;
  line-height: 24px;
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  color: #52585d;
  font-weight: 500;
  background: #FFF; }
  body input, body textarea {
    border: #e2e2e2 1px solid;
    outline: none; }
    body input:focus:required:invalid, body textarea:focus:required:invalid {
      border-color: red; }
    body input:required:valid, body textarea:required:valid {
      border-color: green; }
  body a {
    color: #8f8f8f; }
  body hr {
    border-color: transparent !important;
    background: transparent !important; }
  body .alignleft {
    display: block;
    float: left;
    margin: 5px 10px 5px 0; }
  body .alignright {
    display: block;
    float: right;
    margin: 5px 0 5px 10px; }
  body .h1, body .h2, body .h3, body .h4, body .h5, body .h6, body h1, body h2, body h3, body h4, body h5, body h6 {
    font-weight: 500; }
  body .lazyload {
    z-index: 10000; }

.alignRight {
  text-align: right; }

.center {
  text-align: center;
  display: block;
  width: 100%; }

.alignLeft {
  text-align: left; }

.padding0 {
  padding: 0; }

.paddingLeft0 {
  padding-left: 0; }

.borderRight1 {
  border-right: 1px solid #d9e1e4 !important; }

.borderLeft1 {
  border-left: 1px solid #d9e1e4 !important; }

.overflowHidden {
  overflow: hidden; }

.line {
  background: #d9e1e4;
  height: 1px;
  display: block;
  clear: both;
  margin-bottom: 23px; }

.floatLeft {
  float: left;
  margin-right: 25px; }

.floatRight {
  float: right;
  margin-left: 25px; }

.clear {
  clear: both; }

.search_block {
  display: inline-block;
  position: relative;
  border-right: 1px solid #454b54;
  border-left: 1px solid #454b54;
  width: 50px;
  height: 34px;
  cursor: pointer;
  margin: 13px 0 7px; }
  .search_block i {
    display: block;
    margin: 5px auto; }

.search-form {
  display: none;
  position: absolute;
  padding: 3px;
  background: #2ac25e;
  z-index: 1000;
  border-radius: 5px;
  right: -218px;
  top: 11px; }
  .search-form.active {
    right: 55px;
    display: block; }
  .search-form input[type='text'] {
    padding: 4px 30px 4px 7px;
    border-radius: 5px;
    font-size: 14px; }
  .search-form input[type='submit'] {
    background: url(../img/search-ico.png) center 6px no-repeat #fff;
    width: 28px;
    height: 32px;
    position: absolute;
    right: 5px;
    top: 4px;
    border: 0;
    border-radius: 0 5px 5px 0;
    cursor: pointer; }
  .search-form .search-submit {
    width: 28px;
    height: 32px;
    position: absolute;
    right: 5px;
    top: 5px;
    border: 0;
    border-radius: 0 5px 5px 0;
    cursor: pointer; }
    .search-form .search-submit i {
      position: absolute;
      display: block;
      right: 6px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 22px; }

.choose_language_block {
  display: inline-block;
  position: relative;
  margin: 13px 0 -2px -3px;
  vertical-align: top;
  border-right: 1px solid #454b54;
  height: 34px;
  width: 46px; }
  .choose_language_block .language_active {
    background: url(../img/flags-ico.png) -480px center no-repeat;
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 6px 13px; }
  .choose_language_block .language_links_block {
    background: #313840;
    padding: 0 5px;
    border-radius: 5px;
    position: absolute;
    width: 36px;
    margin-left: 4px;
    z-index: 100;
    display: none; }
    .choose_language_block .language_links_block.active {
      display: block; }
    .choose_language_block .language_links_block a {
      background: url(../img/flags-ico.png) 0 center no-repeat;
      height: 22px;
      margin: 5px auto;
      width: 20px;
      display: block;
      color: transparent;
      cursor: pointer;
      overflow: hidden; }
      .choose_language_block .language_links_block a.language_sw {
        background-position: -120px center !important; }
      .choose_language_block .language_links_block a.language_ge {
        background-position: -160px center !important; }
      .choose_language_block .language_links_block a.language_fn {
        background-position: -40px center !important; }
      .choose_language_block .language_links_block a.language_no {
        background-position: -80px center !important; }
      .choose_language_block .language_links_block a.language_pl {
        background-position: 0px center !important; }
      .choose_language_block .language_links_block a.language_da {
        background-position: -200px center !important; }
      .choose_language_block .language_links_block a.language_de {
        background-position: -240px center !important; }
      .choose_language_block .language_links_block a.language_nl {
        background-position: -280px center !important; }
      .choose_language_block .language_links_block a.language_ca {
        background-position: -320px center !important; }
      .choose_language_block .language_links_block a.language_nz {
        background-position: -360px center !important; }
      .choose_language_block .language_links_block a.language_fr {
        background-position: -400px center !important; }
      .choose_language_block .language_links_block a.language_ja {
        background-position: -440px center !important; }
      .choose_language_block .language_links_block a.language_ro {
        background-position: -480px center !important; }

/* header */
.mobile-menu-icon {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  margin: 15px -15px 0 10px;
  padding: 7px;
  line-height: 0;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  z-index: 1000; }
  .mobile-menu-icon span {
    background: #454b54;
    margin-top: 3px;
    width: 100%;
    height: 3px;
    display: inline-block; }

.mobile-menu-cnt {
  display: none; }
  .mobile-menu-cnt .mobile-header-menu {
    position: absolute;
    width: 85%;
    background: #f5f5f5;
    z-index: 9;
    top: 63px;
    display: block; }
    .mobile-menu-cnt .mobile-header-menu ul {
      padding: 0;
      margin: 0;
      border: 1px solid #d9e1e4; }
      .mobile-menu-cnt .mobile-header-menu ul li {
        display: block;
        border-top: 1px solid #454b54;
        position: relative; }
        .mobile-menu-cnt .mobile-header-menu ul li a {
          color: #818181;
          font-weight: 600;
          padding: 10px;
          display: block; }
          .mobile-menu-cnt .mobile-header-menu ul li a:hover {
            text-decoration: none;
            color: #ffc462; }
        .mobile-menu-cnt .mobile-header-menu ul li .sub-menu {
          background: #fff;
          padding: 10px;
          text-align: center; }
          .mobile-menu-cnt .mobile-header-menu ul li .sub-menu li {
            border: 0; }
            .mobile-menu-cnt .mobile-header-menu ul li .sub-menu li a {
              display: block;
              position: relative;
              color: #818181;
              font-weight: 500;
              font-size: 14px;
              padding: 5px 0; }
              .mobile-menu-cnt .mobile-header-menu ul li .sub-menu li a:hover {
                text-decoration: underline; }
  .mobile-menu-cnt.active {
    display: block; }

.sub-menu {
  display: none; }

.active-link:after {
  content: "►";
  display: block;
  cursor: pointer;
  width: 40px;
  height: 30px;
  color: #000;
  position: absolute;
  right: 0;
  top: 10px; }

.active-link ul.sub-menu {
  display: block; }

.header {
  background: linear-gradient(0deg, #05070a, #192231); }
  .header .header-logo {
    background: url(../img/logo.png) no-repeat 50% transparent;
    display: block;
    position: absolute;
    width: 160px;
    height: 62px;
    left: 0;
    z-index: 15;
    top: 0;
    margin-left: 10px; }

.header-line {
  display: block;
  position: relative;
  height: 7px;
  width: 100%;
  background: #f7f7f7; }

/* front-page */
.promo-block-left {
  height: auto;
  overflow: hidden;
  background: #fff;
  border: 1px solid #d9e1e4;
  border-radius: 5px; }
  .promo-block-left .promo-block-title {
    border-top: 0; }

.promo-block-title {
  color: #53595e;
  text-align: center;
  font-size: 22px;
  border-bottom: 1px solid #d9e1e4;
  border-top: 1px solid #d9e1e4;
  padding: 10px 0 10px;
  font-weight: 700; }

.header-promo-block {
  display: block;
  position: relative;
  margin: 15px -10px;
  overflow: hidden; }
  .header-promo-block .casinos-header {
    position: relative;
    z-index: 1;
    border: 1px solid #d9e1e4;
    box-shadow: 0 0 7px #d9e1e4;
    border-radius: 5px; }
    .header-promo-block .casinos-header .promo-block-title {
      border-top: 0; }
    .header-promo-block .casinos-header .load-more-in-tab {
      border-top: 0; }
  .header-promo-block .promo-block-left .casino-promo-cnt {
    display: block;
    position: relative;
    margin: 0 auto; }
    .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item {
      border-bottom: 1px solid #d9e1e4;
      padding: 2px 15px;
      height: auto;
      overflow: hidden; }
      .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item:nth-child(even) {
        background: #f7f7f7; }
      .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item:last-child {
        border: 0; }
      .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item .casino-logo {
        border-right: 0; }
        .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item .casino-logo img {
          border-radius: 5px;
          max-width: 100%;
          margin: -2px auto;
          display: block; }
      .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item .casino-rating {
        border-right: 1px solid #d9e1e4;
        border-left: 1px solid #d9e1e4;
        margin: 2px -1px; }
      .header-promo-block .promo-block-left .casino-promo-cnt .casino-promo-item .casino-btn-ref {
        padding: 7px 18px;
        border-radius: 3px;
        margin: 15px auto;
        max-width: 100%;
        display: block;
        width: 94px; }

.content-front {
  padding: 15px; }

.content-block-bg {
  background: #f7f7f7; }

.btn {
  background: #febf00;
  background: linear-gradient(to top, #fc8801, #febf00);
  color: #fff;
  line-height: 24px;
  font-weight: 400; }
  .btn:hover {
    color: #fff;
    opacity: 0.8; }

.content {
  display: block;
  position: relative; }
  .content h1 {
    font-weight: 700;
    line-height: 40px;
    font-size: 38px; }

.hidden-text {
  padding-bottom: 30px; }
  .hidden-text.active {
    height: auto !important; }

.hidden-block {
  display: block;
  position: absolute;
  height: 50px;
  z-index: 3;
  width: 100%;
  bottom: 2px;
  background: #f5f5f5;
  background: linear-gradient(180deg, rgba(245, 245, 245, 0.50744), #f5f5f5); }
  .hidden-block.no-active {
    display: none; }

.hidden-btn {
  color: #1b8bf4;
  width: 130px;
  margin: 15px auto;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  font-size: 18px;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 9px; }
  .hidden-btn span {
    color: #437155;
    display: inline-block;
    border: 2px solid #437155;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    margin-left: 10px; }
  .hidden-btn:hover {
    color: #ac3121; }

.title-cnt {
  padding: 15px 0; }
  .title-cnt .title {
    color: #29333e;
    font-size: 32px;
    line-height: 32px; }

/* rating */
progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 7px;
  margin-bottom: 0; }

progress[value]::-webkit-progress-bar {
  background-color: #c4d7ee;
  border-radius: 8px; }

progress[value]::-webkit-progress-value {
  border-radius: 8px;
  background: linear-gradient(to right, #6fabf7, #257ff0);
  -webkit-animation: animate-stripes 5s linear infinite;
  animation: animate-stripes 5s linear infinite; }

@-webkit-keyframes animate-stripes {
  100% {
    background-position: -100px 0; } }

@keyframes animate-stripes {
  100% {
    background-position: -100px 0; } }

progress[value]::-webkit-progress-value::before {
  content: '80%';
  position: absolute;
  right: 0;
  top: -125%; }

progress[value]::-webkit-progress-value::after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  right: 7px;
  top: 7px;
  background-color: #fff; }

progress[value]::-moz-progress-bar {
  background: linear-gradient(to right, #6fabf7, #257ff0);
  border-radius: 8px; }

.progress-value {
  color: #29333e;
  font-weight: 600;
  margin: 6px auto -10px; }

.progress-value span {
  color: #c8c8c8; }

/* tabs */
.sorting-th {
  width: 100%; }

.table-tabs .load-more-in-tab {
  border-top: 0; }

.table-tabs .tabs-block {
  padding: 0 15px; }
  .table-tabs .tabs-block .tab-item {
    padding: 10px 0;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    border: 1px solid #d9e1e4;
    height: 46px;
    overflow: hidden;
    background: #fbfbfb;
    margin: 2px 0; }
    .table-tabs .tabs-block .tab-item.active {
      background: #FFF; }
    .table-tabs .tabs-block .tab-item:last-child {
      line-height: 17px;
      padding: 6px 0; }
    .table-tabs .tabs-block .tab-item .title-item {
      color: #29333e;
      display: block;
      text-align: center;
      font-size: 13px;
      font-weight: 600; }
      .table-tabs .tabs-block .tab-item .title-item i {
        display: inline-block;
        vertical-align: middle; }

.table-tabs .oneTableTab {
  padding: 0 15px; }
  .table-tabs .oneTableTab .table-sorter {
    background: #fff;
    border: 1px solid #d0d0d0;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    width: 100%; }
    .table-tabs .oneTableTab .table-sorter .sorting-panel {
      background: #6fabf7;
      height: auto;
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid #d9e1e4; }
      .table-tabs .oneTableTab .table-sorter .sorting-panel .sort-items-title {
        display: block;
        padding: 5px 0;
        text-align: center;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        margin: 2px 0; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body tr:nth-child(2n) {
      background: #f7f7f7; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body .casino-title-hidden {
      display: none; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body .casino-logo {
      border-right: 0; }
      .table-tabs .oneTableTab .table-sorter .casino-table-body .casino-logo img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        border-radius: 5px; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body .casino-rating {
      margin: -20px -1px 10px;
      padding: 10px 15px 20px; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body .casino-btn-ref {
      padding: 5px 27px;
      border-radius: 3px;
      display: inline-block;
      margin: 12px 15px; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body tr {
      border-bottom: 1px solid #d9e1e4; }
      .table-tabs .oneTableTab .table-sorter .casino-table-body tr td {
        padding: 10px 5px 0;
        border: 0;
        text-align: left; }
      .table-tabs .oneTableTab .table-sorter .casino-table-body tr td:nth-child(1) {
        width: 50%; }
      .table-tabs .oneTableTab .table-sorter .casino-table-body tr td:nth-child(2) {
        width: 50%; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body .table-casino-bonus {
      padding: 0 10px;
      font-size: 14px;
      text-align: center; }
    .table-tabs .oneTableTab .table-sorter .casino-table-body .btns-cnt {
      /*border-left: 1px solid $borderColor;*/ }
      .table-tabs .oneTableTab .table-sorter .casino-table-body .btns-cnt .casinos-link-btn {
        text-decoration: underline;
        margin: -10px auto 5px;
        display: block;
        font-weight: 300;
        color: #e36384; }
        .table-tabs .oneTableTab .table-sorter .casino-table-body .btns-cnt .casinos-link-btn:hover {
          text-decoration: none;
          color: #52585d; }
  .table-tabs .oneTableTab .load-more-in-tab {
    background: #e3e3e3;
    color: #29333e;
    text-decoration: underline;
    padding: 7px 10px 10px;
    border-radius: 0 0 5px 5px;
    text-align: center;
    cursor: pointer;
    transition: top 1s ease-out 0.5s;
    width: 100%;
    border: 1px solid #d9e1e4;
    border-top: 0; }
    .table-tabs .oneTableTab .load-more-in-tab:hover {
      text-decoration: none; }

/* promotion block */
.promotion-block {
  margin: 20px -5px 0; }
  .promotion-block .one-news-item {
    height: 87px;
    overflow: hidden;
    padding: 13px 10px;
    line-height: 14px;
    border-bottom: 1px solid #d9e1e4; }
    .promotion-block .one-news-item:hover {
      background: #6daaf7; }
      .promotion-block .one-news-item:hover .promotion-title {
        color: #FFFFFF; }
      .promotion-block .one-news-item:hover .promotion-text {
        color: #FFFFFF; }
    .promotion-block .one-news-item:last-child {
      border-bottom: 0; }
    .promotion-block .one-news-item img {
      border-radius: 5px;
      height: 60px; }
    .promotion-block .one-news-item .promotion-title {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 3px;
      display: block;
      max-height: 30px;
      overflow: hidden;
      color: #52585d; }
    .promotion-block .one-news-item .promotion-text {
      font-size: 14px;
      padding: 5px 0 0; }
  .promotion-block .show-more-promo {
    color: #29333e;
    position: relative;
    padding: 9px 10px;
    margin-top: 6px;
    text-align: center;
    display: block; }

/* slots */
.front-slots-bg {
  background: #fc8f01; }

.front-slots-cnt {
  height: auto;
  overflow: hidden;
  padding: 15px 0;
  line-height: 29px;
  text-align: center; }
  .front-slots-cnt .more-slots-btn {
    border: 1px solid #FFF;
    color: #fff;
    margin: 0 auto 15px;
    cursor: pointer;
    display: block;
    width: 198px;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: 500; }
    .front-slots-cnt .more-slots-btn:hover {
      background: #fff;
      color: #c84646; }
  .front-slots-cnt .filter-slots {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center; }
    .front-slots-cnt .filter-slots li {
      display: inline-block;
      border: 1px solid #d9e1e4;
      padding: 2px 10px;
      color: #fff;
      border-radius: 5px;
      margin: 5px;
      cursor: pointer; }
      .front-slots-cnt .filter-slots li:hover {
        color: #ac3121;
        background: #fff; }
      .front-slots-cnt .filter-slots li.active {
        background: #fff;
        color: #52585d; }
  .front-slots-cnt .title-front-slots-cnt {
    font-size: 26px;
    color: #FFF;
    padding: 15px 0; }
  .front-slots-cnt .slots-block ul {
    list-style: none;
    margin: 15px 0;
    padding: 0;
    text-align: center; }
    .front-slots-cnt .slots-block ul li {
      background: #FFF;
      padding: 3px;
      border-radius: 5px;
      margin: 0 0 12px;
      max-width: 228px;
      cursor: pointer;
      min-width: 228px;
      display: inline-block; }
      .front-slots-cnt .slots-block ul li .slot-img-block {
        display: block;
        position: relative;
        height: auto;
        overflow: hidden; }
        .front-slots-cnt .slots-block ul li .slot-img-block:hover .slot-hidden-block {
          display: block; }
        .front-slots-cnt .slots-block ul li .slot-img-block img {
          max-width: 100%;
          border: none;
          width: 100%; }
        .front-slots-cnt .slots-block ul li .slot-img-block .slot-hidden-block {
          background: rgba(0, 0, 0, 0.7);
          width: 100%;
          height: 100%;
          display: none;
          position: absolute;
          top: 0; }
          .front-slots-cnt .slots-block ul li .slot-img-block .slot-hidden-block .slot-hidden-block-btn {
            background: url(../img/play-btn-ico.png) no-repeat;
            display: block;
            width: 68px;
            height: 62px;
            position: absolute;
            z-index: 1000;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
            .front-slots-cnt .slots-block ul li .slot-img-block .slot-hidden-block .slot-hidden-block-btn:hover {
              background-position: 0 -79px; }
      .front-slots-cnt .slots-block ul li .slot-title_cnt {
        position: relative;
        height: 48px;
        overflow: hidden;
        vertical-align: middle;
        display: table;
        text-align: center;
        width: 100%; }
        .front-slots-cnt .slots-block ul li .slot-title_cnt .slot-title {
          color: #434343;
          padding: 5px;
          text-align: center;
          font-weight: 600;
          display: table-cell;
          vertical-align: middle;
          line-height: 18px; }

/* front new sidebar */
.news-block .one-news-item {
  height: 94px;
  overflow: hidden;
  padding: 10px;
  line-height: 14px;
  border-bottom: 1px solid #d9e1e4; }
  .news-block .one-news-item img {
    border-radius: 5px;
    width: 80px;
    margin: 10px 10px 18px 0; }
  .news-block .one-news-item:hover {
    background: #fbfbfb; }
  .news-block .one-news-item:nth-child(even) {
    background: #fbfbfb; }
  .news-block .one-news-item:hover .promotion-title {
    text-decoration: none;
    color: #FFFFFF; }
  .news-block .one-news-item:last-child {
    border-bottom: 0; }
  .news-block .one-news-item .promotion-title {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 3px;
    display: block;
    line-height: 16px;
    color: #52585d;
    margin-top: 0; }
    .news-block .one-news-item .promotion-title:hover {
      text-decoration: none; }
  .news-block .one-news-item .promotion-text {
    font-size: 12px;
    padding: 5px 0;
    line-height: 14px; }

/* Nowe automaty do gry */
.new-games-block {
  display: block;
  position: relative; }
  .new-games-block ul {
    text-align: center;
    padding: 0;
    list-style: none; }
    .new-games-block ul li {
      background: #FFF;
      padding: 7px 15px;
      border-radius: 5px;
      margin: 0 0 12px;
      max-width: 100%;
      min-width: 100%;
      display: inline-block;
      cursor: pointer; }
      .new-games-block ul li .slot-img-block {
        display: block;
        position: relative;
        height: auto;
        overflow: hidden;
        border-radius: 5px; }
        .new-games-block ul li .slot-img-block:hover .slot-hidden-block {
          display: block; }
        .new-games-block ul li .slot-img-block img {
          border: none;
          width: 100%; }
        .new-games-block ul li .slot-img-block .slot-hidden-block {
          background: rgba(0, 0, 0, 0.7);
          width: 100%;
          height: 100%;
          display: none;
          position: absolute;
          top: 0; }
          .new-games-block ul li .slot-img-block .slot-hidden-block .slot-hidden-block-btn {
            background: url(../img/play-btn-ico.png) no-repeat;
            display: block;
            width: 68px;
            height: 62px;
            position: absolute;
            z-index: 1000;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
            .new-games-block ul li .slot-img-block .slot-hidden-block .slot-hidden-block-btn:hover {
              background-position: 0 -79px; }
      .new-games-block ul li .slot-title {
        display: none; }

/* footer */
.footer-line {
  background: #e3e3e3;
  width: 100%;
  height: 10px;
  display: block;
  position: relative; }

footer {
  padding: 15px; }
  footer .footer-before-block .footer-img-cnt {
    display: block;
    color: #5b6267;
    padding-top: 10px;
    line-height: 20px;
    font-size: 13px;
    background: url("../img/footer-text.png") no-repeat left top transparent;
    background-size: contain;
    width: 100%;
    height: 210px; }
    footer .footer-before-block .footer-img-cnt .footer-title {
      color: #434343;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 10px; }
  footer .footer-before-block .footer-menu ul {
    list-style: none;
    padding: 13px 0;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1; }
    footer .footer-before-block .footer-menu ul li {
      display: block; }
      footer .footer-before-block .footer-menu ul li a {
        display: block;
        color: #1b8bf4;
        text-decoration: underline;
        padding: 5px 0;
        font-size: 15px;
        line-height: 18px; }
        footer .footer-before-block .footer-menu ul li a:hover {
          color: #60acf3; }
  footer .footer-news {
    border-top: 1px solid #d9e1e4;
    padding: 15px 0 0; }
    footer .footer-news .footer-title {
      color: #434343;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 10px; }
    footer .footer-news ul {
      text-align: center;
      padding: 0;
      list-style: none;
      margin: 10px 0 0; }
      footer .footer-news ul li {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 15px;
        opacity: .7;
        text-align: center;
        width: 190px; }
        footer .footer-news ul li:hover {
          opacity: 1; }
        footer .footer-news ul li:hover .footer-new-title {
          color: #60acf3; }
        footer .footer-news ul li img {
          width: 190px;
          border-radius: 5px;
          display: block;
          margin: 0 auto; }
        footer .footer-news ul li .footer-new-title {
          padding: 5px 3px;
          color: #1b8bf4;
          text-decoration: underline;
          text-align: left;
          clear: both;
          font-size: 14px; }

.under-footer-block {
  background: #e3e3e3;
  padding: 7px 0;
  color: #1b8bf4; }
  .under-footer-block .soc-block {
    text-align: center; }
    .under-footer-block .soc-block a {
      display: inline-block;
      width: 26px;
      height: 23px;
      background: url(../img/social-net-icons.png) 10px center no-repeat;
      margin-top: 5px;
      opacity: .7; }
      .under-footer-block .soc-block a:nth-child(2) {
        background-position: -24px center; }
      .under-footer-block .soc-block a:nth-child(3) {
        background-position: -60px center; }
      .under-footer-block .soc-block a:nth-child(4) {
        background-position: -129px center;
        width: 35px; }
      .under-footer-block .soc-block a:hover {
        opacity: 1;
        font-size: 14px; }
  .under-footer-block .copy-rights {
    color: #ababab;
    padding: 5px 0 3px;
    font-size: 14px;
    text-align: center; }
  .under-footer-block .payment-sys {
    text-align: center;
    float: none;
    height: 33px;
    display: block; }
    .under-footer-block .payment-sys a {
      display: inline-block;
      vertical-align: middle; }
      .under-footer-block .payment-sys a.footer-gambl-ter-link {
        width: 54px;
        height: 20px;
        background: url(../img/gamblingtherapy-logo.png) 0 center no-repeat; }
      .under-footer-block .payment-sys a:nth-child(2) {
        width: 40px;
        height: 33px;
        margin: 0 7px;
        background: url(../img/18-dmca-gpwa-icons.png) 0 center no-repeat; }
      .under-footer-block .payment-sys a:nth-child(4) {
        width: 46px;
        height: 33px;
        background: url(../img/18-dmca-gpwa-icons.png) -139px center no-repeat; }
      .under-footer-block .payment-sys a.dmca {
        width: 64px;
        height: 33px;
        background: url(../img/18-dmca-gpwa-icons.png) -56px center no-repeat;
        margin: 0 7px; }

/* casinos-template */
.category-casino-spellen .line {
  margin-bottom: 23px !important; }

.casinos-template {
  position: relative; }
  .casinos-template .part-title {
    font-size: 32px;
    line-height: 32px;
    margin: 15px 0; }
  .casinos-template .line {
    margin-bottom: 0; }
  .casinos-template .more-casino-btn {
    color: #fff;
    margin: 0 auto 15px;
    cursor: pointer;
    display: block;
    width: 198px;
    padding: 9px 10px;
    text-align: center;
    border-radius: 5px;
    font-weight: 500;
    border: 1px solid #FFF; }
    .casinos-template .more-casino-btn:hover {
      color: #fff; }
  .casinos-template .list-casinos {
    padding: 0;
    list-style: none;
    margin: 15px 0;
    text-align: center; }
    .casinos-template .list-casinos li {
      border: 1px solid #d9e1e4;
      box-shadow: 1px 1px 12px #d9e1e4;
      padding: 7px 7px 10px;
      border-radius: 5px;
      max-width: 225px;
      position: relative;
      min-width: 225px;
      margin: 0 auto 11px;
      display: inline-block; }
      .casinos-template .list-casinos li .casino-rating {
        padding: 0 25px; }
      .casinos-template .list-casinos li .slot-img-over-block {
        border-bottom: 1px solid #d9e1e4;
        margin: -15px -7px 0;
        padding: 0 15px 8px; }
        .casinos-template .list-casinos li .slot-img-over-block img {
          margin: 15px auto 0;
          display: block; }
      .casinos-template .list-casinos li .casino-btn-ref {
        width: 88%;
        position: relative;
        margin: 13px auto 2px;
        display: block; }
      .casinos-template .list-casinos li .slot-title {
        font-weight: 500;
        padding: 10px 0;
        font-size: 22px;
        line-height: 20px;
        display: block;
        height: 31px;
        overflow: hidden;
        color: #29333e;
        text-align: center;
        margin: 15px -8px 0;
        border-top: 1px solid #d9e1e4; }
        .casinos-template .list-casinos li .slot-title:hover {
          text-decoration: none;
          color: #7a8c8c; }
      .casinos-template .list-casinos li .casino-win {
        border-bottom: 1px solid #d9e1e4;
        padding: 18px 15px 5px;
        font-size: 13px;
        text-align: center;
        margin: 0 -8px; }
      .casinos-template .list-casinos li .oversikt {
        display: inline-block;
        color: #52585d;
        margin: 10px 0 0; }
      .casinos-template .list-casinos li .casino-mode {
        text-align: center;
        position: relative;
        display: block;
        margin: 7px auto 0;
        vertical-align: middle; }
        .casinos-template .list-casinos li .casino-mode a {
          height: 33px;
          width: 32px;
          cursor: pointer;
          vertical-align: middle;
          display: inline-block; }
          .casinos-template .list-casinos li .casino-mode a i {
            display: block;
            margin: auto; }

/* top-rating-casinos */
.top-rating-casinos .slot-title {
  border: 0 !important;
  margin: 5px -8px 0 !important; }

.top-rating-casinos .casino-win {
  border: 0 !important; }

.top-rating-casinos li {
  padding: 7px 7px 50px !important; }
  .top-rating-casinos li .casino-btn-ref {
    width: 88%;
    margin: 13px auto 2px;
    display: block;
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 11px; }

/* best-rating-casino-block */
.best-rating-casino-block .promo-block-title {
  margin-bottom: -1px; }

.best-rating-casino-block .one-sb-casino-item {
  border-top: 1px solid #d9e1e4;
  padding: 0;
  height: auto;
  overflow: hidden; }
  .best-rating-casino-block .one-sb-casino-item:nth-child(odd) {
    background: #f7f7f7; }
  .best-rating-casino-block .one-sb-casino-item:hover {
    background: #f7f7f7; }
  .best-rating-casino-block .one-sb-casino-item .casino-logo {
    border-right: 1px solid #d9e1e4; }
    .best-rating-casino-block .one-sb-casino-item .casino-logo img {
      display: block;
      position: relative;
      margin: 5px auto;
      border-radius: 5px; }
  .best-rating-casino-block .one-sb-casino-item .casino-sb-rating-block {
    margin-left: -15px;
    padding: 0 10px; }
    .best-rating-casino-block .one-sb-casino-item .casino-sb-rating-block .casino-rating .progress-value {
      margin: 3px auto -10px; }
    .best-rating-casino-block .one-sb-casino-item .casino-sb-rating-block .casino-btn-ref {
      padding: 5px 0;
      width: 100%;
      margin: 0 0 5px;
      display: block;
      line-height: 18px; }

/* slots-template */
.slots-template .part-title {
  font-size: 32px;
  line-height: 32px;
  margin: 15px 0 0;
  text-align: center; }

.slots-template .best-rating-casino-block {
  margin: 0 -15px; }

/* list-slots */
.list-slots {
  text-align: center;
  list-style: none;
  padding: 0; }
  .list-slots li {
    border: 1px solid #d9e1e4;
    padding: 0 0 100px;
    border-radius: 5px;
    max-width: 100%;
    box-shadow: 1px 1px 12px #d9e1e4;
    position: relative;
    min-width: 100%;
    margin: 0 0 20px;
    overflow: hidden;
    cursor: pointer;
    display: inline-block; }
    .list-slots li img {
      width: 100%;
      display: block;
      position: relative; }
    .list-slots li .slot-title {
      font-weight: 300;
      padding: 10px 0;
      font-size: 22px;
      line-height: 20px;
      display: block;
      height: 31px;
      overflow: hidden;
      color: #29333e;
      text-align: center;
      margin: 5px 0 0;
      text-transform: capitalize; }
    .list-slots li .slot-software {
      padding: 5px 0;
      font-size: 15px;
      text-align: center;
      color: #70acf7; }
    .list-slots li .slot-btn-ref {
      margin: auto;
      width: 85%;
      position: absolute;
      bottom: 57px;
      left: 0;
      right: 0; }
    .list-slots li .slot-link {
      margin: auto;
      width: 85%;
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      color: #FFF;
      box-sizing: border-box;
      font-weight: 500;
      background: #70acf7; }
      .list-slots li .slot-link:hover {
        color: #FFF;
        background: #257ff0; }

/* page-numbs */
.page-numbs {
  text-align: center; }
  .page-numbs a {
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 32px;
    box-shadow: 1px 1px 12px #d9e1e4;
    font-weight: 500;
    text-align: center;
    color: #52585d;
    border-radius: 50%;
    border: 1px solid #d9e1e4 !important;
    border-color: #d9e1e4 !important; }
    .page-numbs a:hover {
      background: #247ff0;
      color: #FFF;
      border: 1px solid #247ff0;
      border-color: #247ff0 !important;
      box-shadow: 1px 1px 12px #247ff0;
      text-decoration: none; }
  .page-numbs span {
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 32px;
    box-shadow: 1px 1px 12px #247ff0;
    font-weight: 500 !important;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #247ff0 !important;
    border-color: #247ff0 !important;
    background: #247ff0;
    color: #FFF; }

/* list-news */
.list-news {
  list-style: none;
  padding: 0;
  text-align: center; }
  .list-news li {
    border: 1px solid #d9e1e4;
    padding: 0 0 30px;
    border-radius: 5px;
    max-width: 212px;
    box-shadow: 1px 1px 12px #d9e1e4;
    position: relative;
    min-width: 212px;
    margin: 0 0 20px;
    overflow: hidden;
    display: inline-block; }
    .list-news li img {
      display: block;
      margin: 15px auto;
      border-radius: 5px; }
    .list-news li .news-title {
      padding: 10px;
      font-size: 15px;
      line-height: 15px; }
    .list-news li .news-date {
      color: #e2d8cc;
      text-align: left;
      padding: 0 10px 10px;
      position: absolute;
      bottom: 0; }
  .list-news li.payment-system {
    padding: 0; }
    .list-news li.payment-system .news-title {
      margin-top: 15px !important;
      border-top: 1px solid #d9e1e4 !important; }

.category-content h1 {
  text-align: center;
  line-height: 40px;
  font-size: 38px; }

.news-template .best-rating-casino-block, .news-template .news-block, .news-template .new-games-block {
  margin: 0 -15px 15px; }

/* new-template */
.new-template .content img {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  width: 100%;
  display: block;
  margin: 10px auto; }

.new-template .best-rating-casino-block {
  margin: 0 -15px; }

/* breadcrumbs */
.breadcrumbs-bg {
  background: #fff; }

.breadcrumbs-block {
  padding: 10px 0;
  color: #818181;
  font-weight: 400; }
  .breadcrumbs-block a {
    color: #818181;
    text-decoration: underline; }
    .breadcrumbs-block a:hover {
      color: #70acf7; }
  .breadcrumbs-block span {
    color: #70acf7;
    text-decoration: none; }

/* 404 error */
.error-template .error-code {
  font-size: 180px;
  line-height: 250px;
  color: #ac3121;
  text-align: center; }

.error-template .error-text {
  text-align: center;
  padding: 15px;
  font-size: 22px;
  color: #7a8c8c; }

.error-template .casino-btn-ref {
  width: 150px;
  display: block;
  margin: 10px auto 100px; }

/* search-template */
.search-template .search-list {
  padding: 0;
  list-style: none;
  margin: 15px 0; }
  .search-template .search-list li {
    display: block;
    margin-bottom: 25px;
    border-bottom: 1px solid #d9e1e4; }
    .search-template .search-list li .title {
      font-size: 22px;
      color: #29333e;
      font-weight: 500;
      margin-bottom: 10px; }
    .search-template .search-list li .text {
      padding: 0 0 15px; }
    .search-template .search-list li .link {
      color: #52585d; }

.nothing-find {
  text-align: center; }

/* news-block */
.news {
  margin-bottom: 30px; }
  .news .one-new {
    height: 130px;
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid #d9e1e4;
    border-radius: 5px;
    position: relative;
    background: #FFF; }
    .news .one-new.opened {
      height: auto; }
      .news .one-new.opened .hidden-block-new {
        display: none; }
    .news .one-new .hidden-block-new {
      display: block;
      position: absolute;
      height: 50px;
      z-index: 100;
      width: 100%;
      bottom: 0;
      background: rgba(255, 255, 255, 0.75);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, white 100%);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, white 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, white 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, white 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, white 100%); }
      .news .one-new .hidden-block-new.no-active {
        display: none; }
    .news .one-new.wide {
      height: auto; }
      .news .one-new.wide .show-text {
        background: url(../img/up-rarr.png) right center no-repeat; }
    .news .one-new .hidden-block {
      height: 60px; }
    .news .one-new .new-description {
      padding: 10px 10px 25px; }
      .news .one-new .new-description img {
        float: left;
        margin-right: 10px;
        z-index: 100;
        position: relative;
        max-width: 100%;
        height: auto;
        border: none;
        border-radius: 5px; }
      .news .one-new .new-description .new-img {
        position: relative;
        z-index: 1000; }
      .news .one-new .new-description .new-title {
        color: #29333e;
        font-weight: 600;
        padding: 5px 0;
        font-size: 14px; }
      .news .one-new .new-description .new-content {
        font-size: 14px;
        line-height: 22px; }
    .news .one-new .show-text, .news .one-new .more-text {
      position: absolute;
      bottom: 3px;
      right: 10px;
      color: #2ac25e;
      font-size: 14px;
      font-weight: 600;
      padding: 0 20px 0 0;
      z-index: 100;
      cursor: pointer; }
      .news .one-new .show-text i, .news .one-new .more-text i {
        width: 18px;
        height: 10px;
        display: block;
        position: absolute;
        top: 7px;
        right: 0; }

/* my-comments-block */
.my-comments-block {
  padding: 25px 15px;
  margin: 30px 0 0; }
  .my-comments-block .one-comment-cnt {
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 10px 0 0; }
    .my-comments-block .one-comment-cnt.opened {
      height: auto; }
      .my-comments-block .one-comment-cnt.opened .hidden-block {
        display: none; }
    .my-comments-block .one-comment-cnt .comment-author {
      font-weight: 500; }
      .my-comments-block .one-comment-cnt .comment-author .comment-date {
        color: #52585d;
        margin-left: 15px; }
    .my-comments-block .one-comment-cnt .comment-text {
      padding: 5px 0;
      font-size: 14px; }
  .my-comments-block .more-comments a {
    color: #52585d; }

/* one casino */
.casino-review-template h1 {
  color: #29333e;
  margin-bottom: 0;
  text-align: center;
  font-weight: 600; }

.casino-review-template .casino-img-cnt {
  border: 1px solid #d9e1e4;
  box-shadow: 1px 1px 12px #d9e1e4;
  border-radius: 5px;
  margin: 15px auto 0;
  width: 100%;
  display: block;
  position: relative; }
  .casino-review-template .casino-img-cnt .casino-single-img {
    position: relative;
    display: block;
    margin: 15px auto; }

.casino-review-template .content .casino-big-btn {
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  font-size: 24px;
  line-height: 24px;
  padding: 5px 10px 10px;
  width: 100%;
  margin-top: 0;
  background: linear-gradient(0deg, #f6a237, #f8b746); }
  .casino-review-template .content .casino-big-btn:hover {
    background: linear-gradient(0deg, #f8b746, #f6a237); }

.casino-review-template .content h1 {
  color: #29333e;
  padding-right: 100px;
  margin-bottom: 10px; }

.casino-review-template .content .casino-big-btn {
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  line-height: 26px;
  padding: 5px;
  width: 100%;
  margin-top: 0;
  font-weight: 600; }

.casino-review-template .casino-desc-cnt {
  padding: 5px 0 15px; }
  .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt {
    display: block;
    position: relative;
    padding-bottom: 13px;
    height: auto; }
    .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table {
      display: table;
      width: 100%;
      margin: 10px auto; }
      .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block {
        display: table-cell;
        vertical-align: top;
        width: 50%; }
        .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block ul, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block ol, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ul, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ol {
          padding: 0;
          margin: 0;
          list-style: none !important; }
          .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block ul li, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block ol li, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ul li, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ol li {
            display: block;
            position: relative;
            font-size: 14px;
            padding: 0 5px 0 15px;
            line-height: 16px;
            margin-bottom: 5px; }
            .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block ul li:before, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-plus-block ol li:before, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ul li:before, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ol li:before {
              content: '+';
              left: 0;
              display: block;
              position: absolute;
              font-size: 23px;
              color: #dd0000;
              border: 0;
              top: -2px; }
      .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ul li:before, .casino-review-template .casino-desc-cnt .casino-plus-minus-cnt .casino-prop-table .casino-minus-block ol li:before {
        content: '-';
        color: #555555; }

.casino-review-template .casino-progress {
  width: 160px;
  display: block;
  position: relative;
  margin: 0 auto 5px;
  text-align: center; }

.casino-review-template .line {
  background: #d9e1e4;
  height: 1px; }

.casino-review-template .casino-left-properties-block {
  border-radius: 5px;
  height: auto;
  width: 100%;
  border: 1px solid #d9e1e4;
  box-shadow: 1px 1px 12px #d9e1e4;
  margin-top: 8px;
  background: #FFF; }
  .casino-review-template .casino-left-properties-block .properties-block {
    display: block;
    position: relative; }
    .casino-review-template .casino-left-properties-block .properties-block .properties-block-item, .casino-review-template .casino-left-properties-block .properties-block .properties-pass {
      border-bottom: 1px solid #d9e1e4;
      padding: 7px 30px 7px 14px;
      position: relative; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass i {
        display: block;
        position: absolute;
        top: 17px;
        right: 6px; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item i.icon-icon-down, .casino-review-template .casino-left-properties-block .properties-block .properties-pass i.icon-icon-down {
          cursor: pointer; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item span i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass span i {
        display: block;
        position: absolute;
        top: 9px;
        right: 6px; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item a i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass a i {
        display: block;
        position: absolute;
        top: 5px;
        right: 6px; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item.pass, .casino-review-template .casino-left-properties-block .properties-block .properties-pass.pass {
        background: none;
        cursor: default;
        border-bottom: 0;
        padding: 10px 30px 9px 14px; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .properties-block-item-hidden, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .properties-block-item-hidden {
        display: none;
        margin-top: 5px; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .properties-block-item-hidden.active, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .properties-block-item-hidden.active {
          display: block; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .casino-mode, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .casino-mode {
        display: inline-block;
        text-align: left; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .casino-mode span, .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .casino-mode a, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .casino-mode span, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .casino-mode a {
          height: 32px;
          width: 32px;
          vertical-align: middle;
          display: inline-block;
          position: relative; }
          .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .casino-mode span i, .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .casino-mode a i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .casino-mode span i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .casino-mode a i {
            display: block;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .char-flag, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .char-flag {
        width: 34px;
        height: 30px;
        vertical-align: middle;
        margin: 3px 0 3px -5px;
        display: inline-block;
        position: relative;
        border-right: 1px solid #d9e1e4; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .char-flag span, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .char-flag span {
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: default; }
          .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .char-flag span i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .char-flag span i {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .char-currency, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .char-currency {
        width: 34px;
        height: 30px;
        vertical-align: middle;
        margin: 3px 0 3px -5px;
        display: inline-block;
        position: relative;
        border-right: 1px solid #d9e1e4; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .char-currency span, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .char-currency span {
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: default; }
          .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .char-currency span i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .char-currency span i {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .payment-flag, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .payment-flag {
        width: 63px;
        height: 45px;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        border: 1px solid #d9e1e4;
        margin: 2px -1px 2px 0; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .payment-flag span, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .payment-flag span {
          width: 62px;
          height: 45px;
          display: block;
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: default; }
          .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .payment-flag span i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .payment-flag span i {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; }
        .casino-review-template .casino-left-properties-block .properties-block .properties-block-item .payment-flag a i, .casino-review-template .casino-left-properties-block .properties-block .properties-pass .payment-flag a i {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          cursor: pointer; }
      .casino-review-template .casino-left-properties-block .properties-block .properties-block-item span.title, .casino-review-template .casino-left-properties-block .properties-block .properties-pass span.title {
        font-size: 14px;
        border-right: 1px solid #d9e1e4;
        padding: 4px 10px 4px 0;
        color: #52585d; }
    .casino-review-template .casino-left-properties-block .properties-block .properties-block-item:last-child {
      border-bottom: 0;
      padding: 10px 30px 9px 14px; }
    .casino-review-template .casino-left-properties-block .properties-block .properties-block-item.payment-cnt {
      padding: 0 30px 1px 14px; }
  .casino-review-template .casino-left-properties-block .product-properties ul {
    list-style: none;
    margin: 0 auto -1px;
    padding: 0;
    border-radius: 5px;
    box-shadow: 1px 1px 12px #d9e1e4; }
    .casino-review-template .casino-left-properties-block .product-properties ul li {
      border-bottom: 1px solid #d9e1e4;
      padding: 10px 0 10px 40px;
      margin: 0;
      color: #52585d;
      position: relative;
      font-size: 14px;
      font-weight: 600; }
      .casino-review-template .casino-left-properties-block .product-properties ul li:last-child {
        border-bottom: 0; }
      .casino-review-template .casino-left-properties-block .product-properties ul li:hover, .casino-review-template .casino-left-properties-block .product-properties ul li:active {
        color: #1b8bf4;
        background: #f7f7f7; }
      .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block {
        display: none;
        border-top: 1px solid #d9e1e4;
        border-bottom: 1px solid #d9e1e4;
        border-left: 1px solid #d9e1e4;
        background: #FFF;
        position: absolute;
        width: 100%;
        left: -1px;
        z-index: 10;
        padding: 13px;
        margin-top: 9px;
        height: auto;
        max-height: 226px;
        overflow-y: scroll;
        right: -1px; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block span {
          display: block;
          color: #52585d;
          font-size: 12px;
          padding: 1px 0 1px 12px;
          position: relative; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block span i {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block span:before {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #52585d;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 10px; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block a {
          display: block;
          color: #52585d;
          font-size: 12px;
          padding: 1px 0 1px 12px;
          position: relative;
          cursor: pointer;
          text-decoration: underline; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block a i {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block a:before {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #52585d;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 10px; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block a:hover {
            text-decoration: none; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block.active {
          display: block; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .casino-mode {
          display: inline-block; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .casino-mode span {
            height: 35px;
            width: 32px;
            vertical-align: middle;
            display: inline-block; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .casino-mode span:before {
              display: none; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-flag {
          width: 34px;
          height: 30px;
          vertical-align: middle;
          margin: 3px 0 3px -5px;
          display: inline-block;
          position: relative;
          border-right: 1px solid #454b54; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-flag span {
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: default; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-flag span:before {
              display: none; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-flag a {
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-flag a:before {
              display: none; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-currency {
          width: 34px;
          height: 30px;
          vertical-align: middle;
          margin: 3px 0 3px 0;
          display: inline-block;
          position: relative;
          border-right: 1px solid #d9e1e4; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-currency span {
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: default; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-currency span:before {
              display: none; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-currency a {
            position: absolute;
            padding: 0;
            width: 24px;
            height: 20px;
            display: block;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-currency a:before {
              display: none; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .char-currency a i {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
              right: 0; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .payment-flag {
          width: 63px;
          height: 45px;
          vertical-align: middle;
          display: inline-block;
          position: relative;
          border: 1px solid #d9e1e4;
          margin: 2px -2px 2px 0; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .payment-flag span {
            width: 62px;
            height: 45px;
            display: block;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: default; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .payment-flag span:before {
              display: none; }
          .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .payment-flag a {
            position: relative;
            padding: 0; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .payment-flag a:before {
              display: none; }
            .casino-review-template .casino-left-properties-block .product-properties ul li .drop-block .payment-flag a i {
              position: relative;
              margin: auto;
              display: block; }
      .casino-review-template .casino-left-properties-block .product-properties ul li .icon-char {
        position: absolute;
        left: 8px;
        top: 11px;
        margin: auto; }
      .casino-review-template .casino-left-properties-block .product-properties ul li .icon {
        display: block;
        position: absolute;
        width: 32px;
        height: 34px;
        top: 5px;
        right: 0;
        cursor: pointer; }
        .casino-review-template .casino-left-properties-block .product-properties ul li .icon i {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto; }

.casino-review-template .casino-screens {
  margin: 15px 0;
  border: 1px solid #d9e1e4;
  box-shadow: 1px 1px 12px #d9e1e4;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  background: #FFF; }
  .casino-review-template .casino-screens img {
    border-radius: 5px;
    max-width: 100%;
    margin: 0 auto 15px;
    height: auto;
    width: auto;
    display: block;
    border: 1px solid #d9e1e4; }
  .casino-review-template .casino-screens .iframe {
    border-radius: 8px;
    width: auto;
    overflow: hidden;
    border: 1px solid #d9e1e4; }
    .casino-review-template .casino-screens .iframe iframe, .casino-review-template .casino-screens .iframe object {
      height: auto;
      border-radius: 5px;
      max-width: 100%; }

.casino-review-template .hidden-text {
  overflow: hidden; }

/* menu-link-go-to */
.fixed-cnt {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; }

.menu-link-go-to ul {
  padding: 0;
  margin: 15px 0 15px 1px; }
  .menu-link-go-to ul li {
    cursor: pointer;
    border: 1px solid #d9e1e4;
    margin: 0 -1px -1px;
    background: #fbfbfb;
    display: block;
    position: relative;
    width: 100%; }
    .menu-link-go-to ul li i {
      display: none;
      position: absolute;
      margin: -5px auto 0;
      left: 0;
      right: 0; }
    .menu-link-go-to ul li:hover {
      background: #FFF !important; }
      .menu-link-go-to ul li:hover i {
        display: block; }
    .menu-link-go-to ul li.active {
      border-radius: 5px 5px 0 0;
      overflow: hidden; }
      .menu-link-go-to ul li.active a {
        color: #1b8bf4;
        text-decoration: none;
        background: #FFF !important; }
      .menu-link-go-to ul li.active i {
        display: block; }
    .menu-link-go-to ul li a {
      display: block;
      padding: 10px;
      color: #1b8bf4;
      text-decoration: none;
      font-size: 13px;
      font-weight: 400; }
      .menu-link-go-to ul li a:hover {
        color: #1ac2c2;
        text-decoration: none; }

/* popup */
.popup-cont {
  width: 96%;
  height: auto;
  z-index: 100001;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  display: none;
  overflow: hidden; }

.popup-cont iframe, .popup-cont object {
  width: 350px;
  height: 265px;
  border: none;
  background: #000;
  display: block;
  margin: 15px auto 35px; }

.shadow {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;
  display: none; }

.button {
  outline: none;
  display: block;
  height: 80px;
  width: 90%;
  cursor: pointer;
  text-decoration: none !important;
  margin: 20px auto; }

.button .outer {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  background: black;
  box-shadow: inset rgba(0, 0, 0, 0.85) 0 1px 5px;
  transform: perspective(500px) rotateX(35deg); }

.button .outer .height {
  position: relative;
  height: 100%;
  margin-top: -25px;
  padding-bottom: 25px;
  background: #febf00;
  box-shadow: rgba(0, 0, 0, 0.85) 0 1px 1px, inset rgba(0, 0, 0, 0.35) 0 -2px 8px;
  transition: all .1s ease-in-out; }

.button:hover .outer .height {
  margin-top: -10px;
  padding-bottom: 10px;
  background: #20b553;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 1px, inset rgba(0, 0, 0, 0.35) 0 -2px 6px;
  text-decoration: none !important; }

.button:active .outer .height {
  margin-top: 0;
  padding-bottom: 0; }

.button .outer .height .inner {
  line-height: 68px;
  font-size: 30px;
  letter-spacing: .05em;
  position: relative;
  height: 100%;
  text-align: center;
  text-shadow: #000 0 0 1px;
  background: #febf00;
  background: linear-gradient(top, #febf00 0%, #20b553 100%);
  box-shadow: inset rgba(255, 255, 255, 0.85) 0 0 1px;
  transition: all .1s ease-in-out;
  color: #fff; }

.button:hover .outer .height .inner {
  text-shadow: #d9e1e4 0 0 1px;
  background: #20b553;
  background: linear-gradient(top, #20b553 0%, #febf00 100%);
  text-decoration: none; }

.button:active .outer .height .inner {
  text-shadow: #20b553 0 1px 0;
  box-shadow: inset rgba(0, 0, 0, 0.9) 0 0 8px;
  background: #20b553;
  background: linear-gradient(top, #20b553 0%, #febf00 100%);
  text-decoration: none; }

/* demo */
.slot-background {
  /*background: $slotBG;*/
  border-bottom: 1px solid #454b54; }
  .slot-background .category-content h1 {
    margin: 25px auto 10px;
    font-weight: 400;
    line-height: 24px;
    font-size: 24px; }

.demo-container {
  display: block;
  margin: 5px 0 0;
  width: 100%; }
  .demo-container .demo-left {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 25px; }
    .demo-container .demo-left .one-sb-casino-item {
      padding: 8px 0; }
    .demo-container .demo-left .promo-block-left {
      border: 0;
      box-shadow: none; }
  .demo-container .demo {
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 25px; }
    .demo-container .demo iframe, .demo-container .demo object {
      display: block;
      width: 100%;
      border-radius: 5px;
      background: #000;
      border: 0; }
    .demo-container .demo .gameItem {
      padding: 0;
      background: url(../img/slot-bg.jpg) 50% no-repeat;
      background-size: cover;
      height: 100%;
      display: block;
      width: 100%;
      position: relative;
      z-index: 3;
      border-radius: 5px; }
      .demo-container .demo .gameItem:hover {
        background: url(../img/slot-bg-hover.jpg) 50% no-repeat;
        background-size: cover; }
      .demo-container .demo .gameItem .play-big-slot-btn {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
    .demo-container .demo .bonus-btn-block {
      position: relative;
      margin-top: 20px;
      border-radius: 10px;
      overflow: hidden;
      height: 60px;
      padding: 10px 15px;
      box-sizing: border-box;
      background: url(../img/bonus-btn-bg.jpg) no-repeat top;
      background-size: 100% 100%;
      display: block; }
      .demo-container .demo .bonus-btn-block .demo-bonus-btn {
        display: block;
        width: 100%;
        height: 100%; }
        .demo-container .demo .bonus-btn-block .demo-bonus-btn .btn__left-block {
          display: inline-block;
          width: 32%; }
          .demo-container .demo .bonus-btn-block .demo-bonus-btn .btn__left-block .casino-name {
            position: relative;
            height: 20px;
            width: auto;
            color: #919497;
            font-size: 16px;
            padding: 0 0 0 23px;
            margin-top: -5px; }
          .demo-container .demo .bonus-btn-block .demo-bonus-btn .btn__left-block .bonus-size {
            position: relative;
            margin-top: 22px;
            color: #fff;
            font-size: 48px;
            font-weight: 600; }
        .demo-container .demo .bonus-btn-block .demo-bonus-btn .btn__right-block {
          float: right;
          width: 68%;
          height: 80px;
          line-height: 43px;
          font-size: 26px;
          font-weight: 400;
          color: #fff;
          text-align: center; }
  .demo-container .demo-right {
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 25px; }
    .demo-container .demo-right.promo-block-left {
      border: 0;
      box-shadow: none !important; }
    .demo-container .demo-right .char-block {
      display: block;
      position: relative; }
      .demo-container .demo-right .char-block .char-item {
        text-align: left;
        padding: 5px 15px;
        border-top: 1px solid #d9e1e4; }
        .demo-container .demo-right .char-block .char-item span {
          text-align: left;
          width: 65%;
          display: inline-block;
          font-size: 14px;
          line-height: 16px;
          color: #53595e;
          font-weight: 700;
          vertical-align: middle; }
        .demo-container .demo-right .char-block .char-item span:nth-child(1) {
          color: #818181;
          font-weight: 500; }
        .demo-container .demo-right .char-block .char-item span:nth-child(2) {
          width: 32%; }
        .demo-container .demo-right .char-block .char-item:nth-child(even) {
          background: #f7f7f7; }

/* END demo */
/* comments */
.comments-bg-container {
  background: #f5f5f5; }
  .comments-bg-container .comments-template-block .comment-reply-title, .comments-bg-container .comments-template-block .logged-in-as, .comments-bg-container .comments-template-block .comment-form-url, .comments-bg-container .comments-template-block .comment-notes {
    display: none; }
  .comments-bg-container .comments-template-block .comment-form-author, .comments-bg-container .comments-template-block .comment-form-comment, .comments-bg-container .comments-template-block .comment-form-email {
    background: #FFF;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 12px #d9e1e4;
    margin: 15px 0; }
  .comments-bg-container .comments-template-block .comment-form-comment {
    margin: 0; }
  .comments-bg-container .comments-template-block label {
    color: #29333e;
    display: block;
    font-size: 14px;
    font-weight: 600; }
  .comments-bg-container .comments-template-block input[type=email], .comments-bg-container .comments-template-block input[type=text], .comments-bg-container .comments-template-block textarea {
    border-radius: 5px;
    background: #fbfbfb;
    border: 1px solid #d0d0d0;
    font-size: 14px;
    padding: 5px;
    width: 100%; }
    .comments-bg-container .comments-template-block input[type=email]:focus, .comments-bg-container .comments-template-block input[type=text]:focus, .comments-bg-container .comments-template-block textarea:focus {
      border-color: #b7afaf !important; }
  .comments-bg-container .comments-template-block input[type=submit] {
    background: #febf00;
    border: 0;
    color: #fff;
    padding: 10px;
    width: 220px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer; }
  .comments-bg-container .comments-cnt .one-comment-cnt {
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 10px 0 0; }
    .comments-bg-container .comments-cnt .one-comment-cnt.opened {
      height: auto; }
      .comments-bg-container .comments-cnt .one-comment-cnt.opened .hidden-block {
        display: none; }
      .comments-bg-container .comments-cnt .one-comment-cnt.opened .comment-text {
        height: auto; }
    .comments-bg-container .comments-cnt .one-comment-cnt .comment-author {
      font-weight: 600; }
      .comments-bg-container .comments-cnt .one-comment-cnt .comment-author .comment-date {
        color: #1b8bf4;
        position: absolute;
        right: 10px; }
    .comments-bg-container .comments-cnt .one-comment-cnt .comment-text {
      padding: 5px 0;
      font-size: 14px;
      height: 100px; }
    .comments-bg-container .comments-cnt .one-comment-cnt .btn-show-comment {
      display: block;
      position: absolute;
      right: 15px;
      cursor: pointer;
      bottom: -5px;
      z-index: 10;
      color: #2ac25e;
      font-size: 14px;
      font-weight: 600; }
    .comments-bg-container .comments-cnt .one-comment-cnt .show-text, .comments-bg-container .comments-cnt .one-comment-cnt .more-text {
      position: absolute;
      bottom: -1px;
      right: 10px;
      color: #1b8bf4;
      font-size: 14px;
      padding: 0 20px 0 0;
      z-index: 100;
      cursor: pointer; }
      .comments-bg-container .comments-cnt .one-comment-cnt .show-text i, .comments-bg-container .comments-cnt .one-comment-cnt .more-text i {
        width: 18px;
        height: 10px;
        display: block;
        position: absolute;
        top: 7px;
        right: 0; }
      .comments-bg-container .comments-cnt .one-comment-cnt .show-text:hover, .comments-bg-container .comments-cnt .one-comment-cnt .show-text:active, .comments-bg-container .comments-cnt .one-comment-cnt .more-text:hover, .comments-bg-container .comments-cnt .one-comment-cnt .more-text:active {
        color: #60acf3; }
    .comments-bg-container .comments-cnt .one-comment-cnt:hover {
      color: #52585d; }

/* END comments */
/* developers */
.sorting {
  padding: 18px 15px;
  border: 1px solid #d9e1e4;
  box-shadow: 1px 1px 12px #d9e1e4;
  display: block;
  margin: 0 -25px;
  color: #818181;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  position: relative; }
  .sorting .icon .icon-icon-down {
    display: block;
    position: absolute;
    width: 20px;
    height: 18px;
    top: 0;
    right: 10px;
    cursor: pointer;
    bottom: 0;
    margin: auto; }
  .sorting.active {
    background: #fbfbfb;
    color: #1b8bf4; }

.sorting_panel, .sorting_panel_dev, .sorting_panel_payment {
  display: none;
  background: #70acf7;
  padding: 15px;
  border: 1px solid #d9e1e4;
  text-align: center;
  margin: 0px -26px;
  position: relative; }
  .sorting_panel.active, .sorting_panel_dev.active, .sorting_panel_payment.active {
    display: block; }
  .sorting_panel a, .sorting_panel_dev a, .sorting_panel_payment a {
    display: inline-block;
    color: #FFF !important;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    margin: 0 15px 0 0;
    font-size: 16px; }
    .sorting_panel a i, .sorting_panel_dev a i, .sorting_panel_payment a i {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto; }
    .sorting_panel a.sort-item-asc, .sorting_panel_dev a.sort-item-asc, .sorting_panel_payment a.sort-item-asc {
      padding: 9px 9px 7px 0; }
    .sorting_panel a.sort-item-desc, .sorting_panel_dev a.sort-item-desc, .sorting_panel_payment a.sort-item-desc {
      padding: 9px 9px 7px 0; }
    .sorting_panel a:hover, .sorting_panel_dev a:hover, .sorting_panel_payment a:hover {
      text-decoration: underline !important; }

.list-developers li .slot-img-over-block {
  margin: -7px !important;
  border-bottom: 0 !important; }
  .list-developers li .slot-img-over-block img {
    border-radius: 5px; }

.list-developers li .slot-title {
  position: relative;
  text-align: left !important;
  padding: 10px 45px 10px 13px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  height: 38px !important;
  margin: 15px -8px -5px !important;
  line-height: 23px !important; }
  .list-developers li .slot-title span {
    display: block;
    position: absolute;
    right: 7px;
    top: 6px;
    padding: 2px 9px;
    border-radius: 5px;
    background: #6daaf7;
    font-weight: 300;
    color: #fff; }

/* END developers */
/* all-bonuses-list */
.all-bonuses-list li {
  padding: 0 0 15px; }
  .all-bonuses-list li .typ-bonus {
    margin: 12px 0 15px;
    font-size: 13px;
    text-align: center;
    height: 20px;
    overflow: hidden; }
  .all-bonuses-list li .bonus_img_block {
    padding: 0 17px 15px;
    border-bottom: 1px solid #d9e1e4;
    margin-bottom: 15px; }
    .all-bonuses-list li .bonus_img_block img {
      border-radius: 5px;
      max-width: 100%; }
  .all-bonuses-list li .news-title {
    text-align: center;
    display: block;
    font-size: 20px;
    border: 0; }
    .all-bonuses-list li .news-title:hover {
      text-decoration: none;
      color: #53595e; }
  .all-bonuses-list li .bonus-text {
    font-size: 13px;
    line-height: 22px;
    padding: 5px 15px 0;
    text-align: center; }
  .all-bonuses-list li .bonus-btn-block {
    text-align: center;
    padding: 15px 15px 0; }
    .all-bonuses-list li .bonus-btn-block .btn {
      width: 100%; }

.html h2, .html h3, .html h4, .hljs h2, .hljs h3, .hljs h4, .xml h2, .xml h3, .xml h4 {
  color: #8f8f8f;
  text-transform: uppercase; }

.html a:hover, .hljs a:hover, .xml a:hover {
  color: #fff; }

.html p, .hljs p, .xml p {
  color: #8f8f8f; }

.play-btn-container {
  padding: 20px; }

@media screen and (max-width: 414px) {
  .casinos-template .top-rating-casinos li {
    max-width: 100%;
    min-width: 100%;
    width: 100%; }
  .casinos-template .list-casinos li {
    min-width: 100%;
    max-width: 100%; }
  .front-slots-cnt .slots-block ul li {
    min-width: 100%;
    max-width: 100%; }
  .list-news li {
    max-width: 100%;
    min-width: 100%; } }

.how_many {
  text-transform: uppercase;
  position: relative;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  height: 22px;
  font-size: 18px;
  display: block;
  text-align: center; }

.casinos-technical-template .list-casinos li {
  border: 1px solid #d9e1e4;
  box-shadow: 1px 1px 12px #d9e1e4;
  padding: 7px 7px 10px;
  border-radius: 5px;
  max-width: 100%;
  position: relative;
  min-width: 100%;
  margin: 0 0 15px; }

.casinos-technical-template .promo-block-left {
  border-radius: 0;
  margin: 0 -10px 0; }

.casinos-technical-template .promotion-block {
  margin: 5px -10px 0;
  margin: 0 -10px; }

.is_technical .list-technical-link {
  margin: 15px auto;
  padding: 0; }
  .is_technical .list-technical-link li {
    display: inline-block;
    list-style: none; }
    .is_technical .list-technical-link li a {
      padding: 5px 4px 5px 0;
      font-size: 14px;
      line-height: 14px; }
      .is_technical .list-technical-link li a:hover {
        color: #ffc462; }

.is_technical .promo-block-left {
  border-radius: 0;
  margin: 0 -10px; }

.is_technical .promotion-block {
  border-radius: 0;
  margin: 0 -10px; }

.icon-icon-top, .icon-iconUp {
  background-image: url(sprite.png); }

.icon-iconUp {
  background-position: 0 -41px;
  width: 62px;
  height: 62px; }

.to_top {
  display: none;
  position: fixed;
  z-index: 4;
  cursor: pointer;
  bottom: 20px;
  height: 53px;
  width: 60px;
  right: 10px; }

.to_top.display {
  display: block; }

/**
 ** bonus slider styles
 */
.icon-svg {
  display: block;
  max-width: 65%;
  margin: 0 auto;
  height: 44px;
  fill: #fff; }

.new-bonuses-cnt {
  display: block;
  position: relative;
  margin: 15px 0; }

.fade-slider-container {
  display: block;
  position: relative;
  margin-bottom: 15px; }

.bonus-kinds-cnt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid #d9e1e4;
  border-radius: 5px 5px 0 0;
  overflow: hidden; }

.one-kind {
  display: inline-block;
  background: #fbfbfb;
  border-right: 1px solid #d9e1e4;
  border-left: 1px solid #d9e1e4;
  width: 10%;
  height: 92px;
  cursor: pointer; }

.one-kind.active, .one-kind.super-active {
  background: #fff; }
  .one-kind.active .kind-icon, .one-kind.super-active .kind-icon {
    background: #fff; }
    .one-kind.active .kind-icon .icon-svg, .one-kind.super-active .kind-icon .icon-svg {
      fill: #52585d; }
  .one-kind.active .kind-text, .one-kind.super-active .kind-text {
    color: #52585d; }

.one-kind:first-child {
  border-left: 0;
  border-radius: 3px 0 0 0; }

.one-kind:last-child {
  border-right: 0;
  margin-right: -1px;
  border-radius: 0 3px 0 0; }

.kind-icon {
  display: block;
  width: 50px;
  height: 50px;
  background: #52585d;
  border-radius: 50%;
  margin: 7px auto 0;
  padding: 3px; }

.one-kind.no-active {
  cursor: default; }
  .one-kind.no-active .kind-icon {
    background: #d0d0d0; }
  .one-kind.no-active .kind-text {
    color: #d0d0d0; }

.kind-icon img {
  display: block;
  max-width: 65%;
  margin: 0 auto;
  height: 40px; }

.kind-text {
  text-align: center;
  color: #1b8bf4;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  padding: 5px 4px; }

.bonus-kind-item-slider-cnt {
  border: 1px solid #d9e1e4;
  border-radius: 3px 3px 0 0;
  padding: 0 65px;
  margin-top: -1px;
  display: none;
  position: relative; }

.bonus-kind-item-slider-cnt.no-active {
  display: none; }

.bonus-slider-block {
  width: 75%; }

.bonus-slider {
  height: 240px;
  overflow: hidden; }

.slider-item {
  position: relative;
  width: 786px !important; }

.bonus-img-cnt {
  width: 23%;
  position: relative; }

.img-block {
  width: 190px;
  height: 190px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.bonus-img-cnt img {
  display: block;
  margin: auto; }

.bonus-desc {
  width: 530px;
  padding: 20px 30px;
  float: left;
  display: block; }

.bonus-text-preview {
  font-size: 16px;
  padding-right: 15px; }

.bonus-title {
  color: #52585d;
  font-size: 30px;
  line-height: 35px;
  margin: 0 0 20px;
  font-weight: 700; }

.slider-item-btn-cnt {
  width: 209px;
  display: block;
  float: left;
  position: relative;
  height: 100px;
  margin: 73px 20px; }

.slider-item-btn-cnt .d-bonus-btn {
  width: 90%;
  padding: 22px 10px;
  font-size: 27px;
  margin: 90px auto; }

.slider-item-btn-cnt a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100; }

.slider-item-btn-cnt iframe {
  max-width: 210px;
  pointer-events: none;
  position: relative;
  z-index: 10;
  height: 100px; }

.bonus-slider-block .bx-controls {
  text-align: center;
  margin-left: -23%; }

.bonus-slider-block .bx-viewport {
  height: 220px !important; }

.fade-slider-wrapper-block {
  display: block;
  position: relative; }

.left-rarr {
  width: 32px;
  position: absolute;
  background: #fbfbfb;
  left: 1px;
  color: transparent;
  text-indent: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  border-top: 1px solid #d9e1e4;
  border-bottom: 1px solid #d9e1e4; }
  .left-rarr i {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }

.left-rarr:hover,
.right-rarr:hover {
  background-color: #fff; }

.right-rarr {
  width: 32px;
  position: absolute;
  background: #fbfbfb;
  right: 1px;
  color: transparent;
  text-indent: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  border-top: 1px solid #d9e1e4;
  border-bottom: 1px solid #d9e1e4; }
  .right-rarr i {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }

.one-kind-slider.active {
  display: flex;
  position: relative; }

.one-slide {
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear; }

.one-slide.active {
  -webkit-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  transition: opacity 1s linear;
  opacity: 1; }

.paging {
  text-align: center;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 10px auto;
  bottom: -5px; }

.paging span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #70acf7;
  cursor: pointer;
  margin: 2px 0; }

.paging span.active {
  background: #d0d0d0; }

/**
Mobile bonuses
 */
.mobile-bonuses {
  display: block;
  position: relative; }

.mobile-bonuses .new-bonuses-cnt {
  margin: 7px -30px; }

.mobile-bonuses .bonus-kinds-cnt {
  display: block;
  align-items: inherit;
  justify-content: inherit;
  flex-direction: inherit;
  width: 1060px;
  border-radius: 0; }

.mobile-bonuses .one-kind {
  display: inline-block;
  width: 108px;
  margin: 0 -3px;
  vertical-align: top;
  height: 106px;
  border-radius: 0; }

.mobile-bonuses .scroll-cnt {
  max-width: 100%;
  overflow-x: scroll; }

.mobile-bonuses .bonus-kind-item-slider-cnt {
  border: none;
  padding: 0 20px;
  margin-top: -1px;
  display: none; }

.mobile-bonuses .bonus-kind-item-slider-cnt.active {
  display: block !important; }

.mobile-bonuses .bonus-kind-item-slider-cnt.no-active {
  display: none; }

.mobile-bonuses .bonus-img-cnt {
  width: 100%;
  display: block;
  margin: 15px auto; }

.mobile-bonuses .img-block {
  position: relative;
  width: 150px;
  height: 150px; }

.mobile-bonuses .bonus-slider-block {
  width: 100%;
  border-bottom: 1px solid #d9e1e4; }

.mobile-bonuses .bonus-slider {
  height: 500px;
  min-height: 100%;
  overflow: inherit;
  display: block;
  position: relative; }

.mobile-bonuses .bonus-slider-block .bx-viewport {
  height: 540px !important;
  min-height: 100%; }

.mobile-bonuses .slider-item {
  width: auto !important; }

.mobile-bonuses .bonus-slider-block .bx-controls {
  margin-left: 0; }

.mobile-bonuses .bonus-desc {
  width: auto;
  padding: 20px 30px 0;
  float: none;
  display: block;
  text-align: center; }

.mobile-bonuses .slider-item-btn-cnt {
  float: none;
  padding: 0;
  margin: 15px auto;
  width: 210px; }

.mobile-bonuses .bonus-title {
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  max-height: 103px;
  overflow: hidden; }

.mobile-bonuses .img-block iframe {
  width: 150px;
  height: 150px; }

.icon-svg {
  display: block;
  max-width: 65%;
  margin: 0 auto;
  fill: #fff; }

.mobile-bonuses .kind-text {
  padding: 3px 5px; }

.mobile-bonuses .bonus-text-preview {
  font-size: 14px;
  padding-right: 0;
  max-height: 100px;
  overflow: hidden; }
