/* main var */
$basicFontColor: #52585d;
$bgODDColor: #f4f7f8;
$basicBG: #FFF;
$blocksBG: #FFF;
$screenBG: #FFF;
$blocksSbBg: #424242;
$backgroundSlot: #424242;

/* weight */
$lightWeight: 300;
$mediumWeight: 500;
$mediumBoldWeight: 600;
$boldWeight: 700;

/* border */
$borderColor: #d9e1e4;
$shadowColor: #d9e1e4;

/* titles */
$titleColor: #29333e;
$titleBlockColor: #53595e;

/* contents block bg */
$contentBlockBg: #f7f7f7;

/* btn */
$btnBg: #febf00;
$btnBgTop: #febf00;
$btnBgBottom: #fc8801;

$btnBgHover: #20b553;
$btnTextColor: #fff;
$btnLink: #e36384;
$btnHoverLink: #ac3121;
$btnColor: #fff;

/* rating */
$ratingColor: #29333e;
$ratingSpanColor: #c8c8c8;

/* read more btn*/
$readMoreColor: #437155;
$readMoreSpanColor: #437155;
$readMoreColorHover: #ac3121;
$readMoreSpanColorHover: #ac3121;

/* tabs */
$tableBG: #fff;
$tabsBg: #fbfbfb;
$tabHoverBg: #FFF;
$tabTableCasinoItemHover: #f7f7f7;
$titleTabColor: #1b8bf4;
$titleTabColorHover: #818181;
$titleTabBorderColor: #d0d0d0;

/* tabs sort panel*/
$sortPanelBg: #6fabf7;
$sortItemTitle: #fff;
$sortItemFontSize: 14px;

/* Promocje */
$sbCasinoItemHoverBG: #6daaf7;
$sbCasinoItemHoverColor: #FFFFFF;

/* header */
$headerBg: #f5f5f5;
$headerMenuLinkColor: #fcfaf7;
$headerMenuLinkBorderColor: #454b54;
$searchFormBg: #2ac25e;
$languageBlockBg: #313840;
$headerLine: #f7f7f7;

/* header menu */
$menuLinkColor: #d9e1e4;
$menuLinkHoverColor: #ffc462;

/* front page */
$loadMoreInTabBg: #e3e3e3;
$loadMoreInTabHoverBg: #FFF;
$loadMoreInTabColor: #29333e;
$loadMoreInTabHoverColor: #FFF;
$frontSlotsBg: #fc8f01;

/* link */
$linkColor: #52585d;
$contentLinkColor: #8f8f8f;

/* slots */
$titleFrontSlotsCnt: #FFF;
$filterItemColor: #fff;
$filterItemHoverColor: #ac3121;
$filterItemHoverBg: #fff;

$oneSlotBg: #FFF;
$oneSlotTitle: #434343;

/* Nowe kasyna */
$oneNewsItemBG: #424242;
$oneNewsItemHoverBG: #fbfbfb;

/* footer */
$footerBeforeBg: #e3e3e3;
$footerBg: #e3e3e3;
$footerLinksColor: #1b8bf4;
$footerLinksHoverColor: #60acf3;
$footerTitleColor: #434343;

/* copy-rights */
$copyRights: #ababab;

/* casino list */
$casinoListItemTitle: #29333e;
$casinoListItemHover: #7a8c8c;

/* more casino btn */
$moreCasinoBTNBorderColor: #FFF;
$moreCasinoBTNColor: #FFF;
$moreCasinoBTNBGHover: #257ff0;
$moreCasinoBTNBG: #70acf7;
$moreCasinoBTNColorHover: #FFF;

/* news */
$newsDateColor: #e2d8cc;
$oneNewBG: #FFF;

/* breadcrumbs */
$breadcrumbsBG: #fff;
$breadcrumbsLinkColor: #818181;
$breadcrumbsLinkHoverColor: #70acf7;
$breadcrumbsSpanColor: #70acf7;

/* 404 error */
$errorCodeColor: #ac3121;
$errorTextColor: #7a8c8c;

/* drop menu */
$dropMenuBgColor: #f7f7f7;
$dropMenuLinkColor: #f4f4f4;
$dropMenuLinkFontSize: 14px;

/* slot */
$slotBG: linear-gradient(to top,#05070a,#192231);
$slotTitle: #29333e;

/* comments */
$commentsBgContainerBlock: #FFF;
$commentsBgContainer: #f5f5f5;
$commentsBorderContainerColor: #d0d0d0;
$commentsBorderContainerShadow: #d0d0d0;
$commentsBorder: #d0d0d0;
$commentsBgField: #fbfbfb;

/* sorting */
$sortingPanelBG: #70acf7;
$sortingLinkColor: #FFF;

/* paging */
$pageBg: #424242;

/* sidebar */
$sidebarLinkColor: #818181;

%flex-row-wrap{
    display: flex;
    //justify-content: space-between;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    flex-flow: row wrap;
}


/* header top bg */
$direction: top;
$fromColor: #05070a;
$toColor: #192231;
@mixin linear-gradient($direction, $fromColor, $toColor) {
    background-color: $toColor;
    background-image: -webkit-gradient(linear, $direction, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient($direction, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient($direction, $fromColor, $toColor); /* FF3.6 */
    background-image: -ms-linear-gradient($direction, $fromColor, $toColor); /* IE10 */
    background-image: -o-linear-gradient($direction, $fromColor, $toColor); /* Opera 11.10+ */
    background-image: linear-gradient($direction, $fromColor, $toColor);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

/*fade slider */
$kindIconBg: #52585d;

/* read more */
$readMoreColor: #1b8bf4;
$readMoreHoverColor: #60acf3;

/* page */
$pageNumberBg: #FFF;
$pageNumberBgHover: #247ff0;
$pageBorderColor: #247ff0;
$pageBorderColorHover: #d9e1e4;
$pageShadowColor: #247ff0;
$pageNumberColor: #247ff0;
$pageNumberColorHover: #FFF;