/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-1-Pay {
  background-image: url(sprite.png);
  background-position: -67px -123px;
  width: 52px;
  height: 39px;
}
.icon-18-dmca-gpwa-icons {
  background-image: url(sprite.png);
  background-position: 0px 0px;
  width: 185px;
  height: 21px;
}
.icon-21GNET {
  background-image: url(sprite.png);
  background-position: -576px -1311px;
  width: 52px;
  height: 39px;
}
.icon-440-Cash {
  background-image: url(sprite.png);
  background-position: -504px -1370px;
  width: 52px;
  height: 39px;
}
.icon-4B {
  background-image: url(sprite.png);
  background-position: -1429px -885px;
  width: 52px;
  height: 39px;
}
.icon-7Eleven {
  background-image: url(sprite.png);
  background-position: -205px 0px;
  width: 52px;
  height: 39px;
}
.icon-900-Pay-by-Neteller {
  background-image: url(sprite.png);
  background-position: -205px -59px;
  width: 52px;
  height: 39px;
}
.icon-900Pay-by-Navaho {
  background-image: url(sprite.png);
  background-position: -205px -118px;
  width: 52px;
  height: 39px;
}
.icon-ACH {
  background-image: url(sprite.png);
  background-position: -144px -190px;
  width: 52px;
  height: 39px;
}
.icon-AGMO {
  background-image: url(sprite.png);
  background-position: -277px -177px;
  width: 52px;
  height: 39px;
}
.icon-AKbank {
  background-image: url(sprite.png);
  background-position: -72px -249px;
  width: 52px;
  height: 39px;
}
.icon-AMRO {
  background-image: url(sprite.png);
  background-position: -421px -118px;
  width: 52px;
  height: 39px;
}
.icon-ATM-Online {
  background-image: url(sprite.png);
  background-position: -288px -426px;
  width: 52px;
  height: 39px;
}
.icon-ATM {
  background-image: url(sprite.png);
  background-position: -360px -426px;
  width: 52px;
  height: 39px;
}
.icon-Abaqoos {
  background-image: url(sprite.png);
  background-position: 0px -190px;
  width: 52px;
  height: 39px;
}
.icon-Account-to-Account-transfer {
  background-image: url(sprite.png);
  background-position: -72px -190px;
  width: 52px;
  height: 39px;
}
.icon-Affiliate-Program {
  background-image: url(sprite.png);
  background-position: -1080px -1075px;
  width: 22px;
  height: 22px;
}
.icon-Affiliate-programs {
  background-image: url(sprite.png);
  background-position: -1501px -294px;
  width: 22px;
  height: 22px;
}
.icon-Agencies-IziPlay {
  background-image: url(sprite.png);
  background-position: -277px -118px;
  width: 52px;
  height: 39px;
}
.icon-AirPay {
  background-image: url(sprite.png);
  background-position: 0px -249px;
  width: 52px;
  height: 39px;
}
.icon-Aktia-Bank {
  background-image: url(sprite.png);
  background-position: -144px -249px;
  width: 52px;
  height: 39px;
}
.icon-Aktia {
  background-image: url(sprite.png);
  background-position: -216px -249px;
  width: 52px;
  height: 39px;
}
.icon-Aland-Islands {
  background-image: url(sprite.png);
  background-position: -1480px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Albanian-lekë {
  background-image: url(sprite.png);
  background-position: -840px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Albanian {
  background-image: url(sprite.png);
  background-position: -480px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Alfa-Bank {
  background-image: url(sprite.png);
  background-position: -349px -177px;
  width: 52px;
  height: 39px;
}
.icon-Alfa-Click {
  background-image: url(sprite.png);
  background-position: -349px -236px;
  width: 52px;
  height: 39px;
}
.icon-Algerian-dinars {
  background-image: url(sprite.png);
  background-position: -1663px -640px;
  width: 20px;
  height: 20px;
}
.icon-AloGateway {
  background-image: url(sprite.png);
  background-position: -144px -308px;
  width: 52px;
  height: 39px;
}
.icon-Amazon-gift-card {
  background-image: url(sprite.png);
  background-position: -216px -308px;
  width: 52px;
  height: 39px;
}
.icon-American-Express {
  background-image: url(sprite.png);
  background-position: -421px 0px;
  width: 52px;
  height: 39px;
}
.icon-Amigotechs {
  background-image: url(sprite.png);
  background-position: -421px -59px;
  width: 52px;
  height: 39px;
}
.icon-Angolan-kwanzas {
  background-image: url(sprite.png);
  background-position: -1663px -480px;
  width: 20px;
  height: 20px;
}
.icon-Arabic {
  background-image: url(sprite.png);
  background-position: -1663px -360px;
  width: 20px;
  height: 20px;
}
.icon-Arena-Wallet {
  background-image: url(sprite.png);
  background-position: -72px -367px;
  width: 52px;
  height: 39px;
}
.icon-Argentine pesos {
  background-image: url(sprite.png);
  background-position: -1663px -320px;
  width: 20px;
  height: 20px;
}
.icon-Argentine-pesos {
  background-image: url(sprite.png);
  background-position: -1663px -280px;
  width: 20px;
  height: 20px;
}
.icon-Armenian-drams {
  background-image: url(sprite.png);
  background-position: -1663px -240px;
  width: 20px;
  height: 20px;
}
.icon-Asia-Local-Bank {
  background-image: url(sprite.png);
  background-position: 0px -426px;
  width: 52px;
  height: 39px;
}
.icon-Asipay8 {
  background-image: url(sprite.png);
  background-position: -72px -426px;
  width: 52px;
  height: 39px;
}
.icon-AstroPay-Card {
  background-image: url(sprite.png);
  background-position: -144px -426px;
  width: 52px;
  height: 39px;
}
.icon-AstroPay-Direct {
  background-image: url(sprite.png);
  background-position: -216px -426px;
  width: 52px;
  height: 39px;
}
.icon-Australian dollars {
  background-image: url(sprite.png);
  background-position: -1663px -40px;
  width: 20px;
  height: 20px;
}
.icon-Australian-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -493px -118px;
  width: 52px;
  height: 39px;
}
.icon-Australian-dollars {
  background-image: url(sprite.png);
  background-position: -1663px 0px;
  width: 20px;
  height: 20px;
}
.icon-Austrian-German {
  background-image: url(sprite.png);
  background-position: -1600px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Autoplay-Option {
  background-image: url(sprite.png);
  background-position: -1501px 0px;
  width: 22px;
  height: 22px;
}
.icon-Azerbaijani-manats {
  background-image: url(sprite.png);
  background-position: -1480px -1608px;
  width: 20px;
  height: 20px;
}
.icon-BACS {
  background-image: url(sprite.png);
  background-position: -144px -485px;
  width: 52px;
  height: 39px;
}
.icon-BLESK-peněženka {
  background-image: url(sprite.png);
  background-position: -637px -413px;
  width: 52px;
  height: 39px;
}
.icon-BPay {
  background-image: url(sprite.png);
  background-position: -709px -177px;
  width: 52px;
  height: 39px;
}
.icon-BT-bill {
  background-image: url(sprite.png);
  background-position: -144px -662px;
  width: 52px;
  height: 39px;
}
.icon-Baccarat {
  background-image: url(sprite.png);
  background-position: -432px -426px;
  width: 28px;
  height: 28px;
}
.icon-Bahraini-dinars {
  background-image: url(sprite.png);
  background-position: -1120px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Baloto {
  background-image: url(sprite.png);
  background-position: -288px -485px;
  width: 52px;
  height: 39px;
}
.icon-Bancontact-Mister-Cash {
  background-image: url(sprite.png);
  background-position: -432px -485px;
  width: 52px;
  height: 39px;
}
.icon-Bangladeshi-takas {
  background-image: url(sprite.png);
  background-position: -880px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Bank-Dependent {
  background-image: url(sprite.png);
  background-position: -565px -59px;
  width: 52px;
  height: 39px;
}
.icon-Bank-Draft {
  background-image: url(sprite.png);
  background-position: -565px -177px;
  width: 52px;
  height: 39px;
}
.icon-Bank-Pekao {
  background-image: url(sprite.png);
  background-position: -565px -236px;
  width: 52px;
  height: 39px;
}
.icon-Bank-Transfer-Express {
  background-image: url(sprite.png);
  background-position: -565px -295px;
  width: 52px;
  height: 39px;
}
.icon-Bank-Wire-Transfer {
  background-image: url(sprite.png);
  background-position: -565px -413px;
  width: 52px;
  height: 39px;
}
.icon-Bank-transfer {
  background-image: url(sprite.png);
  background-position: -565px -354px;
  width: 52px;
  height: 39px;
}
.icon-BankLink {
  background-image: url(sprite.png);
  background-position: -565px -472px;
  width: 52px;
  height: 39px;
}
.icon-Barcode-Santander {
  background-image: url(sprite.png);
  background-position: 0px -544px;
  width: 52px;
  height: 39px;
}
.icon-Beeline {
  background-image: url(sprite.png);
  background-position: -216px -544px;
  width: 52px;
  height: 39px;
}
.icon-Belarusian-rubles {
  background-image: url(sprite.png);
  background-position: -760px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Belarusian {
  background-image: url(sprite.png);
  background-position: -720px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Betfred-Shops {
  background-image: url(sprite.png);
  background-position: -432px -544px;
  width: 52px;
  height: 39px;
}
.icon-Betting-Voucher {
  background-image: url(sprite.png);
  background-position: -504px -544px;
  width: 52px;
  height: 39px;
}
.icon-Billingpartner {
  background-image: url(sprite.png);
  background-position: -637px -236px;
  width: 52px;
  height: 39px;
}
.icon-Bitcoin {
  background-image: url(sprite.png);
  background-position: -637px -295px;
  width: 52px;
  height: 39px;
}
.icon-Blackjack {
  background-image: url(sprite.png);
  background-position: -360px -308px;
  width: 28px;
  height: 28px;
}
.icon-Blueprint-Gaming {
  background-image: url(sprite.png);
  background-position: -637px -472px;
  width: 52px;
  height: 39px;
}
.icon-Boku {
  background-image: url(sprite.png);
  background-position: -72px -603px;
  width: 52px;
  height: 39px;
}
.icon-Boleto-by-Neteller {
  background-image: url(sprite.png);
  background-position: -144px -603px;
  width: 52px;
  height: 39px;
}
.icon-Boleto {
  background-image: url(sprite.png);
  background-position: -216px -603px;
  width: 52px;
  height: 39px;
}
.icon-Bolivian-bolivianos {
  background-image: url(sprite.png);
  background-position: -1623px -1520px;
  width: 20px;
  height: 20px;
}
.icon-Bonifico {
  background-image: url(sprite.png);
  background-position: -360px -603px;
  width: 52px;
  height: 39px;
}
.icon-Bonus-Game {
  background-image: url(sprite.png);
  background-position: -1501px -1050px;
  width: 22px;
  height: 22px;
}
.icon-Bonus-Value {
  background-image: url(sprite.png);
  background-position: -1501px -1008px;
  width: 22px;
  height: 22px;
}
.icon-Book-to-book {
  background-image: url(sprite.png);
  background-position: -709px 0px;
  width: 52px;
  height: 39px;
}
.icon-Bosnia-Herzegovina-convertible-marks {
  background-image: url(sprite.png);
  background-position: -1623px -1080px;
  width: 20px;
  height: 20px;
}
.icon-Botswanan-pulas {
  background-image: url(sprite.png);
  background-position: -1623px -1040px;
  width: 20px;
  height: 20px;
}
.icon-Bradesco {
  background-image: url(sprite.png);
  background-position: -709px -236px;
  width: 52px;
  height: 39px;
}
.icon-Brazil-Visa-credit-card {
  background-image: url(sprite.png);
  background-position: -709px -354px;
  width: 52px;
  height: 39px;
}
.icon-Brazil-credit-card {
  background-image: url(sprite.png);
  background-position: -709px -295px;
  width: 52px;
  height: 39px;
}
.icon-Brazilian-reals {
  background-image: url(sprite.png);
  background-position: -1623px -1000px;
  width: 20px;
  height: 20px;
}
.icon-British pounds sterling {
  background-image: url(sprite.png);
  background-position: -1623px -960px;
  width: 20px;
  height: 20px;
}
.icon-British-pounds-sterling {
  background-image: url(sprite.png);
  background-position: -1623px -920px;
  width: 20px;
  height: 20px;
}
.icon-Brunei dollars {
  background-image: url(sprite.png);
  background-position: -1623px -760px;
  width: 20px;
  height: 20px;
}
.icon-Brunei-dollars {
  background-image: url(sprite.png);
  background-position: -1623px -720px;
  width: 20px;
  height: 20px;
}
.icon-Bulgarian-leva {
  background-image: url(sprite.png);
  background-position: -1623px -480px;
  width: 20px;
  height: 20px;
}
.icon-Bulgarian {
  background-image: url(sprite.png);
  background-position: -1623px -440px;
  width: 20px;
  height: 20px;
}
.icon-Burundian-francs {
  background-image: url(sprite.png);
  background-position: -1623px -400px;
  width: 20px;
  height: 20px;
}
.icon-C24 {
  background-image: url(sprite.png);
  background-position: 0px -721px;
  width: 52px;
  height: 39px;
}
.icon-CADEFT {
  background-image: url(sprite.png);
  background-position: -216px -721px;
  width: 52px;
  height: 39px;
}
.icon-CDS {
  background-image: url(sprite.png);
  background-position: -144px -780px;
  width: 52px;
  height: 39px;
}
.icon-CEP-Bank {
  background-image: url(sprite.png);
  background-position: -288px -780px;
  width: 52px;
  height: 39px;
}
.icon-CFT-Card-Funds-Transfer {
  background-image: url(sprite.png);
  background-position: -360px -780px;
  width: 52px;
  height: 39px;
}
.icon-CIMB-Bank {
  background-image: url(sprite.png);
  background-position: -853px -354px;
  width: 52px;
  height: 39px;
}
.icon-CLICK2PAY {
  background-image: url(sprite.png);
  background-position: -853px -649px;
  width: 52px;
  height: 39px;
}
.icon-CWC-Gaming {
  background-image: url(sprite.png);
  background-position: -925px -708px;
  width: 52px;
  height: 39px;
}
.icon-Canadian dollars {
  background-image: url(sprite.png);
  background-position: -1623px -160px;
  width: 20px;
  height: 20px;
}
.icon-Canadian-dollars {
  background-image: url(sprite.png);
  background-position: -1623px -120px;
  width: 20px;
  height: 20px;
}
.icon-Canadian {
  background-image: url(sprite.png);
  background-position: -1623px -40px;
  width: 20px;
  height: 20px;
}
.icon-Cardpay {
  background-image: url(sprite.png);
  background-position: -504px -721px;
  width: 52px;
  height: 39px;
}
.icon-CartaSi-by-Skrill {
  background-image: url(sprite.png);
  background-position: -576px -721px;
  width: 52px;
  height: 39px;
}
.icon-CartaSi {
  background-image: url(sprite.png);
  background-position: -648px -721px;
  width: 52px;
  height: 39px;
}
.icon-Carte-Bleue {
  background-image: url(sprite.png);
  background-position: -781px 0px;
  width: 52px;
  height: 39px;
}
.icon-Cash-Transfer {
  background-image: url(sprite.png);
  background-position: -781px -59px;
  width: 52px;
  height: 39px;
}
.icon-CashU {
  background-image: url(sprite.png);
  background-position: -781px -295px;
  width: 52px;
  height: 39px;
}
.icon-Cashable {
  background-image: url(sprite.png);
  background-position: -1501px -966px;
  width: 22px;
  height: 22px;
}
.icon-Cashiers-Check {
  background-image: url(sprite.png);
  background-position: -781px -177px;
  width: 52px;
  height: 39px;
}
.icon-Cashlib {
  background-image: url(sprite.png);
  background-position: -781px -236px;
  width: 52px;
  height: 39px;
}
.icon-Cashup {
  background-image: url(sprite.png);
  background-position: -781px -354px;
  width: 52px;
  height: 39px;
}
.icon-Casino-Owner {
  background-image: url(sprite.png);
  background-position: -1501px -924px;
  width: 22px;
  height: 22px;
}
.icon-Casino-type {
  background-image: url(sprite.png);
  background-position: -1501px -882px;
  width: 22px;
  height: 22px;
}
.icon-Casino {
  background-image: url(sprite.png);
  background-position: -1501px -840px;
  width: 22px;
  height: 22px;
}
.icon-Catalan {
  background-image: url(sprite.png);
  background-position: -1080px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Central-Coin {
  background-image: url(sprite.png);
  background-position: -216px -780px;
  width: 52px;
  height: 39px;
}
.icon-Check {
  background-image: url(sprite.png);
  background-position: -432px -780px;
  width: 52px;
  height: 39px;
}
.icon-Checks-by-Mail {
  background-image: url(sprite.png);
  background-position: -504px -780px;
  width: 52px;
  height: 39px;
}
.icon-Cheque {
  background-image: url(sprite.png);
  background-position: -576px -780px;
  width: 52px;
  height: 39px;
}
.icon-Chilean pesos {
  background-image: url(sprite.png);
  background-position: -800px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Chilean-pesos {
  background-image: url(sprite.png);
  background-position: -760px -1568px;
  width: 20px;
  height: 20px;
}
.icon-China-online-payment {
  background-image: url(sprite.png);
  background-position: -853px -59px;
  width: 52px;
  height: 39px;
}
.icon-Chinese-Renminbi {
  background-image: url(sprite.png);
  background-position: -720px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Chinese-yuan {
  background-image: url(sprite.png);
  background-position: -640px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Chinese {
  background-image: url(sprite.png);
  background-position: -600px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Citadel-Commerce {
  background-image: url(sprite.png);
  background-position: -853px -413px;
  width: 52px;
  height: 39px;
}
.icon-Citadel-Direct {
  background-image: url(sprite.png);
  background-position: -853px -472px;
  width: 52px;
  height: 39px;
}
.icon-Citadel-Internet-Bank {
  background-image: url(sprite.png);
  background-position: -853px -531px;
  width: 52px;
  height: 39px;
}
.icon-Citadel {
  background-image: url(sprite.png);
  background-position: -853px -590px;
  width: 52px;
  height: 39px;
}
.icon-ClickDirectPay {
  background-image: url(sprite.png);
  background-position: -853px -767px;
  width: 52px;
  height: 39px;
}
.icon-ClickandBuy {
  background-image: url(sprite.png);
  background-position: -853px -708px;
  width: 52px;
  height: 39px;
}
.icon-Colombian pesos {
  background-image: url(sprite.png);
  background-position: -480px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Colombian-pesos {
  background-image: url(sprite.png);
  background-position: -440px -1568px;
  width: 20px;
  height: 20px;
}
.icon-ComGate {
  background-image: url(sprite.png);
  background-position: -360px -839px;
  width: 52px;
  height: 39px;
}
.icon-Comepay {
  background-image: url(sprite.png);
  background-position: -288px -839px;
  width: 52px;
  height: 39px;
}
.icon-Congolese-francs {
  background-image: url(sprite.png);
  background-position: -240px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Contact {
  background-image: url(sprite.png);
  background-position: -504px -839px;
  width: 52px;
  height: 39px;
}
.icon-Costa-Rica {
  background-image: url(sprite.png);
  background-position: -160px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Costa-Rican-colóns {
  background-image: url(sprite.png);
  background-position: -80px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Coupon {
  background-image: url(sprite.png);
  background-position: -792px -839px;
  width: 52px;
  height: 39px;
}
.icon-Courier-Check {
  background-image: url(sprite.png);
  background-position: -925px 0px;
  width: 52px;
  height: 39px;
}
.icon-Credit-Card {
  background-image: url(sprite.png);
  background-position: -925px -177px;
  width: 52px;
  height: 39px;
}
.icon-Credit-Cards {
  background-image: url(sprite.png);
  background-position: -925px -236px;
  width: 52px;
  height: 39px;
}
.icon-Croatian-kunas {
  background-image: url(sprite.png);
  background-position: -1583px -1240px;
  width: 20px;
  height: 20px;
}
.icon-Croatian {
  background-image: url(sprite.png);
  background-position: -1583px -1200px;
  width: 20px;
  height: 20px;
}
.icon-Cubits {
  background-image: url(sprite.png);
  background-position: -925px -472px;
  width: 52px;
  height: 39px;
}
.icon-Cuenta-Digital {
  background-image: url(sprite.png);
  background-position: -925px -531px;
  width: 52px;
  height: 39px;
}
.icon-Currencies {
  background-image: url(sprite.png);
  background-position: -1501px -546px;
  width: 22px;
  height: 22px;
}
.icon-Custom-Software {
  background-image: url(sprite.png);
  background-position: -925px -649px;
  width: 52px;
  height: 39px;
}
.icon-Czech-Republic-korunas {
  background-image: url(sprite.png);
  background-position: -1583px -880px;
  width: 20px;
  height: 20px;
}
.icon-Czech {
  background-image: url(sprite.png);
  background-position: -1583px -840px;
  width: 20px;
  height: 20px;
}
.icon-Danish {
  background-image: url(sprite.png);
  background-position: -1583px -600px;
  width: 20px;
  height: 20px;
}
.icon-Dankort {
  background-image: url(sprite.png);
  background-position: -216px -898px;
  width: 52px;
  height: 39px;
}
.icon-Danske-Bank {
  background-image: url(sprite.png);
  background-position: -288px -898px;
  width: 52px;
  height: 39px;
}
.icon-Dash {
  background-image: url(sprite.png);
  background-position: -432px -898px;
  width: 52px;
  height: 39px;
}
.icon-Debit-Card {
  background-image: url(sprite.png);
  background-position: -576px -898px;
  width: 52px;
  height: 39px;
}
.icon-Delta {
  background-image: url(sprite.png);
  background-position: -648px -898px;
  width: 52px;
  height: 39px;
}
.icon-Denmark-kroner {
  background-image: url(sprite.png);
  background-position: -1583px -480px;
  width: 20px;
  height: 20px;
}
.icon-Deposit-methods {
  background-image: url(sprite.png);
  background-position: -1501px -462px;
  width: 22px;
  height: 22px;
}
.icon-DineroMail {
  background-image: url(sprite.png);
  background-position: -864px -898px;
  width: 52px;
  height: 39px;
}
.icon-Diners-Club-International-by-Skrill {
  background-image: url(sprite.png);
  background-position: -997px 0px;
  width: 52px;
  height: 39px;
}
.icon-Diners-Club-International {
  background-image: url(sprite.png);
  background-position: -997px -59px;
  width: 52px;
  height: 39px;
}
.icon-Diners {
  background-image: url(sprite.png);
  background-position: -997px -118px;
  width: 52px;
  height: 39px;
}
.icon-Direct-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -997px -236px;
  width: 52px;
  height: 39px;
}
.icon-Direct-Cash {
  background-image: url(sprite.png);
  background-position: -997px -295px;
  width: 52px;
  height: 39px;
}
.icon-Direct-Money {
  background-image: url(sprite.png);
  background-position: -997px -413px;
  width: 52px;
  height: 39px;
}
.icon-Direct-bank-transfer-by-Skrill {
  background-image: url(sprite.png);
  background-position: -997px -177px;
  width: 52px;
  height: 39px;
}
.icon-Direct-ebanking {
  background-image: url(sprite.png);
  background-position: -997px -354px;
  width: 52px;
  height: 39px;
}
.icon-Discover {
  background-image: url(sprite.png);
  background-position: -997px -472px;
  width: 52px;
  height: 39px;
}
.icon-Dogecoin {
  background-image: url(sprite.png);
  background-position: -997px -708px;
  width: 52px;
  height: 39px;
}
.icon-Download {
  background-image: url(sprite.png);
  background-position: -792px -780px;
  width: 22px;
  height: 24px;
}
.icon-Dragonfish-Random-Logic {
  background-image: url(sprite.png);
  background-position: 0px -957px;
  width: 52px;
  height: 39px;
}
.icon-Dutch {
  background-image: url(sprite.png);
  background-position: -1120px -1528px;
  width: 20px;
  height: 20px;
}
.icon-E-Pro-e-Vouchers {
  background-image: url(sprite.png);
  background-position: -288px -957px;
  width: 52px;
  height: 39px;
}
.icon-EC-ELV {
  background-image: url(sprite.png);
  background-position: -504px -957px;
  width: 52px;
  height: 39px;
}
.icon-ECO-Card {
  background-image: url(sprite.png);
  background-position: -792px -957px;
  width: 52px;
  height: 39px;
}
.icon-ECOBANk {
  background-image: url(sprite.png);
  background-position: -864px -957px;
  width: 52px;
  height: 39px;
}
.icon-ECash-Direct {
  background-image: url(sprite.png);
  background-position: -648px -957px;
  width: 52px;
  height: 39px;
}
.icon-ELV-Elektronisches-Lastschriftverfahren {
  background-image: url(sprite.png);
  background-position: -1069px -590px;
  width: 52px;
  height: 39px;
}
.icon-EMO---Electronic-Money-Order {
  background-image: url(sprite.png);
  background-position: -1069px -649px;
  width: 52px;
  height: 39px;
}
.icon-EMO-Electronic-Money-Order {
  background-image: url(sprite.png);
  background-position: -1069px -708px;
  width: 52px;
  height: 39px;
}
.icon-ENets-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1069px -885px;
  width: 52px;
  height: 39px;
}
.icon-EPRO {
  background-image: url(sprite.png);
  background-position: -576px -1016px;
  width: 52px;
  height: 39px;
}
.icon-EPS {
  background-image: url(sprite.png);
  background-position: -648px -1016px;
  width: 52px;
  height: 39px;
}
.icon-ETH {
  background-image: url(sprite.png);
  background-position: 0px -1075px;
  width: 52px;
  height: 39px;
}
.icon-EZ-Voucher {
  background-image: url(sprite.png);
  background-position: -1141px -177px;
  width: 52px;
  height: 39px;
}
.icon-EZIPay {
  background-image: url(sprite.png);
  background-position: -1141px -236px;
  width: 52px;
  height: 39px;
}
.icon-Easy-EFT {
  background-image: url(sprite.png);
  background-position: -360px -957px;
  width: 52px;
  height: 39px;
}
.icon-EasyPay {
  background-image: url(sprite.png);
  background-position: -432px -957px;
  width: 52px;
  height: 39px;
}
.icon-EcoPay {
  background-image: url(sprite.png);
  background-position: -936px -957px;
  width: 52px;
  height: 39px;
}
.icon-EcoPayz {
  background-image: url(sprite.png);
  background-position: -1069px 0px;
  width: 52px;
  height: 39px;
}
.icon-Egyptian-pounds {
  background-image: url(sprite.png);
  background-position: -960px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Elba {
  background-image: url(sprite.png);
  background-position: -1069px -354px;
  width: 52px;
  height: 39px;
}
.icon-Electracade {
  background-image: url(sprite.png);
  background-position: -1069px -413px;
  width: 52px;
  height: 39px;
}
.icon-Electron {
  background-image: url(sprite.png);
  background-position: -1069px -472px;
  width: 52px;
  height: 39px;
}
.icon-Electronic-Transfer {
  background-image: url(sprite.png);
  background-position: -1069px -531px;
  width: 52px;
  height: 39px;
}
.icon-English {
  background-image: url(sprite.png);
  background-position: -680px -1528px;
  width: 20px;
  height: 20px;
}
.icon-EnterCash {
  background-image: url(sprite.png);
  background-position: -72px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Entropay {
  background-image: url(sprite.png);
  background-position: -144px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Envoy {
  background-image: url(sprite.png);
  background-position: -216px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Estonian-Kroon {
  background-image: url(sprite.png);
  background-position: -560px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Estonian {
  background-image: url(sprite.png);
  background-position: -520px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Ethiopian-birrs {
  background-image: url(sprite.png);
  background-position: -360px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Euro-Card {
  background-image: url(sprite.png);
  background-position: -216px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Euro6000 {
  background-image: url(sprite.png);
  background-position: -288px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Euros {
  background-image: url(sprite.png);
  background-position: -280px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Euroset {
  background-image: url(sprite.png);
  background-position: -432px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Eurp-Standard-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -504px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Euteller {
  background-image: url(sprite.png);
  background-position: -576px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Evroset {
  background-image: url(sprite.png);
  background-position: -648px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Exclusive {
  background-image: url(sprite.png);
  background-position: -1501px -42px;
  width: 22px;
  height: 22px;
}
.icon-Express-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -936px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Express-Merchants {
  background-image: url(sprite.png);
  background-position: -1008px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Express-Wallet {
  background-image: url(sprite.png);
  background-position: -1141px 0px;
  width: 52px;
  height: 39px;
}
.icon-Eyecon {
  background-image: url(sprite.png);
  background-position: -1141px -118px;
  width: 52px;
  height: 39px;
}
.icon-Ezugi {
  background-image: url(sprite.png);
  background-position: -1141px -295px;
  width: 52px;
  height: 39px;
}
.icon-Farsi {
  background-image: url(sprite.png);
  background-position: -160px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Fast-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -1141px -413px;
  width: 52px;
  height: 39px;
}
.icon-Fast-Pay {
  background-image: url(sprite.png);
  background-position: -1141px -472px;
  width: 52px;
  height: 39px;
}
.icon-Fast-Wire {
  background-image: url(sprite.png);
  background-position: -1141px -531px;
  width: 52px;
  height: 39px;
}
.icon-Fedex-Checks {
  background-image: url(sprite.png);
  background-position: -1141px -590px;
  width: 52px;
  height: 39px;
}
.icon-Finnish-Online-Banking {
  background-image: url(sprite.png);
  background-position: -1141px -767px;
  width: 52px;
  height: 39px;
}
.icon-Finnish-ebank {
  background-image: url(sprite.png);
  background-position: -1141px -708px;
  width: 52px;
  height: 39px;
}
.icon-Finnish {
  background-image: url(sprite.png);
  background-position: -40px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Fio-Banka {
  background-image: url(sprite.png);
  background-position: -1141px -885px;
  width: 52px;
  height: 39px;
}
.icon-Firepay {
  background-image: url(sprite.png);
  background-position: -1141px -944px;
  width: 52px;
  height: 39px;
}
.icon-Flexepin {
  background-image: url(sprite.png);
  background-position: -1141px -1003px;
  width: 52px;
  height: 39px;
}
.icon-Free-Spins {
  background-image: url(sprite.png);
  background-position: -1368px -1370px;
  width: 22px;
  height: 22px;
}
.icon-French {
  background-image: url(sprite.png);
  background-position: -1543px -1360px;
  width: 20px;
  height: 20px;
}
.icon-FundSend {
  background-image: url(sprite.png);
  background-position: -216px -1134px;
  width: 52px;
  height: 39px;
}
.icon-GCNL-icon4 {
  background-image: url(sprite.png);
  background-position: -139px -123px;
  width: 43px;
  height: 27px;
}
.icon-GCNL-license {
  background-image: url(sprite.png);
  background-position: -576px -544px;
  width: 29px;
  height: 26px;
}
.icon-GCNL-mobile {
  background-image: url(sprite.png);
  background-position: -162px -41px;
  width: 19px;
  height: 29px;
}
.icon-GCNL-star {
  background-image: url(sprite.png);
  background-position: -216px -190px;
  width: 30px;
  height: 30px;
}
.icon-GTECH-G2 {
  background-image: url(sprite.png);
  background-position: -1213px -708px;
  width: 52px;
  height: 39px;
}
.icon-Game-RTP {
  background-image: url(sprite.png);
  background-position: -1224px -1193px;
  width: 22px;
  height: 22px;
}
.icon-Gaming-Right-Media {
  background-image: url(sprite.png);
  background-position: -504px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Garanti {
  background-image: url(sprite.png);
  background-position: -576px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Georgian-laris {
  background-image: url(sprite.png);
  background-position: -1543px -720px;
  width: 20px;
  height: 20px;
}
.icon-Georgian {
  background-image: url(sprite.png);
  background-position: -1543px -640px;
  width: 20px;
  height: 20px;
}
.icon-German {
  background-image: url(sprite.png);
  background-position: -1543px -600px;
  width: 20px;
  height: 20px;
}
.icon-Ghanaian-cedis {
  background-image: url(sprite.png);
  background-position: -1543px -560px;
  width: 20px;
  height: 20px;
}
.icon-GiroPay-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1213px -236px;
  width: 52px;
  height: 39px;
}
.icon-GiroPay {
  background-image: url(sprite.png);
  background-position: -1213px -295px;
  width: 52px;
  height: 39px;
}
.icon-Global-EcoCard {
  background-image: url(sprite.png);
  background-position: -1213px -354px;
  width: 52px;
  height: 39px;
}
.icon-Gluepay {
  background-image: url(sprite.png);
  background-position: -1213px -413px;
  width: 52px;
  height: 39px;
}
.icon-GoCash88 {
  background-image: url(sprite.png);
  background-position: -1213px -472px;
  width: 52px;
  height: 39px;
}
.icon-Golden-Palace-Gaming-Hall {
  background-image: url(sprite.png);
  background-position: -1213px -531px;
  width: 52px;
  height: 39px;
}
.icon-Grand-Virtual {
  background-image: url(sprite.png);
  background-position: -1213px -590px;
  width: 52px;
  height: 39px;
}
.icon-Greek {
  background-image: url(sprite.png);
  background-position: -1543px -480px;
  width: 20px;
  height: 20px;
}
.icon-HSBC {
  background-image: url(sprite.png);
  background-position: -576px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Hal-Cash {
  background-image: url(sprite.png);
  background-position: -1213px -767px;
  width: 52px;
  height: 39px;
}
.icon-Hall-Cash {
  background-image: url(sprite.png);
  background-position: -1213px -826px;
  width: 52px;
  height: 39px;
}
.icon-Handelsbanken {
  background-image: url(sprite.png);
  background-position: -1213px -885px;
  width: 52px;
  height: 39px;
}
.icon-Hebrew {
  background-image: url(sprite.png);
  background-position: -1543px -400px;
  width: 20px;
  height: 20px;
}
.icon-Help2Pay {
  background-image: url(sprite.png);
  background-position: -1213px -1062px;
  width: 52px;
  height: 39px;
}
.icon-Hendelsbanken {
  background-image: url(sprite.png);
  background-position: -1213px -1121px;
  width: 52px;
  height: 39px;
}
.icon-High5Games {
  background-image: url(sprite.png);
  background-position: -72px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Hindi {
  background-image: url(sprite.png);
  background-position: -1543px -320px;
  width: 20px;
  height: 20px;
}
.icon-Hipay {
  background-image: url(sprite.png);
  background-position: -216px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Hong-Kong-dollars {
  background-image: url(sprite.png);
  background-position: -1543px -240px;
  width: 20px;
  height: 20px;
}
.icon-Hungarian-forints {
  background-image: url(sprite.png);
  background-position: -1543px 0px;
  width: 20px;
  height: 20px;
}
.icon-Hungarian {
  background-image: url(sprite.png);
  background-position: -1480px -1488px;
  width: 20px;
  height: 20px;
}
.icon-ING {
  background-image: url(sprite.png);
  background-position: -1285px -885px;
  width: 52px;
  height: 39px;
}
.icon-Icelandic-krónur {
  background-image: url(sprite.png);
  background-position: -1440px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Icelandic {
  background-image: url(sprite.png);
  background-position: -1400px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Indian-rupees {
  background-image: url(sprite.png);
  background-position: -1000px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Indonesian-rupiahs {
  background-image: url(sprite.png);
  background-position: -960px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Indonesian {
  background-image: url(sprite.png);
  background-position: -920px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Instabet {
  background-image: url(sprite.png);
  background-position: -1285px -1062px;
  width: 52px;
  height: 39px;
}
.icon-Instant-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: 0px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-Bank {
  background-image: url(sprite.png);
  background-position: -72px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-Banking {
  background-image: url(sprite.png);
  background-position: -144px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-Debit {
  background-image: url(sprite.png);
  background-position: -216px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-EFT {
  background-image: url(sprite.png);
  background-position: -288px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-ETF {
  background-image: url(sprite.png);
  background-position: -360px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-Online-Check {
  background-image: url(sprite.png);
  background-position: -432px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Instant-Play {
  background-image: url(sprite.png);
  background-position: -334px -1429px;
  width: 24px;
  height: 19px;
}
.icon-Instant-Wire-Transfer {
  background-image: url(sprite.png);
  background-position: -576px -1252px;
  width: 52px;
  height: 39px;
}
.icon-InstantTransfer {
  background-image: url(sprite.png);
  background-position: -648px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Inteligo {
  background-image: url(sprite.png);
  background-position: -720px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Intercash {
  background-image: url(sprite.png);
  background-position: -792px -1252px;
  width: 52px;
  height: 39px;
}
.icon-International-Bank-transfer {
  background-image: url(sprite.png);
  background-position: -936px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Internet-Banking {
  background-image: url(sprite.png);
  background-position: -1008px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Internet-Cash {
  background-image: url(sprite.png);
  background-position: -1080px -1252px;
  width: 52px;
  height: 39px;
}
.icon-Iranian-rials {
  background-image: url(sprite.png);
  background-position: -760px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Iraqi-dinars {
  background-image: url(sprite.png);
  background-position: -720px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Irish {
  background-image: url(sprite.png);
  background-position: -680px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Israel {
  background-image: url(sprite.png);
  background-position: -520px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Israeli-new-sheqels {
  background-image: url(sprite.png);
  background-position: -480px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Italian {
  background-image: url(sprite.png);
  background-position: -400px -1488px;
  width: 20px;
  height: 20px;
}
.icon-JCB {
  background-image: url(sprite.png);
  background-position: -1357px -944px;
  width: 52px;
  height: 39px;
}
.icon-Jackpot {
  background-image: url(sprite.png);
  background-position: -1501px -420px;
  width: 22px;
  height: 22px;
}
.icon-Jadestone {
  background-image: url(sprite.png);
  background-position: -1357px -708px;
  width: 52px;
  height: 39px;
}
.icon-Japanese yen {
  background-image: url(sprite.png);
  background-position: -200px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Japanese-yen {
  background-image: url(sprite.png);
  background-position: -160px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Japanese {
  background-image: url(sprite.png);
  background-position: -120px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Jordanian-dinars {
  background-image: url(sprite.png);
  background-position: -1501px -1340px;
  width: 20px;
  height: 20px;
}
.icon-Kalibra-Card {
  background-image: url(sprite.png);
  background-position: -1357px -1239px;
  width: 52px;
  height: 39px;
}
.icon-Kalixa {
  background-image: url(sprite.png);
  background-position: 0px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Kazakhstani-tenges {
  background-image: url(sprite.png);
  background-position: -1623px -1120px;
  width: 20px;
  height: 20px;
}
.icon-Kenyan-shillings {
  background-image: url(sprite.png);
  background-position: -1623px 0px;
  width: 20px;
  height: 20px;
}
.icon-Korean {
  background-image: url(sprite.png);
  background-position: -320px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Kuwaiti-dinars {
  background-image: url(sprite.png);
  background-position: -1583px -960px;
  width: 20px;
  height: 20px;
}
.icon-LAVA-pay {
  background-image: url(sprite.png);
  background-position: -1152px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Languages {
  background-image: url(sprite.png);
  background-position: -1501px -252px;
  width: 22px;
  height: 22px;
}
.icon-Laser-by-Skrill {
  background-image: url(sprite.png);
  background-position: -792px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Laser {
  background-image: url(sprite.png);
  background-position: -864px -1311px;
  width: 52px;
  height: 39px;
}
.icon-LatamBilling {
  background-image: url(sprite.png);
  background-position: -936px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Latvian-lati {
  background-image: url(sprite.png);
  background-position: -1320px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Latvian {
  background-image: url(sprite.png);
  background-position: -1280px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Liberty-Reserve {
  background-image: url(sprite.png);
  background-position: -1296px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Licences {
  background-image: url(sprite.png);
  background-position: -1501px -126px;
  width: 22px;
  height: 22px;
}
.icon-LifeCell {
  background-image: url(sprite.png);
  background-position: -72px -1370px;
  width: 52px;
  height: 39px;
}
.icon-LiqPay {
  background-image: url(sprite.png);
  background-position: -216px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Litecoin {
  background-image: url(sprite.png);
  background-position: -288px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Lithuanian-litai {
  background-image: url(sprite.png);
  background-position: -1160px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Lithuanian {
  background-image: url(sprite.png);
  background-position: -400px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Lobanet {
  background-image: url(sprite.png);
  background-position: -576px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Local-Bank-In {
  background-image: url(sprite.png);
  background-position: -648px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Local-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -720px -1370px;
  width: 52px;
  height: 39px;
}
.icon-LocalFast-Bank-Transfers {
  background-image: url(sprite.png);
  background-position: -792px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Local_Fast-Bank-Transfers {
  background-image: url(sprite.png);
  background-position: -864px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Lottomaticard {
  background-image: url(sprite.png);
  background-position: -936px -1370px;
  width: 52px;
  height: 39px;
}
.icon-MST-Gift-Card {
  background-image: url(sprite.png);
  background-position: 0px -1370px;
  width: 52px;
  height: 39px;
}
.icon-MTC {
  background-image: url(sprite.png);
  background-position: -1224px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Macedonian-denari {
  background-image: url(sprite.png);
  background-position: -1543px -1000px;
  width: 20px;
  height: 20px;
}
.icon-Maestro-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1429px -118px;
  width: 52px;
  height: 39px;
}
.icon-Maestro {
  background-image: url(sprite.png);
  background-position: -1429px -177px;
  width: 52px;
  height: 39px;
}
.icon-Malaysian-ringgits {
  background-image: url(sprite.png);
  background-position: -1543px -800px;
  width: 20px;
  height: 20px;
}
.icon-Malaysian {
  background-image: url(sprite.png);
  background-position: -1543px -40px;
  width: 20px;
  height: 20px;
}
.icon-MasterCard {
  background-image: url(sprite.png);
  background-position: -1429px -472px;
  width: 52px;
  height: 39px;
}
.icon-Mastercard-by-Neteller {
  background-image: url(sprite.png);
  background-position: -1429px -354px;
  width: 52px;
  height: 39px;
}
.icon-Mastercard-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1429px -413px;
  width: 52px;
  height: 39px;
}
.icon-Mauritian-rupees {
  background-image: url(sprite.png);
  background-position: -1360px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Max-Coins-Per-Line {
  background-image: url(sprite.png);
  background-position: -1368px -1311px;
  width: 22px;
  height: 22px;
}
.icon-Max-Coins-Size {
  background-image: url(sprite.png);
  background-position: -1296px -1252px;
  width: 22px;
  height: 22px;
}
.icon-Maximum-Bonus-Amount {
  background-image: url(sprite.png);
  background-position: -1152px -1134px;
  width: 22px;
  height: 22px;
}
.icon-Maybank {
  background-image: url(sprite.png);
  background-position: -1429px -767px;
  width: 52px;
  height: 39px;
}
.icon-Medialive-Casino {
  background-image: url(sprite.png);
  background-position: -1429px -944px;
  width: 52px;
  height: 39px;
}
.icon-Megafon {
  background-image: url(sprite.png);
  background-position: -1429px -1003px;
  width: 52px;
  height: 39px;
}
.icon-Merge-Gaming-Network {
  background-image: url(sprite.png);
  background-position: -1429px -1121px;
  width: 52px;
  height: 39px;
}
.icon-Mexican pesos {
  background-image: url(sprite.png);
  background-position: -1240px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Mexican-pesos {
  background-image: url(sprite.png);
  background-position: -240px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Min-Coins-Per-Line {
  background-image: url(sprite.png);
  background-position: -1080px -1016px;
  width: 22px;
  height: 22px;
}
.icon-Min-Coins-Size {
  background-image: url(sprite.png);
  background-position: -1501px -1218px;
  width: 22px;
  height: 22px;
}
.icon-Min-Deposit {
  background-image: url(sprite.png);
  background-position: -1501px -210px;
  width: 22px;
  height: 22px;
}
.icon-MisterCash {
  background-image: url(sprite.png);
  background-position: -72px -1429px;
  width: 52px;
  height: 39px;
}
.icon-Mobile-Commerce {
  background-image: url(sprite.png);
  background-position: 0px -1429px;
  width: 52px;
  height: 39px;
}
.icon-Mobile-Deposit {
  background-image: url(sprite.png);
  background-position: -1429px -1357px;
  width: 52px;
  height: 39px;
}
.icon-Mobile-bill {
  background-image: url(sprite.png);
  background-position: -144px -1429px;
  width: 52px;
  height: 39px;
}
.icon-Mobile-payments-Beeline {
  background-image: url(sprite.png);
  background-position: -1429px -1239px;
  width: 52px;
  height: 39px;
}
.icon-Mobile {
  background-image: url(sprite.png);
  background-position: -1663px -764px;
  width: 15px;
  height: 24px;
}
.icon-Mojeplatba {
  background-image: url(sprite.png);
  background-position: -1429px -1180px;
  width: 52px;
  height: 39px;
}
.icon-Moldovan-lei {
  background-image: url(sprite.png);
  background-position: -1200px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Monero {
  background-image: url(sprite.png);
  background-position: -1429px -1062px;
  width: 52px;
  height: 39px;
}
.icon-Moneta ru {
  background-image: url(sprite.png);
  background-position: -82px -41px;
  width: 60px;
  height: 45px;
}
.icon-Moneta-For-Russia {
  background-image: url(sprite.png);
  background-position: -1429px -708px;
  width: 52px;
  height: 39px;
}
.icon-Moneta {
  background-image: url(sprite.png);
  background-position: -1429px -590px;
  width: 52px;
  height: 39px;
}
.icon-Money-Global {
  background-image: url(sprite.png);
  background-position: -1429px -531px;
  width: 52px;
  height: 39px;
}
.icon-Money-Gram {
  background-image: url(sprite.png);
  background-position: -1429px -295px;
  width: 52px;
  height: 39px;
}
.icon-Money-Line-Wallet {
  background-image: url(sprite.png);
  background-position: -1429px -236px;
  width: 52px;
  height: 39px;
}
.icon-Money-Mail {
  background-image: url(sprite.png);
  background-position: -1429px -59px;
  width: 52px;
  height: 39px;
}
.icon-Money-Order {
  background-image: url(sprite.png);
  background-position: -1429px 0px;
  width: 52px;
  height: 39px;
}
.icon-Money-Transfer {
  background-image: url(sprite.png);
  background-position: -1296px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Money-Wire {
  background-image: url(sprite.png);
  background-position: -1224px -1370px;
  width: 52px;
  height: 39px;
}
.icon-MoneyPak {
  background-image: url(sprite.png);
  background-position: -1152px -1370px;
  width: 52px;
  height: 39px;
}
.icon-MoneySafe {
  background-image: url(sprite.png);
  background-position: -1080px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Moroccan-dirhams {
  background-image: url(sprite.png);
  background-position: -1543px -1480px;
  width: 20px;
  height: 20px;
}
.icon-Moscow-Credit-Bank {
  background-image: url(sprite.png);
  background-position: -1008px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Mozambican-meticals {
  background-image: url(sprite.png);
  background-position: -240px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Mplatba {
  background-image: url(sprite.png);
  background-position: -360px -1370px;
  width: 52px;
  height: 39px;
}
.icon-Multibanco {
  background-image: url(sprite.png);
  background-position: -1080px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Multicommerce-Game-Studio {
  background-image: url(sprite.png);
  background-position: -1008px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Multiplier {
  background-image: url(sprite.png);
  background-position: -1501px -168px;
  width: 22px;
  height: 22px;
}
.icon-My-Cash-card {
  background-image: url(sprite.png);
  background-position: -720px -1311px;
  width: 52px;
  height: 39px;
}
.icon-MyBank {
  background-image: url(sprite.png);
  background-position: -648px -1311px;
  width: 52px;
  height: 39px;
}
.icon-MyCitadel {
  background-image: url(sprite.png);
  background-position: -504px -1311px;
  width: 52px;
  height: 39px;
}
.icon-MyMonedero {
  background-image: url(sprite.png);
  background-position: -432px -1311px;
  width: 52px;
  height: 39px;
}
.icon-MyVoucher {
  background-image: url(sprite.png);
  background-position: -288px -1311px;
  width: 52px;
  height: 39px;
}
.icon-MypaylinQ {
  background-image: url(sprite.png);
  background-position: -360px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Namibian dollars {
  background-image: url(sprite.png);
  background-position: -1360px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Namibian-dollars {
  background-image: url(sprite.png);
  background-position: -1400px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Neosurf {
  background-image: url(sprite.png);
  background-position: -216px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Neteller-1-Pay {
  background-image: url(sprite.png);
  background-position: -144px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Neteller {
  background-image: url(sprite.png);
  background-position: -72px -1311px;
  width: 52px;
  height: 39px;
}
.icon-Netpay-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1357px -1180px;
  width: 52px;
  height: 39px;
}
.icon-Netpay {
  background-image: url(sprite.png);
  background-position: -1357px -1121px;
  width: 52px;
  height: 39px;
}
.icon-New Zealand dollars {
  background-image: url(sprite.png);
  background-position: -1623px -1440px;
  width: 20px;
  height: 20px;
}
.icon-New-Taiwan-dollars {
  background-image: url(sprite.png);
  background-position: -160px -1608px;
  width: 20px;
  height: 20px;
}
.icon-New-Zealand-dollars {
  background-image: url(sprite.png);
  background-position: -1280px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Nigerian-nairas {
  background-image: url(sprite.png);
  background-position: -1560px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Nordea-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1357px -1062px;
  width: 52px;
  height: 39px;
}
.icon-Nordea {
  background-image: url(sprite.png);
  background-position: -1357px -1003px;
  width: 52px;
  height: 39px;
}
.icon-Norwegian kroner {
  background-image: url(sprite.png);
  background-position: -1501px -1420px;
  width: 20px;
  height: 20px;
}
.icon-Norwegian-kroner {
  background-image: url(sprite.png);
  background-position: 0px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Norwegian {
  background-image: url(sprite.png);
  background-position: -40px -1488px;
  width: 20px;
  height: 20px;
}
.icon-OKPay {
  background-image: url(sprite.png);
  background-position: -1357px -649px;
  width: 52px;
  height: 39px;
}
.icon-OP-Pohjola-Group {
  background-image: url(sprite.png);
  background-position: -1357px -177px;
  width: 52px;
  height: 39px;
}
.icon-OTP {
  background-image: url(sprite.png);
  background-position: -1357px -59px;
  width: 52px;
  height: 39px;
}
.icon-Oaysafe {
  background-image: url(sprite.png);
  background-position: -1357px -885px;
  width: 52px;
  height: 39px;
}
.icon-OchaPay {
  background-image: url(sprite.png);
  background-position: -1357px -826px;
  width: 52px;
  height: 39px;
}
.icon-Ogone {
  background-image: url(sprite.png);
  background-position: -1357px -767px;
  width: 52px;
  height: 39px;
}
.icon-Omani-rials {
  background-image: url(sprite.png);
  background-position: -280px -1488px;
  width: 20px;
  height: 20px;
}
.icon-On-Demand-Funds {
  background-image: url(sprite.png);
  background-position: -1357px -590px;
  width: 52px;
  height: 39px;
}
.icon-Online-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -1357px -472px;
  width: 52px;
  height: 39px;
}
.icon-Online-Banking {
  background-image: url(sprite.png);
  background-position: -1357px -413px;
  width: 52px;
  height: 39px;
}
.icon-Online-Checks {
  background-image: url(sprite.png);
  background-position: -1357px -354px;
  width: 52px;
  height: 39px;
}
.icon-Online-bank-transfer-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1357px -531px;
  width: 52px;
  height: 39px;
}
.icon-Online_berweisung {
  background-image: url(sprite.png);
  background-position: -1357px -236px;
  width: 52px;
  height: 39px;
}
.icon-Onlineberweisung {
  background-image: url(sprite.png);
  background-position: -1357px -295px;
  width: 52px;
  height: 39px;
}
.icon-Otopay {
  background-image: url(sprite.png);
  background-position: -1357px -118px;
  width: 52px;
  height: 39px;
}
.icon-Overnight-Express {
  background-image: url(sprite.png);
  background-position: -1357px 0px;
  width: 52px;
  height: 39px;
}
.icon-P24 {
  background-image: url(sprite.png);
  background-position: -504px -1252px;
  width: 52px;
  height: 39px;
}
.icon-PAGOFACIL {
  background-image: url(sprite.png);
  background-position: -1285px -826px;
  width: 52px;
  height: 39px;
}
.icon-PLATBA24 {
  background-image: url(sprite.png);
  background-position: -792px -1134px;
  width: 52px;
  height: 39px;
}
.icon-POL {
  background-image: url(sprite.png);
  background-position: -648px -1134px;
  width: 52px;
  height: 39px;
}
.icon-POLi {
  background-image: url(sprite.png);
  background-position: -360px -1134px;
  width: 52px;
  height: 39px;
}
.icon-POP-Bank {
  background-image: url(sprite.png);
  background-position: -144px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Pago-efectivo {
  background-image: url(sprite.png);
  background-position: -1285px -1003px;
  width: 52px;
  height: 39px;
}
.icon-Papaya-Card {
  background-image: url(sprite.png);
  background-position: -1285px -767px;
  width: 52px;
  height: 39px;
}
.icon-Paper-Check {
  background-image: url(sprite.png);
  background-position: -1285px -708px;
  width: 52px;
  height: 39px;
}
.icon-Paraguayan-guaranis {
  background-image: url(sprite.png);
  background-position: -1040px -1488px;
  width: 20px;
  height: 20px;
}
.icon-PariPlay {
  background-image: url(sprite.png);
  background-position: -1285px -649px;
  width: 52px;
  height: 39px;
}
.icon-PastePay {
  background-image: url(sprite.png);
  background-position: -1285px -590px;
  width: 52px;
  height: 39px;
}
.icon-Pay-2-Card {
  background-image: url(sprite.png);
  background-position: -1285px -531px;
  width: 52px;
  height: 39px;
}
.icon-Pay-Spark {
  background-image: url(sprite.png);
  background-position: -1285px -177px;
  width: 52px;
  height: 39px;
}
.icon-Pay-by-Phone {
  background-image: url(sprite.png);
  background-position: -1285px -295px;
  width: 52px;
  height: 39px;
}
.icon-Pay2 {
  background-image: url(sprite.png);
  background-position: -1285px -118px;
  width: 52px;
  height: 39px;
}
.icon-PayAnyWay {
  background-image: url(sprite.png);
  background-position: -1285px -59px;
  width: 52px;
  height: 39px;
}
.icon-PayKasa {
  background-image: url(sprite.png);
  background-position: -1008px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayKwik {
  background-image: url(sprite.png);
  background-position: -864px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayP2P {
  background-image: url(sprite.png);
  background-position: -504px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayPal {
  background-image: url(sprite.png);
  background-position: -432px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayPlay {
  background-image: url(sprite.png);
  background-position: -360px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayPoint-Card {
  background-image: url(sprite.png);
  background-position: -288px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayPoint-e-Voucher {
  background-image: url(sprite.png);
  background-position: -144px -1193px;
  width: 52px;
  height: 39px;
}
.icon-PayU {
  background-image: url(sprite.png);
  background-position: -1213px -118px;
  width: 52px;
  height: 39px;
}
.icon-PayVision {
  background-image: url(sprite.png);
  background-position: -1213px -59px;
  width: 52px;
  height: 39px;
}
.icon-Payeer {
  background-image: url(sprite.png);
  background-position: -1285px 0px;
  width: 52px;
  height: 39px;
}
.icon-Payforit {
  background-image: url(sprite.png);
  background-position: -1080px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Paylevo {
  background-image: url(sprite.png);
  background-position: -792px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Paylines {
  background-image: url(sprite.png);
  background-position: -1501px -1176px;
  width: 22px;
  height: 22px;
}
.icon-Paymat-Card {
  background-image: url(sprite.png);
  background-position: -720px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Payolution {
  background-image: url(sprite.png);
  background-position: -648px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Paysafe-Card {
  background-image: url(sprite.png);
  background-position: 0px -1193px;
  width: 52px;
  height: 39px;
}
.icon-Paysec-THB {
  background-image: url(sprite.png);
  background-position: -1213px -1003px;
  width: 52px;
  height: 39px;
}
.icon-Paysera {
  background-image: url(sprite.png);
  background-position: -1213px -944px;
  width: 52px;
  height: 39px;
}
.icon-Payson {
  background-image: url(sprite.png);
  background-position: -1213px -649px;
  width: 52px;
  height: 39px;
}
.icon-Paytrail {
  background-image: url(sprite.png);
  background-position: -1213px -177px;
  width: 52px;
  height: 39px;
}
.icon-Peercoin {
  background-image: url(sprite.png);
  background-position: -1213px 0px;
  width: 52px;
  height: 39px;
}
.icon-Perfect-Money {
  background-image: url(sprite.png);
  background-position: -1080px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Person-to-Person {
  background-image: url(sprite.png);
  background-position: -1008px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Person2Person {
  background-image: url(sprite.png);
  background-position: -936px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Philippine-pesos {
  background-image: url(sprite.png);
  background-position: -1543px -840px;
  width: 20px;
  height: 20px;
}
.icon-Phone {
  background-image: url(sprite.png);
  background-position: -864px -1134px;
  width: 52px;
  height: 39px;
}
.icon-PlusPay {
  background-image: url(sprite.png);
  background-position: -720px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Poli-by-Skrill {
  background-image: url(sprite.png);
  background-position: -432px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Polish-banks {
  background-image: url(sprite.png);
  background-position: -288px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Polish-zlotys {
  background-image: url(sprite.png);
  background-position: -1543px -1280px;
  width: 20px;
  height: 20px;
}
.icon-Polish {
  background-image: url(sprite.png);
  background-position: -1543px -1320px;
  width: 20px;
  height: 20px;
}
.icon-Portuguese {
  background-image: url(sprite.png);
  background-position: -1543px -1440px;
  width: 20px;
  height: 20px;
}
.icon-Postal-Check {
  background-image: url(sprite.png);
  background-position: -72px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Postepay-by-Skrill {
  background-image: url(sprite.png);
  background-position: 0px -1134px;
  width: 52px;
  height: 39px;
}
.icon-Postepay {
  background-image: url(sprite.png);
  background-position: -1141px -826px;
  width: 52px;
  height: 39px;
}
.icon-Pragmatic-Play {
  background-image: url(sprite.png);
  background-position: -1141px -649px;
  width: 52px;
  height: 39px;
}
.icon-Prepaid-ATM {
  background-image: url(sprite.png);
  background-position: -1141px -354px;
  width: 52px;
  height: 39px;
}
.icon-Prepaid-Card {
  background-image: url(sprite.png);
  background-position: -1141px -59px;
  width: 52px;
  height: 39px;
}
.icon-Prepaid-Cards {
  background-image: url(sprite.png);
  background-position: -864px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Prepaid-Gift-Card {
  background-image: url(sprite.png);
  background-position: -360px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Privat24 {
  background-image: url(sprite.png);
  background-position: -144px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Probability {
  background-image: url(sprite.png);
  background-position: -72px -1075px;
  width: 52px;
  height: 39px;
}
.icon-Progressive {
  background-image: url(sprite.png);
  background-position: -1501px -84px;
  width: 22px;
  height: 22px;
}
.icon-Promsvyazbank {
  background-image: url(sprite.png);
  background-position: -1008px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Przelewy {
  background-image: url(sprite.png);
  background-position: -936px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Przelewy24-by-Skrill {
  background-image: url(sprite.png);
  background-position: -864px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Przelewy24 {
  background-image: url(sprite.png);
  background-position: -792px -1016px;
  width: 52px;
  height: 39px;
}
.icon-Public-Bank {
  background-image: url(sprite.png);
  background-position: -720px -1016px;
  width: 52px;
  height: 39px;
}
.icon-PugglePay {
  background-image: url(sprite.png);
  background-position: 0px -1016px;
  width: 52px;
  height: 39px;
}
.icon-QIWI {
  background-image: url(sprite.png);
  background-position: -1069px -826px;
  width: 52px;
  height: 39px;
}
.icon-Qatari rials {
  background-image: url(sprite.png);
  background-position: -800px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Qatari-rials {
  background-image: url(sprite.png);
  background-position: -840px -1528px;
  width: 20px;
  height: 20px;
}
.icon-Quick-Cash {
  background-image: url(sprite.png);
  background-position: -1069px -295px;
  width: 52px;
  height: 39px;
}
.icon-Quick-Pay {
  background-image: url(sprite.png);
  background-position: -1069px -177px;
  width: 52px;
  height: 39px;
}
.icon-QuickTender {
  background-image: url(sprite.png);
  background-position: -1069px -118px;
  width: 52px;
  height: 39px;
}
.icon-QuickTransfer {
  background-image: url(sprite.png);
  background-position: -1069px -59px;
  width: 52px;
  height: 39px;
}
.icon-Rabobank {
  background-image: url(sprite.png);
  background-position: -144px -957px;
  width: 52px;
  height: 39px;
}
.icon-Rapida {
  background-image: url(sprite.png);
  background-position: -72px -957px;
  width: 52px;
  height: 39px;
}
.icon-Redeem-voucher {
  background-image: url(sprite.png);
  background-position: -997px -885px;
  width: 52px;
  height: 39px;
}
.icon-Redpagos-by-Neteller {
  background-image: url(sprite.png);
  background-position: -997px -826px;
  width: 52px;
  height: 39px;
}
.icon-Reels {
  background-image: url(sprite.png);
  background-position: -1501px -336px;
  width: 22px;
  height: 22px;
}
.icon-Reloadit {
  background-image: url(sprite.png);
  background-position: -997px -649px;
  width: 52px;
  height: 39px;
}
.icon-Restricted-countries {
  background-image: url(sprite.png);
  background-position: -1501px -378px;
  width: 22px;
  height: 22px;
}
.icon-Romanian-lei {
  background-image: url(sprite.png);
  background-position: -1583px 0px;
  width: 20px;
  height: 20px;
}
.icon-Romanian {
  background-image: url(sprite.png);
  background-position: -1583px -40px;
  width: 20px;
  height: 20px;
}
.icon-Roulette {
  background-image: url(sprite.png);
  background-position: -504px -485px;
  width: 28px;
  height: 28px;
}
.icon-Russian-Cash-Terminal {
  background-image: url(sprite.png);
  background-position: -997px -590px;
  width: 52px;
  height: 39px;
}
.icon-Russian-rubles {
  background-image: url(sprite.png);
  background-position: -1583px -320px;
  width: 20px;
  height: 20px;
}
.icon-Russian {
  background-image: url(sprite.png);
  background-position: -1583px -360px;
  width: 20px;
  height: 20px;
}
.icon-S-Bank {
  background-image: url(sprite.png);
  background-position: -997px -531px;
  width: 52px;
  height: 39px;
}
.icon-SEB-Bank {
  background-image: url(sprite.png);
  background-position: -925px -354px;
  width: 52px;
  height: 39px;
}
.icon-SID {
  background-image: url(sprite.png);
  background-position: -925px -59px;
  width: 52px;
  height: 39px;
}
.icon-SMS-Bill {
  background-image: url(sprite.png);
  background-position: -216px -839px;
  width: 52px;
  height: 39px;
}
.icon-SMS-Plus {
  background-image: url(sprite.png);
  background-position: -144px -839px;
  width: 52px;
  height: 39px;
}
.icon-SMS {
  background-image: url(sprite.png);
  background-position: -72px -839px;
  width: 52px;
  height: 39px;
}
.icon-SNS-Bank {
  background-image: url(sprite.png);
  background-position: 0px -839px;
  width: 52px;
  height: 39px;
}
.icon-Safe2pay {
  background-image: url(sprite.png);
  background-position: -792px -898px;
  width: 52px;
  height: 39px;
}
.icon-SafeCharge {
  background-image: url(sprite.png);
  background-position: -720px -898px;
  width: 52px;
  height: 39px;
}
.icon-SafetyPay {
  background-image: url(sprite.png);
  background-position: -360px -898px;
  width: 52px;
  height: 39px;
}
.icon-Safetypay-Online-Bank-Transfer {
  background-image: url(sprite.png);
  background-position: -504px -898px;
  width: 52px;
  height: 39px;
}
.icon-SampoPankki {
  background-image: url(sprite.png);
  background-position: -144px -898px;
  width: 52px;
  height: 39px;
}
.icon-Santander {
  background-image: url(sprite.png);
  background-position: -72px -898px;
  width: 52px;
  height: 39px;
}
.icon-Saudi riyals {
  background-image: url(sprite.png);
  background-position: -1583px -720px;
  width: 20px;
  height: 20px;
}
.icon-Saudi-riyals {
  background-image: url(sprite.png);
  background-position: -1583px -760px;
  width: 20px;
  height: 20px;
}
.icon-Savings-Bank {
  background-image: url(sprite.png);
  background-position: 0px -898px;
  width: 52px;
  height: 39px;
}
.icon-Sberbank-Online {
  background-image: url(sprite.png);
  background-position: -925px -826px;
  width: 52px;
  height: 39px;
}
.icon-Sberbank {
  background-image: url(sprite.png);
  background-position: -925px -767px;
  width: 52px;
  height: 39px;
}
.icon-Scatter-Symbol {
  background-image: url(sprite.png);
  background-position: -1501px -504px;
  width: 22px;
  height: 22px;
}
.icon-Scotiabank {
  background-image: url(sprite.png);
  background-position: -925px -590px;
  width: 52px;
  height: 39px;
}
.icon-Scratch-Card {
  background-image: url(sprite.png);
  background-position: -925px -413px;
  width: 52px;
  height: 39px;
}
.icon-Secure-Voucher-payment {
  background-image: url(sprite.png);
  background-position: -925px -295px;
  width: 52px;
  height: 39px;
}
.icon-Sepa {
  background-image: url(sprite.png);
  background-position: -925px -118px;
  width: 52px;
  height: 39px;
}
.icon-Serbian-dinars {
  background-image: url(sprite.png);
  background-position: -1583px -1360px;
  width: 20px;
  height: 20px;
}
.icon-Serbian {
  background-image: url(sprite.png);
  background-position: -1583px -1400px;
  width: 20px;
  height: 20px;
}
.icon-Simplified-Chinese {
  background-image: url(sprite.png);
  background-position: -1583px -1520px;
  width: 20px;
  height: 20px;
}
.icon-Singapore dollars {
  background-image: url(sprite.png);
  background-position: 0px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Singapore-dollars {
  background-image: url(sprite.png);
  background-position: -40px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Siru-Mobile {
  background-image: url(sprite.png);
  background-position: -720px -839px;
  width: 52px;
  height: 39px;
}
.icon-Skandiabanken-and-Lansforsakringar {
  background-image: url(sprite.png);
  background-position: -648px -839px;
  width: 52px;
  height: 39px;
}
.icon-Skrill-1-Tap {
  background-image: url(sprite.png);
  background-position: -576px -839px;
  width: 52px;
  height: 39px;
}
.icon-Skrill {
  background-image: url(sprite.png);
  background-position: -432px -839px;
  width: 52px;
  height: 39px;
}
.icon-Slot-Type {
  background-image: url(sprite.png);
  background-position: -1501px -630px;
  width: 22px;
  height: 22px;
}
.icon-Slovak {
  background-image: url(sprite.png);
  background-position: -360px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Slovenian {
  background-image: url(sprite.png);
  background-position: -400px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Sofort-by-Skrill {
  background-image: url(sprite.png);
  background-position: -853px -295px;
  width: 52px;
  height: 39px;
}
.icon-Sofortuberwaisung {
  background-image: url(sprite.png);
  background-position: -853px -236px;
  width: 52px;
  height: 39px;
}
.icon-Software {
  background-image: url(sprite.png);
  background-position: -1501px -672px;
  width: 22px;
  height: 22px;
}
.icon-Solid-Debit-Card {
  background-image: url(sprite.png);
  background-position: -853px -177px;
  width: 52px;
  height: 39px;
}
.icon-Solo-by-Skrill {
  background-image: url(sprite.png);
  background-position: -853px 0px;
  width: 52px;
  height: 39px;
}
.icon-Solo {
  background-image: url(sprite.png);
  background-position: -720px -780px;
  width: 52px;
  height: 39px;
}
.icon-South-African-Rand {
  background-image: url(sprite.png);
  background-position: -920px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Spanish {
  background-image: url(sprite.png);
  background-position: -960px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Speed-Pay {
  background-image: url(sprite.png);
  background-position: -648px -780px;
  width: 52px;
  height: 39px;
}
.icon-Speedcard {
  background-image: url(sprite.png);
  background-position: -72px -780px;
  width: 52px;
  height: 39px;
}
.icon-SporoPay {
  background-image: url(sprite.png);
  background-position: 0px -780px;
  width: 52px;
  height: 39px;
}
.icon-Stander {
  background-image: url(sprite.png);
  background-position: -781px -708px;
  width: 52px;
  height: 39px;
}
.icon-Sudanese-pounds {
  background-image: url(sprite.png);
  background-position: -1240px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Suomen-Verkkomaksut {
  background-image: url(sprite.png);
  background-position: -781px -649px;
  width: 52px;
  height: 39px;
}
.icon-Surefire-Commerce {
  background-image: url(sprite.png);
  background-position: -781px -590px;
  width: 52px;
  height: 39px;
}
.icon-Svyazno {
  background-image: url(sprite.png);
  background-position: -781px -531px;
  width: 52px;
  height: 39px;
}
.icon-Swedbank {
  background-image: url(sprite.png);
  background-position: -781px -472px;
  width: 52px;
  height: 39px;
}
.icon-Swedish kronor {
  background-image: url(sprite.png);
  background-position: -1440px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Swedish-kronor {
  background-image: url(sprite.png);
  background-position: -1480px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Swedish {
  background-image: url(sprite.png);
  background-position: -1520px -1568px;
  width: 20px;
  height: 20px;
}
.icon-Swiff {
  background-image: url(sprite.png);
  background-position: -781px -413px;
  width: 52px;
  height: 39px;
}
.icon-Swift-Check {
  background-image: url(sprite.png);
  background-position: -781px -118px;
  width: 52px;
  height: 39px;
}
.icon-Swiftvoucher {
  background-image: url(sprite.png);
  background-position: -432px -721px;
  width: 52px;
  height: 39px;
}
.icon-Swiss-francs {
  background-image: url(sprite.png);
  background-position: -1623px -80px;
  width: 20px;
  height: 20px;
}
.icon-Swissgame {
  background-image: url(sprite.png);
  background-position: -360px -721px;
  width: 52px;
  height: 39px;
}
.icon-Switch {
  background-image: url(sprite.png);
  background-position: -288px -721px;
  width: 52px;
  height: 39px;
}
.icon-Tanzanian-shillings {
  background-image: url(sprite.png);
  background-position: -1623px -200px;
  width: 20px;
  height: 20px;
}
.icon-Tapiola-Bank {
  background-image: url(sprite.png);
  background-position: -144px -721px;
  width: 52px;
  height: 39px;
}
.icon-Tapiola {
  background-image: url(sprite.png);
  background-position: -72px -721px;
  width: 52px;
  height: 39px;
}
.icon-Tatrabank {
  background-image: url(sprite.png);
  background-position: -648px -662px;
  width: 52px;
  height: 39px;
}
.icon-Tele2 {
  background-image: url(sprite.png);
  background-position: -576px -662px;
  width: 52px;
  height: 39px;
}
.icon-Telebuy {
  background-image: url(sprite.png);
  background-position: -504px -662px;
  width: 52px;
  height: 39px;
}
.icon-Telegraphic-Transfer {
  background-image: url(sprite.png);
  background-position: -360px -662px;
  width: 52px;
  height: 39px;
}
.icon-Teleingreso {
  background-image: url(sprite.png);
  background-position: -288px -662px;
  width: 52px;
  height: 39px;
}
.icon-Thai-baht {
  background-image: url(sprite.png);
  background-position: -1623px -560px;
  width: 20px;
  height: 20px;
}
.icon-Thai {
  background-image: url(sprite.png);
  background-position: -1623px -600px;
  width: 20px;
  height: 20px;
}
.icon-ThaiDirect {
  background-image: url(sprite.png);
  background-position: -216px -662px;
  width: 52px;
  height: 39px;
}
.icon-The-Bank-of-Aland {
  background-image: url(sprite.png);
  background-position: -72px -662px;
  width: 52px;
  height: 39px;
}
.icon-Ticket-Premium {
  background-image: url(sprite.png);
  background-position: 0px -662px;
  width: 52px;
  height: 39px;
}
.icon-TicketSurf {
  background-image: url(sprite.png);
  background-position: -709px -590px;
  width: 52px;
  height: 39px;
}
.icon-Todito-Cash {
  background-image: url(sprite.png);
  background-position: -709px -531px;
  width: 52px;
  height: 39px;
}
.icon-Transfer-Money {
  background-image: url(sprite.png);
  background-position: -709px -472px;
  width: 52px;
  height: 39px;
}
.icon-Transferencia-Bancaria-Local {
  background-image: url(sprite.png);
  background-position: -709px -413px;
  width: 52px;
  height: 39px;
}
.icon-Transferuj-pl {
  background-image: url(sprite.png);
  background-position: -709px -118px;
  width: 52px;
  height: 39px;
}
.icon-TrustPay {
  background-image: url(sprite.png);
  background-position: -576px -603px;
  width: 52px;
  height: 39px;
}
.icon-Trustly {
  background-image: url(sprite.png);
  background-position: -709px -59px;
  width: 52px;
  height: 39px;
}
.icon-Tunisian-dinars {
  background-image: url(sprite.png);
  background-position: -1623px -1200px;
  width: 20px;
  height: 20px;
}
.icon-Turkey-Bank-Wire {
  background-image: url(sprite.png);
  background-position: -504px -603px;
  width: 52px;
  height: 39px;
}
.icon-Turkish-Lira {
  background-image: url(sprite.png);
  background-position: -1623px -1280px;
  width: 20px;
  height: 20px;
}
.icon-Turkish {
  background-image: url(sprite.png);
  background-position: -1623px -1320px;
  width: 20px;
  height: 20px;
}
.icon-Turkiye-Bankasi {
  background-image: url(sprite.png);
  background-position: -432px -603px;
  width: 52px;
  height: 39px;
}
.icon-Type-of-bonus {
  background-image: url(sprite.png);
  background-position: -1501px -1134px;
  width: 22px;
  height: 22px;
}
.icon-UK-Betting-Shops {
  background-image: url(sprite.png);
  background-position: 0px -603px;
  width: 52px;
  height: 39px;
}
.icon-UK-Visa-credit-card {
  background-image: url(sprite.png);
  background-position: -637px -354px;
  width: 52px;
  height: 39px;
}
.icon-UK-credit-card {
  background-image: url(sprite.png);
  background-position: -637px -531px;
  width: 52px;
  height: 39px;
}
.icon-UPayCard {
  background-image: url(sprite.png);
  background-position: -637px 0px;
  width: 52px;
  height: 39px;
}
.icon-US-dollars {
  background-image: url(sprite.png);
  background-position: -600px -1608px;
  width: 20px;
  height: 20px;
}
.icon-UTEL {
  background-image: url(sprite.png);
  background-position: -360px -485px;
  width: 52px;
  height: 39px;
}
.icon-Ugandan-shillings {
  background-image: url(sprite.png);
  background-position: 0px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Ukash {
  background-image: url(sprite.png);
  background-position: -637px -177px;
  width: 52px;
  height: 39px;
}
.icon-Ukrainian-hryvnias {
  background-image: url(sprite.png);
  background-position: -280px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Ukrainian {
  background-image: url(sprite.png);
  background-position: -320px -1608px;
  width: 20px;
  height: 20px;
}
.icon-UnionPay {
  background-image: url(sprite.png);
  background-position: -637px -118px;
  width: 52px;
  height: 39px;
}
.icon-United-States {
  background-image: url(sprite.png);
  background-position: -400px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Univoucher {
  background-image: url(sprite.png);
  background-position: -637px -59px;
  width: 52px;
  height: 39px;
}
.icon-Uruguayan-pesos {
  background-image: url(sprite.png);
  background-position: -560px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Use-My-Wallet {
  background-image: url(sprite.png);
  background-position: -360px -544px;
  width: 52px;
  height: 39px;
}
.icon-UseMyBank {
  background-image: url(sprite.png);
  background-position: -288px -544px;
  width: 52px;
  height: 39px;
}
.icon-UseMyFunds {
  background-image: url(sprite.png);
  background-position: -144px -544px;
  width: 52px;
  height: 39px;
}
.icon-UseMyServices {
  background-image: url(sprite.png);
  background-position: -72px -544px;
  width: 52px;
  height: 39px;
}
.icon-UseMyWallet {
  background-image: url(sprite.png);
  background-position: -565px 0px;
  width: 52px;
  height: 39px;
}
.icon-Uzbekistan som {
  background-image: url(sprite.png);
  background-position: -1000px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Uzbekistan-som {
  background-image: url(sprite.png);
  background-position: -1040px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Vanilla {
  background-image: url(sprite.png);
  background-position: -216px -485px;
  width: 52px;
  height: 39px;
}
.icon-Venezuelan-bolívars {
  background-image: url(sprite.png);
  background-position: -1200px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Video-Poker {
  background-image: url(sprite.png);
  background-position: -288px -249px;
  width: 28px;
  height: 28px;
}
.icon-Vietnamese-dong {
  background-image: url(sprite.png);
  background-position: -1320px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Vietnamese {
  background-image: url(sprite.png);
  background-position: -1360px -1608px;
  width: 20px;
  height: 20px;
}
.icon-Vimo-Wallet {
  background-image: url(sprite.png);
  background-position: -72px -485px;
  width: 52px;
  height: 39px;
}
.icon-Virtual-Card {
  background-image: url(sprite.png);
  background-position: 0px -485px;
  width: 52px;
  height: 39px;
}
.icon-Visa-Debit {
  background-image: url(sprite.png);
  background-position: -493px -295px;
  width: 52px;
  height: 39px;
}
.icon-Visa-Delta {
  background-image: url(sprite.png);
  background-position: -493px -236px;
  width: 52px;
  height: 39px;
}
.icon-Visa-Electron {
  background-image: url(sprite.png);
  background-position: -493px -177px;
  width: 52px;
  height: 39px;
}
.icon-Visa-Virtuon {
  background-image: url(sprite.png);
  background-position: -493px -59px;
  width: 52px;
  height: 39px;
}
.icon-Visa-by-Neteller {
  background-image: url(sprite.png);
  background-position: -493px -413px;
  width: 52px;
  height: 39px;
}
.icon-Visa-by-Skrill {
  background-image: url(sprite.png);
  background-position: -493px -354px;
  width: 52px;
  height: 39px;
}
.icon-Visa {
  background-image: url(sprite.png);
  background-position: -493px 0px;
  width: 52px;
  height: 39px;
}
.icon-Voucher {
  background-image: url(sprite.png);
  background-position: -360px -367px;
  width: 52px;
  height: 39px;
}
.icon-WING {
  background-image: url(sprite.png);
  background-position: -72px -308px;
  width: 52px;
  height: 39px;
}
.icon-Wagering-requirements {
  background-image: url(sprite.png);
  background-position: -1501px -588px;
  width: 22px;
  height: 22px;
}
.icon-Wallet-One {
  background-image: url(sprite.png);
  background-position: -288px -367px;
  width: 52px;
  height: 39px;
}
.icon-Walmart-Gift-Card {
  background-image: url(sprite.png);
  background-position: -216px -367px;
  width: 52px;
  height: 39px;
}
.icon-WeChat-Pay {
  background-image: url(sprite.png);
  background-position: -421px -236px;
  width: 52px;
  height: 39px;
}
.icon-Web-Card {
  background-image: url(sprite.png);
  background-position: -144px -367px;
  width: 52px;
  height: 39px;
}
.icon-WebMoney {
  background-image: url(sprite.png);
  background-position: 0px -367px;
  width: 52px;
  height: 39px;
}
.icon-Webpay-by-Neteller {
  background-image: url(sprite.png);
  background-position: -421px -295px;
  width: 52px;
  height: 39px;
}
.icon-Western-Union {
  background-image: url(sprite.png);
  background-position: -421px -177px;
  width: 52px;
  height: 39px;
}
.icon-Wild-Symbol {
  background-image: url(sprite.png);
  background-position: -1501px -756px;
  width: 22px;
  height: 22px;
}
.icon-William-Hill-cash-direct {
  background-image: url(sprite.png);
  background-position: -288px -308px;
  width: 52px;
  height: 39px;
}
.icon-Wire-Transfer {
  background-image: url(sprite.png);
  background-position: 0px -308px;
  width: 52px;
  height: 39px;
}
.icon-WireCard {
  background-image: url(sprite.png);
  background-position: -349px -118px;
  width: 52px;
  height: 39px;
}
.icon-Withdrawal-Limits {
  background-image: url(sprite.png);
  background-position: -1501px -1092px;
  width: 22px;
  height: 22px;
}
.icon-Withdrawal-methods {
  background-image: url(sprite.png);
  background-position: -1501px -714px;
  width: 22px;
  height: 22px;
}
.icon-Yandex-Money {
  background-image: url(sprite.png);
  background-position: -349px -59px;
  width: 52px;
  height: 39px;
}
.icon-Yap-Kredi {
  background-image: url(sprite.png);
  background-position: -349px 0px;
  width: 52px;
  height: 39px;
}
.icon-Yap_-Kredi {
  background-image: url(sprite.png);
  background-position: -277px -59px;
  width: 52px;
  height: 39px;
}
.icon-Year-established {
  background-image: url(sprite.png);
  background-position: -1501px -798px;
  width: 22px;
  height: 22px;
}
.icon-Zambian-kwachas {
  background-image: url(sprite.png);
  background-position: -1320px -1488px;
  width: 20px;
  height: 20px;
}
.icon-Zimpler {
  background-image: url(sprite.png);
  background-position: -277px 0px;
  width: 52px;
  height: 39px;
}
.icon-all {
  background-image: url(sprite.png);
  background-position: -1663px -600px;
  width: 20px;
  height: 20px;
}
.icon-amd {
  background-image: url(sprite.png);
  background-position: -1663px -560px;
  width: 20px;
  height: 20px;
}
.icon-aoa {
  background-image: url(sprite.png);
  background-position: -1663px -440px;
  width: 20px;
  height: 20px;
}
.icon-ar {
  background-image: url(sprite.png);
  background-position: -1663px -400px;
  width: 20px;
  height: 20px;
}
.icon-ars {
  background-image: url(sprite.png);
  background-position: -1663px -160px;
  width: 20px;
  height: 20px;
}
.icon-aud {
  background-image: url(sprite.png);
  background-position: -1663px -80px;
  width: 20px;
  height: 20px;
}
.icon-ax {
  background-image: url(sprite.png);
  background-position: -1520px -1608px;
  width: 20px;
  height: 20px;
}
.icon-azn {
  background-image: url(sprite.png);
  background-position: -1440px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bam {
  background-image: url(sprite.png);
  background-position: -920px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bank-draft-express {
  background-image: url(sprite.png);
  background-position: -565px -118px;
  width: 52px;
  height: 39px;
}
.icon-bdt {
  background-image: url(sprite.png);
  background-position: -840px -1608px;
  width: 20px;
  height: 20px;
}
.icon-be {
  background-image: url(sprite.png);
  background-position: -800px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bg {
  background-image: url(sprite.png);
  background-position: -520px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bgn {
  background-image: url(sprite.png);
  background-position: -440px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bhd {
  background-image: url(sprite.png);
  background-position: -360px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bif {
  background-image: url(sprite.png);
  background-position: -240px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bnd {
  background-image: url(sprite.png);
  background-position: -120px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bob {
  background-image: url(sprite.png);
  background-position: -80px -1608px;
  width: 20px;
  height: 20px;
}
.icon-bonus-btn-casino-ico {
  background-image: url(sprite.png);
  background-position: -1663px -960px;
  width: 17px;
  height: 15px;
}
.icon-brl {
  background-image: url(sprite.png);
  background-position: -1623px -800px;
  width: 20px;
  height: 20px;
}
.icon-btc {
  background-image: url(sprite.png);
  background-position: -1623px -640px;
  width: 20px;
  height: 20px;
}
.icon-bulletin-post {
  background-image: url(sprite.png);
  background-position: -432px -662px;
  width: 52px;
  height: 39px;
}
.icon-bwp {
  background-image: url(sprite.png);
  background-position: -1623px -360px;
  width: 20px;
  height: 20px;
}
.icon-byr {
  background-image: url(sprite.png);
  background-position: -1623px -320px;
  width: 20px;
  height: 20px;
}
.icon-ca {
  background-image: url(sprite.png);
  background-position: -1623px -280px;
  width: 20px;
  height: 20px;
}
.icon-cad {
  background-image: url(sprite.png);
  background-position: -1623px -240px;
  width: 20px;
  height: 20px;
}
.icon-casino_type_download {
  background-image: url(sprite.png);
  background-position: -864px -839px;
  width: 22px;
  height: 24px;
}
.icon-casino_type_instant_play {
  background-image: url(sprite.png);
  background-position: -290px -1429px;
  width: 24px;
  height: 19px;
}
.icon-casino_type_mobile {
  background-image: url(sprite.png);
  background-position: -1663px -720px;
  width: 15px;
  height: 24px;
}
.icon-cdf {
  background-image: url(sprite.png);
  background-position: -1040px -1568px;
  width: 20px;
  height: 20px;
}
.icon-chf {
  background-image: url(sprite.png);
  background-position: -1000px -1568px;
  width: 20px;
  height: 20px;
}
.icon-china-union-pay {
  background-image: url(sprite.png);
  background-position: -853px -118px;
  width: 52px;
  height: 39px;
}
.icon-clp {
  background-image: url(sprite.png);
  background-position: -560px -1568px;
  width: 20px;
  height: 20px;
}
.icon-cny {
  background-image: url(sprite.png);
  background-position: -520px -1568px;
  width: 20px;
  height: 20px;
}
.icon-cop {
  background-image: url(sprite.png);
  background-position: -200px -1568px;
  width: 20px;
  height: 20px;
}
.icon-cr {
  background-image: url(sprite.png);
  background-position: -1583px -1480px;
  width: 20px;
  height: 20px;
}
.icon-crc {
  background-image: url(sprite.png);
  background-position: -1583px -1320px;
  width: 20px;
  height: 20px;
}
.icon-cs {
  background-image: url(sprite.png);
  background-position: -1583px -1000px;
  width: 20px;
  height: 20px;
}
.icon-czk {
  background-image: url(sprite.png);
  background-position: -1583px -800px;
  width: 20px;
  height: 20px;
}
.icon-da {
  background-image: url(sprite.png);
  background-position: -1583px -640px;
  width: 20px;
  height: 20px;
}
.icon-dao {
  background-image: url(sprite.png);
  background-position: -1583px -560px;
  width: 20px;
  height: 20px;
}
.icon-de {
  background-image: url(sprite.png);
  background-position: -1583px -520px;
  width: 20px;
  height: 20px;
}
.icon-dk {
  background-image: url(sprite.png);
  background-position: -1583px -400px;
  width: 20px;
  height: 20px;
}
.icon-dkk {
  background-image: url(sprite.png);
  background-position: -1501px -1260px;
  width: 20px;
  height: 20px;
}
.icon-doge {
  background-image: url(sprite.png);
  background-position: -1400px -1528px;
  width: 20px;
  height: 20px;
}
.icon-dotpay {
  background-image: url(sprite.png);
  background-position: -997px -767px;
  width: 52px;
  height: 39px;
}
.icon-down-rarr {
  background-image: url(sprite.png);
  background-position: -1663px -995px;
  width: 18px;
  height: 10px;
}
.icon-dzd {
  background-image: url(sprite.png);
  background-position: -1080px -1528px;
  width: 20px;
  height: 20px;
}
.icon-e-Check {
  background-image: url(sprite.png);
  background-position: -216px -957px;
  width: 52px;
  height: 39px;
}
.icon-eCard {
  background-image: url(sprite.png);
  background-position: -576px -957px;
  width: 52px;
  height: 39px;
}
.icon-eChecks-by-iGM-Pay {
  background-image: url(sprite.png);
  background-position: -720px -957px;
  width: 52px;
  height: 39px;
}
.icon-eKonto {
  background-image: url(sprite.png);
  background-position: -1069px -236px;
  width: 52px;
  height: 39px;
}
.icon-eMoney-Safe {
  background-image: url(sprite.png);
  background-position: -1069px -767px;
  width: 52px;
  height: 39px;
}
.icon-eNETS {
  background-image: url(sprite.png);
  background-position: -1069px -944px;
  width: 52px;
  height: 39px;
}
.icon-ePassporte {
  background-image: url(sprite.png);
  background-position: -288px -1016px;
  width: 52px;
  height: 39px;
}
.icon-ePay-bg {
  background-image: url(sprite.png);
  background-position: -360px -1016px;
  width: 52px;
  height: 39px;
}
.icon-ePay {
  background-image: url(sprite.png);
  background-position: -432px -1016px;
  width: 52px;
  height: 39px;
}
.icon-ePlatby {
  background-image: url(sprite.png);
  background-position: -504px -1016px;
  width: 52px;
  height: 39px;
}
.icon-eWallet-Xpress {
  background-image: url(sprite.png);
  background-position: -720px -1075px;
  width: 52px;
  height: 39px;
}
.icon-eek {
  background-image: url(sprite.png);
  background-position: -1040px -1528px;
  width: 20px;
  height: 20px;
}
.icon-egp {
  background-image: url(sprite.png);
  background-position: -1000px -1528px;
  width: 20px;
  height: 20px;
}
.icon-el {
  background-image: url(sprite.png);
  background-position: -920px -1528px;
  width: 20px;
  height: 20px;
}
.icon-en {
  background-image: url(sprite.png);
  background-position: -880px -1528px;
  width: 20px;
  height: 20px;
}
.icon-es {
  background-image: url(sprite.png);
  background-position: -640px -1528px;
  width: 20px;
  height: 20px;
}
.icon-et {
  background-image: url(sprite.png);
  background-position: -480px -1528px;
  width: 20px;
  height: 20px;
}
.icon-etb {
  background-image: url(sprite.png);
  background-position: -440px -1528px;
  width: 20px;
  height: 20px;
}
.icon-eur {
  background-image: url(sprite.png);
  background-position: -320px -1528px;
  width: 20px;
  height: 20px;
}
.icon-ewire {
  background-image: url(sprite.png);
  background-position: -792px -1075px;
  width: 52px;
  height: 39px;
}
.icon-eye {
  background-image: url(sprite.png);
  background-position: -378px -1429px;
  width: 26px;
  height: 17px;
}
.icon-fi {
  background-image: url(sprite.png);
  background-position: -120px -1528px;
  width: 20px;
  height: 20px;
}
.icon-fon2fon {
  background-image: url(sprite.png);
  background-position: -1141px -1062px;
  width: 52px;
  height: 39px;
}
.icon-fr {
  background-image: url(sprite.png);
  background-position: 0px -1528px;
  width: 20px;
  height: 20px;
}
.icon-ga {
  background-image: url(sprite.png);
  background-position: -1543px -1240px;
  width: 20px;
  height: 20px;
}
.icon-gamblingtherapy-logo {
  background-image: url(sprite.png);
  background-position: -216px -1429px;
  width: 54px;
  height: 20px;
}
.icon-gbp {
  background-image: url(sprite.png);
  background-position: -1543px -1120px;
  width: 20px;
  height: 20px;
}
.icon-gel {
  background-image: url(sprite.png);
  background-position: -1543px -760px;
  width: 20px;
  height: 20px;
}
.icon-ghs {
  background-image: url(sprite.png);
  background-position: -1543px -520px;
  width: 20px;
  height: 20px;
}
.icon-he {
  background-image: url(sprite.png);
  background-position: -1543px -440px;
  width: 20px;
  height: 20px;
}
.icon-hi {
  background-image: url(sprite.png);
  background-position: -1543px -360px;
  width: 20px;
  height: 20px;
}
.icon-hkd {
  background-image: url(sprite.png);
  background-position: -1543px -280px;
  width: 20px;
  height: 20px;
}
.icon-hr {
  background-image: url(sprite.png);
  background-position: -1543px -200px;
  width: 20px;
  height: 20px;
}
.icon-hrk {
  background-image: url(sprite.png);
  background-position: -1543px -160px;
  width: 20px;
  height: 20px;
}
.icon-hu {
  background-image: url(sprite.png);
  background-position: -1543px -120px;
  width: 20px;
  height: 20px;
}
.icon-huf {
  background-image: url(sprite.png);
  background-position: -1543px -80px;
  width: 20px;
  height: 20px;
}
.icon-iBanq {
  background-image: url(sprite.png);
  background-position: -936px -1193px;
  width: 52px;
  height: 39px;
}
.icon-iCheque {
  background-image: url(sprite.png);
  background-position: -1152px -1193px;
  width: 52px;
  height: 39px;
}
.icon-iDEAL {
  background-image: url(sprite.png);
  background-position: -1285px -413px;
  width: 52px;
  height: 39px;
}
.icon-iDeal-by-Skrill {
  background-image: url(sprite.png);
  background-position: -1285px -354px;
  width: 52px;
  height: 39px;
}
.icon-iDebit {
  background-image: url(sprite.png);
  background-position: -1285px -472px;
  width: 52px;
  height: 39px;
}
.icon-iPoint {
  background-image: url(sprite.png);
  background-position: -1152px -1252px;
  width: 52px;
  height: 39px;
}
.icon-icon-down {
  background-image: url(sprite.png);
  background-position: -1663px -1120px;
  width: 12px;
  height: 7px;
}
.icon-icon-down {
  background-image: url(sprite.png);
  background-position: -1663px -888px;
  width: 20px;
  height: 14px;
}
.icon-icon-top {
  background-image: url(sprite.png);
  background-position: -1663px -1093px;
  width: 12px;
  height: 7px;
}
.icon-icon-top {
  background-image: url(sprite.png);
  background-position: -936px -898px;
  width: 23px;
  height: 22px;
}
.icon-iconUp {
  background-image: url(sprite.png);
  background-position: 0px -41px;
  width: 62px;
  height: 62px;
}
.icon-id {
  background-image: url(sprite.png);
  background-position: -1200px -1488px;
  width: 20px;
  height: 20px;
}
.icon-idr {
  background-image: url(sprite.png);
  background-position: -1160px -1488px;
  width: 20px;
  height: 20px;
}
.icon-il {
  background-image: url(sprite.png);
  background-position: -1120px -1488px;
  width: 20px;
  height: 20px;
}
.icon-ils {
  background-image: url(sprite.png);
  background-position: -1080px -1488px;
  width: 20px;
  height: 20px;
}
.icon-inpay {
  background-image: url(sprite.png);
  background-position: -1285px -944px;
  width: 52px;
  height: 39px;
}
.icon-inr {
  background-image: url(sprite.png);
  background-position: -880px -1488px;
  width: 20px;
  height: 20px;
}
.icon-instaCash {
  background-image: url(sprite.png);
  background-position: -1285px -1121px;
  width: 52px;
  height: 39px;
}
.icon-instaDebit {
  background-image: url(sprite.png);
  background-position: -1285px -1180px;
  width: 52px;
  height: 39px;
}
.icon-interkassa {
  background-image: url(sprite.png);
  background-position: -864px -1252px;
  width: 52px;
  height: 39px;
}
.icon-iqd {
  background-image: url(sprite.png);
  background-position: -800px -1488px;
  width: 20px;
  height: 20px;
}
.icon-irr {
  background-image: url(sprite.png);
  background-position: -640px -1488px;
  width: 20px;
  height: 20px;
}
.icon-is {
  background-image: url(sprite.png);
  background-position: -600px -1488px;
  width: 20px;
  height: 20px;
}
.icon-isk {
  background-image: url(sprite.png);
  background-position: -560px -1488px;
  width: 20px;
  height: 20px;
}
.icon-it {
  background-image: url(sprite.png);
  background-position: -440px -1488px;
  width: 20px;
  height: 20px;
}
.icon-ja {
  background-image: url(sprite.png);
  background-position: -360px -1488px;
  width: 20px;
  height: 20px;
}
.icon-jod {
  background-image: url(sprite.png);
  background-position: -1501px -1380px;
  width: 20px;
  height: 20px;
}
.icon-jpy {
  background-image: url(sprite.png);
  background-position: -1623px -1360px;
  width: 20px;
  height: 20px;
}
.icon-ka {
  background-image: url(sprite.png);
  background-position: -1623px -1240px;
  width: 20px;
  height: 20px;
}
.icon-kes {
  background-image: url(sprite.png);
  background-position: -1560px -1568px;
  width: 20px;
  height: 20px;
}
.icon-ko {
  background-image: url(sprite.png);
  background-position: -1200px -1568px;
  width: 20px;
  height: 20px;
}
.icon-krw {
  background-image: url(sprite.png);
  background-position: -1583px -1120px;
  width: 20px;
  height: 20px;
}
.icon-kwd {
  background-image: url(sprite.png);
  background-position: -1663px -680px;
  width: 20px;
  height: 20px;
}
.icon-kzt {
  background-image: url(sprite.png);
  background-position: -1583px -920px;
  width: 20px;
  height: 20px;
}
.icon-left {
  background-image: url(sprite.png);
  background-position: -1663px -922px;
  width: 15px;
  height: 18px;
}
.icon-liqpay-com {
  background-image: url(sprite.png);
  background-position: -144px -1370px;
  width: 52px;
  height: 39px;
}
.icon-little-ref-btn-ico {
  background-image: url(sprite.png);
  background-position: 0px -123px;
  width: 47px;
  height: 47px;
}
.icon-lt {
  background-image: url(sprite.png);
  background-position: -200px -1528px;
  width: 20px;
  height: 20px;
}
.icon-ltc {
  background-image: url(sprite.png);
  background-position: -1543px -1400px;
  width: 20px;
  height: 20px;
}
.icon-ltl {
  background-image: url(sprite.png);
  background-position: -1543px -1200px;
  width: 20px;
  height: 20px;
}
.icon-lv {
  background-image: url(sprite.png);
  background-position: -1543px -1160px;
  width: 20px;
  height: 20px;
}
.icon-lvl {
  background-image: url(sprite.png);
  background-position: -1543px -1080px;
  width: 20px;
  height: 20px;
}
.icon-mBankomat {
  background-image: url(sprite.png);
  background-position: -1429px -826px;
  width: 52px;
  height: 39px;
}
.icon-mPeníze {
  background-image: url(sprite.png);
  background-position: -432px -1370px;
  width: 52px;
  height: 39px;
}
.icon-mad {
  background-image: url(sprite.png);
  background-position: -1543px -880px;
  width: 20px;
  height: 20px;
}
.icon-mdl {
  background-image: url(sprite.png);
  background-position: -1583px -440px;
  width: 20px;
  height: 20px;
}
.icon-menuUp {
  background-image: url(sprite.png);
  background-position: -1663px -1147px;
  width: 9px;
  height: 6px;
}
.icon-mkd {
  background-image: url(sprite.png);
  background-position: -1583px -280px;
  width: 20px;
  height: 20px;
}
.icon-ml {
  background-image: url(sprite.png);
  background-position: -1360px -1528px;
  width: 20px;
  height: 20px;
}
.icon-mobile-payment {
  background-image: url(sprite.png);
  background-position: -1429px -1298px;
  width: 52px;
  height: 39px;
}
.icon-moneta-ru {
  background-image: url(sprite.png);
  background-position: -1429px -649px;
  width: 52px;
  height: 39px;
}
.icon-mur {
  background-image: url(sprite.png);
  background-position: -1440px -1528px;
  width: 20px;
  height: 20px;
}
.icon-mxn {
  background-image: url(sprite.png);
  background-position: -1583px -120px;
  width: 20px;
  height: 20px;
}
.icon-myr {
  background-image: url(sprite.png);
  background-position: -680px -1568px;
  width: 20px;
  height: 20px;
}
.icon-mzn {
  background-image: url(sprite.png);
  background-position: -1280px -1568px;
  width: 20px;
  height: 20px;
}
.icon-nad {
  background-image: url(sprite.png);
  background-position: -1320px -1568px;
  width: 20px;
  height: 20px;
}
.icon-ngn {
  background-image: url(sprite.png);
  background-position: -1400px -1608px;
  width: 20px;
  height: 20px;
}
.icon-nl {
  background-image: url(sprite.png);
  background-position: -1663px -200px;
  width: 20px;
  height: 20px;
}
.icon-no {
  background-image: url(sprite.png);
  background-position: -1663px -520px;
  width: 20px;
  height: 20px;
}
.icon-nok {
  background-image: url(sprite.png);
  background-position: -1501px -1300px;
  width: 20px;
  height: 20px;
}
.icon-nzd {
  background-image: url(sprite.png);
  background-position: -80px -1488px;
  width: 20px;
  height: 20px;
}
.icon-omr {
  background-image: url(sprite.png);
  background-position: -320px -1488px;
  width: 20px;
  height: 20px;
}
.icon-oxxo {
  background-image: url(sprite.png);
  background-position: -1224px -1252px;
  width: 52px;
  height: 39px;
}
.icon-pen {
  background-image: url(sprite.png);
  background-position: -1543px -680px;
  width: 20px;
  height: 20px;
}
.icon-php {
  background-image: url(sprite.png);
  background-position: -1543px -920px;
  width: 20px;
  height: 20px;
}
.icon-pl {
  background-image: url(sprite.png);
  background-position: -1543px -960px;
  width: 20px;
  height: 20px;
}
.icon-pln {
  background-image: url(sprite.png);
  background-position: -1543px -1040px;
  width: 20px;
  height: 20px;
}
.icon-popular {
  background-image: url(sprite.png);
  background-position: -432px -367px;
  width: 28px;
  height: 28px;
}
.icon-ppc {
  background-image: url(sprite.png);
  background-position: -80px -1528px;
  width: 20px;
  height: 20px;
}
.icon-pt {
  background-image: url(sprite.png);
  background-position: -600px -1528px;
  width: 20px;
  height: 20px;
}
.icon-pyg {
  background-image: url(sprite.png);
  background-position: -720px -1528px;
  width: 20px;
  height: 20px;
}
.icon-qar {
  background-image: url(sprite.png);
  background-position: -760px -1528px;
  width: 20px;
  height: 20px;
}
.icon-rarr {
  background-image: url(sprite.png);
  background-position: -720px -721px;
  width: 25px;
  height: 25px;
}
.icon-rarr {
  background-image: url(sprite.png);
  background-position: -720px -662px;
  width: 27px;
  height: 24px;
}
.icon-red-triangle {
  background-image: url(sprite.png);
  background-position: -648px -603px;
  width: 35px;
  height: 18px;
}
.icon-right {
  background-image: url(sprite.png);
  background-position: -1663px -848px;
  width: 15px;
  height: 20px;
}
.icon-ro {
  background-image: url(sprite.png);
  background-position: -1520px -1528px;
  width: 20px;
  height: 20px;
}
.icon-ron {
  background-image: url(sprite.png);
  background-position: -1583px -80px;
  width: 20px;
  height: 20px;
}
.icon-rsd {
  background-image: url(sprite.png);
  background-position: -1583px -160px;
  width: 20px;
  height: 20px;
}
.icon-ru {
  background-image: url(sprite.png);
  background-position: -1583px -200px;
  width: 20px;
  height: 20px;
}
.icon-rub {
  background-image: url(sprite.png);
  background-position: -1583px -240px;
  width: 20px;
  height: 20px;
}
.icon-sar {
  background-image: url(sprite.png);
  background-position: -1583px -680px;
  width: 20px;
  height: 20px;
}
.icon-sdg {
  background-image: url(sprite.png);
  background-position: -1583px -1040px;
  width: 20px;
  height: 20px;
}
.icon-se {
  background-image: url(sprite.png);
  background-position: -1583px -1080px;
  width: 20px;
  height: 20px;
}
.icon-search-ico-hover {
  background-image: url(sprite.png);
  background-position: -1008px -957px;
  width: 20px;
  height: 25px;
}
.icon-search-ico {
  background-image: url(sprite.png);
  background-position: -1583px -1160px;
  width: 20px;
  height: 20px;
}
.icon-sek {
  background-image: url(sprite.png);
  background-position: -1583px -1280px;
  width: 20px;
  height: 20px;
}
.icon-sgd {
  background-image: url(sprite.png);
  background-position: -1583px -1440px;
  width: 20px;
  height: 20px;
}
.icon-sk {
  background-image: url(sprite.png);
  background-position: -120px -1568px;
  width: 20px;
  height: 20px;
}
.icon-sl {
  background-image: url(sprite.png);
  background-position: -280px -1568px;
  width: 20px;
  height: 20px;
}
.icon-sort-bottom {
  background-image: url(sprite.png);
  background-position: -1663px -1025px;
  width: 6px;
  height: 14px;
}
.icon-sort-up {
  background-image: url(sprite.png);
  background-position: -1663px -1059px;
  width: 6px;
  height: 14px;
}
.icon-sq {
  background-image: url(sprite.png);
  background-position: -1120px -1568px;
  width: 20px;
  height: 20px;
}
.icon-sr {
  background-image: url(sprite.png);
  background-position: -1160px -1568px;
  width: 20px;
  height: 20px;
}
.icon-th {
  background-image: url(sprite.png);
  background-position: -1623px -520px;
  width: 20px;
  height: 20px;
}
.icon-thb {
  background-image: url(sprite.png);
  background-position: -1623px -680px;
  width: 20px;
  height: 20px;
}
.icon-tk {
  background-image: url(sprite.png);
  background-position: -1623px -840px;
  width: 20px;
  height: 20px;
}
.icon-tnd {
  background-image: url(sprite.png);
  background-position: -1623px -880px;
  width: 20px;
  height: 20px;
}
.icon-try {
  background-image: url(sprite.png);
  background-position: -1623px -1160px;
  width: 20px;
  height: 20px;
}
.icon-twd {
  background-image: url(sprite.png);
  background-position: -1623px -1400px;
  width: 20px;
  height: 20px;
}
.icon-tzs {
  background-image: url(sprite.png);
  background-position: -1623px -1480px;
  width: 20px;
  height: 20px;
}
.icon-u-net {
  background-image: url(sprite.png);
  background-position: -288px -603px;
  width: 52px;
  height: 39px;
}
.icon-uah {
  background-image: url(sprite.png);
  background-position: -1623px -1560px;
  width: 20px;
  height: 20px;
}
.icon-ugx {
  background-image: url(sprite.png);
  background-position: -40px -1608px;
  width: 20px;
  height: 20px;
}
.icon-uk {
  background-image: url(sprite.png);
  background-position: -200px -1608px;
  width: 20px;
  height: 20px;
}
.icon-up-rarr {
  background-image: url(sprite.png);
  background-position: -1663px -808px;
  width: 18px;
  height: 20px;
}
.icon-us {
  background-image: url(sprite.png);
  background-position: -640px -1608px;
  width: 20px;
  height: 20px;
}
.icon-usd {
  background-image: url(sprite.png);
  background-position: -680px -1608px;
  width: 20px;
  height: 20px;
}
.icon-uyu {
  background-image: url(sprite.png);
  background-position: -960px -1608px;
  width: 20px;
  height: 20px;
}
.icon-uzs {
  background-image: url(sprite.png);
  background-position: -1080px -1608px;
  width: 20px;
  height: 20px;
}
.icon-veb {
  background-image: url(sprite.png);
  background-position: -1160px -1608px;
  width: 20px;
  height: 20px;
}
.icon-vi {
  background-image: url(sprite.png);
  background-position: -1240px -1608px;
  width: 20px;
  height: 20px;
}
.icon-vnd {
  background-image: url(sprite.png);
  background-position: -1663px -120px;
  width: 20px;
  height: 20px;
}
.icon-xmr {
  background-image: url(sprite.png);
  background-position: -880px -1568px;
  width: 20px;
  height: 20px;
}
.icon-zar {
  background-image: url(sprite.png);
  background-position: -1280px -1488px;
  width: 20px;
  height: 20px;
}
.icon-zh {
  background-image: url(sprite.png);
  background-position: -1240px -1528px;
  width: 20px;
  height: 20px;
}
.icon-zmk {
  background-image: url(sprite.png);
  background-position: -840px -1488px;
  width: 20px;
  height: 20px;
}
.icon-ČSOB {
  background-image: url(sprite.png);
  background-position: -1285px -236px;
  width: 52px;
  height: 39px;
}
